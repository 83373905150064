import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

//import service
import { ProductListService } from '../../../../services/product-list.service';

import { Environment } from '../../../../enum/environment.enum';

import { CommonService } from '@modules/shared-module/shared-module.module';

import { Observable, Subscription, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ProductsListSettings } from '../../../../model/products-list-settings.model';
import { ListViewType } from '../../../../enum/list-view-type.enum';
/* import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'; */


@Component({
    selector: 'products-list-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ProductsListToolbarComponent implements OnInit {
  @Input("environment") env: Environment;
  //@Input() service: ProductListService;
  listSettings:ProductsListSettings;
  selectedFacets: Array<any> = [];
  
  //viewType: string = 'list';

  constructor( 
      protected activeRoute: ActivatedRoute,
      protected router: Router,
      protected location: Location,
      protected service: ProductListService,
      private commonService: CommonService,
  ){
    this.listSettings = service.listSettings;
    this.service.facetsSubject.subscribe(facets => {
      this.selectedFacets = this.service.getFacetFilterHuman();
    });
  
  }

  ngOnInit(): void {
   this.selectedFacets = this.service.getFacetFilterHuman();
  }

  get viewType(){ return ListViewType; };

  setViewType(value: ListViewType): void {
    this.listSettings.viewType = value;
    //this.viewType = value;
    this.service.setNewListSettings(this.listSettings);
  }

  onOrderBy(): void {
    //il campo viene cambiato dal model
    this.service.setNewListSettings(this.listSettings);
  }


}