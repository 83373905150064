import { Component, OnInit, HostListener } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { Subscription, Observable, of } from 'rxjs';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './styles/footer.component.scss'
  ],
})
export class FooterComponent implements OnInit {

  private footerData$: Observable<any>;
  private footerDataSubscription: Subscription;
  private footerData: any;
  private boostrapGridMax: number;
  private formInput: string;
  test: any;

  constructor(private commonService: CommonService,   protected activeRoute: ActivatedRoute) { 

    this.formInput = '';
    this.footerData = {};
    this.boostrapGridMax = 12;
/*     this.footerDataSubscription = this.commonService.getHeaderData().subscribe(
      data => {
        this.footerData = data;
      }
    ); */
  }

  ngOnInit() { 

    this.footerData = {  
      themeClass: 'dark-theme',
      withContainer: false, 
      sections: [
        {
          type: 'logoAndSocial',
          socials: [
            {
              title: 'Facebook',
              iconClass: 'fa fa-facebook',
              href: '#'
            },
            {
              title: 'Twitter',
              iconClass: 'fa fa-twitter',
              href: '#'
            },
            {
              title: 'Instagram',
              iconClass: 'fa fa-instagram',
              href: '#'
            },
          ]

        },
        {
          title: 'Customer service',
          type: 'menu',
          items: [
            {
              'label': 'Contact',
              'href': '#'
            },
            {
              'label': 'Returns',
              'href': '#'
            },
            {
              'label': 'Site Map',
              'href': '#'
            },
            {
              'label': 'Brands',
              'href': '#'
            },
            {
              'label': 'Unlimited Links',
              'href': '#'
            },
          ]
        },
        {
          title: 'MY ACCOUNT',
          type: 'menu',
          items: [
            {
              'label': 'My Account',
              'href': '#'
            },
            {
              'label': 'Order History',
              'href': '#'
            },
            {
              'label': 'Affiliates',
              'href': '#'
            },
            {
              'label': 'Newsletter',
              'href': '#'
            },
            {
              'label': 'Gift Certificates',
              'href': '#'
            },
          ]
        },
        {
          title: 'Newsletter',
          type: 'newsletter',
          data: {
            subtitle: 'Subscribe to our newsletter',
            placeholder: 'Your email address',
            emailTo: 'sgabriele@moxsolutions.it',
            button: {
              label: 'Send',
              icon: 'email'
            }
          }
        }
      ],
      lower: {
        text: 'Powered by MOX Solutions'
      }


    };

    $( document ).ready(function() {
      
    });

  }

  private getContainerClass(sectionCount): string{
    let spacing = this.boostrapGridMax / sectionCount < 3 ? 3 : this.boostrapGridMax / sectionCount;
    return 'col-6 col-md-' + spacing;
  }

  public onSubmit(){
    console.log(this.formInput);

  }

}
