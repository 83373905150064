import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HeaderData } from './header-data.model';

 
@Injectable({
    providedIn: 'root'
  })
export class HeaderDataService {

    private headerDataSubject: Subject<HeaderData>;

    constructor(){
        this.headerDataSubject = new Subject<HeaderData>(); 
    }

    public setHeaderData(data): void {
        this.headerDataSubject.next(new HeaderData(data));
    }
    
    public getHeaderData(): Observable<HeaderData> {
        return this.headerDataSubject.asObservable();
    }

}