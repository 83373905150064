import { Component } from '@angular/core';
import { CommonService } from '@modules/shared-module/shared-module.module';
import { AppEntityServices } from './ngrx/services/ngrx.service';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  constructor(private appEntityServices: AppEntityServices, private activeRoute: ActivatedRoute, private commonService: CommonService){  }

  ngOnInit() { 
    //let cartService = this.appEntityServices.getEntityCollectionService("CartItem");
    //cartService.getAll();
    this.appEntityServices.getEntityCollectionService("CartItem").getAll();

    this.appEntityServices.getEntityCollectionService("ComparisonItem").getAll();

    this.appEntityServices.getEntityCollectionService("Wishlist").getAll();
   
  }
}
