import { Type } from '@angular/core';

import { BuyComponentInterface } from '../interfaces/buy.interface';
import { StandardBuyComponent } from '../components/standard/standard-buy.component';
import { GridBuyComponent } from '../components/grid/grid-buy.component';

/*
	questa costante contiene tutti i possibili tipi di buy dell'impianto e il relativo collegamento al componente interessato
	nota: in futuro se si dovranno aggiungere buy custom ai vari customer dovrebbe essere sufficiente customizzare questa variabile
				(ed ovviamente inserire i vari file dei nuovi componenti)
*/
export const buyComponents: {[type: string]: Type<BuyComponentInterface>} = {
  standard: StandardBuyComponent,
  grid: GridBuyComponent
};