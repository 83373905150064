import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@modules/shared-module/shared-module.module';

@Component({
  selector: 'popover-list-item',
  templateUrl: './popover-list-item.component.html',
  styleUrls: [
    './popover-list-item.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PopoverListItemComponent implements OnInit {
		@Output() remove: EventEmitter<any> = new EventEmitter();

    @Input() item: any;

    imgLoaded: boolean = false;

    constructor(private commonService: CommonService) { }

    ngOnInit() {  }

    removeItem(item: any, event: any): void {
      event.stopPropagation();
    	this.remove.emit(item);
    }

}
