import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '@modules/shared-module/shared-module.module';
import { SwiperConfig, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
    selector: 'lightbox',
    templateUrl: './lightbox.component.html',
    styleUrls: ['./lightbox.component.scss']
})

export class LightboxComponent implements OnInit{

    private media: any;
    private mediaURL: any;
    constructor(private commonService: CommonService, private sanitizer: DomSanitizer) { } 

    @Input() settings: any;

    sliderConfig: SwiperConfigInterface;    

    ngOnInit() { 
        this.media = this.settings.media;
        this.mediaURL = this.sanitizeURL(this.settings.media.media_url);

        if(this.settings.type == 'slideshow') {
            this.sliderConfig = {
                'slidesPerView': 1,
                'spaceBetween': 0,
                'centeredSlides': true,
                'loop': true,
                'autoplay': {
                  'delay': 5000,
                },
                'pagination': {
                  'el': '.product-image-pagination',
                  'type': 'bullets',
                  'clickable': true,
                },
             };


        }
    } 

    private sanitizeURL(url){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public open(mediaId:any, startAt?: number) { 
        if(this.sliderConfig)
            this.sliderConfig.initialSlide = startAt;
        document.getElementById(this.settings.type + '-' + mediaId).style.display = 'block';
    }

    private close() {
        document.getElementById(this.settings.type + '-' + this.media.viewelement_id).style.display = 'none';
    }

}