import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http'; 
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProductsListSettings } from '../model/products-list-settings.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Environment } from '../enum/environment.enum';
import { CommonService } from '@modules/shared-module/shared-module.module';

/*{
  //providedIn: 'root'
}*/
@Injectable()
export class ProductListService {
  private baseApiUrl: string = '/api/v2/ecommerce/product';

  private headers: Headers;
  private options: RequestOptions;
  private lang: string;

  //contiene i parametri impostati dal ProductListToolbarComponent
  listSettings: ProductsListSettings = new ProductsListSettings();

  list: Array<any> = [];
  facets: Array<any> = [];

  activeCategoryID: number = -1;
  facetFilter: string = null;
  prevFacetStatus: string = null;
  searchText: string = null;

  loading: boolean = false;
  loadingProduct: any = { id: 0};
  noMoreData: boolean = false;

  paging: any = {
    totalSize: 0,
    size: 20,
    current: 1
  };
  
  //Observable sources
  facetsSubject: Subject<any> = new Subject<any>();
  listSettingsSubject: Subject<ProductsListSettings> = new Subject<ProductsListSettings>();
  public currentVariantSubject: Subject<any> = new Subject<any>();

  //Observable streams
  //questo è lo standard definito da Angular.io:
  //si crea il subject per emettere un nuovo "evento" 
  //si crea l'observable a cui gli interessati dovranno sottoscrivere
  //per convenzione (Angular.io) il nome di un observable termina sempre on il $
  listSettingsChanged$ = this.listSettingsSubject.asObservable()

  constructor (private http: HttpClient, protected activeRoute: ActivatedRoute, protected router: Router, protected commonService: CommonService){
    //this.headers = new Headers({ 'Content-type': 'application/json' });
    //this.options = new RequestOptions({ headers: this.headers });
/*     this.router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        this.categoryId = this.activeRoute.snapshot.paramMap.get('cid');
      }
    }); */
    this.lang = this.commonService.getUtilityService().getLangParam();
  }

  /**
   * Get all divisions
   */
  nextPage(param?: any): Observable<any> {
    // !param: normale chiamata da scroll infinito per pagina successiva
    if(!param)
      this.paging.current++;

    // param: richiesta con parametro specifico (cambio nodeID / reset facet )
    if(param) {
      if(param.reset) {
        this.list = [];
        this.paging.current = 1;
        this.noMoreData = false;
        this.searchText = null;
      }
      if(param.nodeID)
        this.activeCategoryID = param.nodeID;

      if(param.hasOwnProperty('searchText'))
        this.searchText = param.searchText || null;
      else if(this.activeRoute.snapshot.queryParams['q']){
        this.searchText = this.activeRoute.snapshot.queryParams['q'];
      }

      if(param.hasOwnProperty('facetFilter'))
        this.facetFilter = param.facetFilter || null;

      if(param.hasOwnProperty('prevFacetStatus'))
        this.prevFacetStatus = param.prevFacetStatus || null;
    }

    if(this.loading || this.noMoreData)
      return null; //Ehi tu! se vuoi un consiglio tieni a mente questo: Restituendo EMPTY (observable vuoto) e questo poi viene 
      //restituito a sua volta da una resolve si ottiene lo spiacevole effetto di cancellare il cambiamento 
      //di route.. e passare un po' di tempo (come un deficente che esamina per ore un sasso) 
      //a domandarsi come mai la this.route.navigate non funzioni nonostante
      //venga invocata passando i parametri corretti.

    this.loading = true;

    let payload = {
      nodeID: this.activeCategoryID,
      pageIndex: this.paging.current, 
      pageSize: this.paging.size,
      searchText: this.searchText,
      facetFilter: this.facetFilter,
      prevFacetStatus: this.prevFacetStatus,
      orderBy: this.listSettings.sortField
    };

    return this.http.post(this.baseApiUrl + '_list', payload/*, this.options*/)
    .pipe(map((response: Response) => {
      let result = this.refineRawProducts(response);
      return result;
    }));
  }


  /**
   * Load a product variant
   */
  loadVariantData(param?: any): Observable<any> {
   
    if(!param) return;

    let payload = {
      nodeID: this.activeCategoryID,
      pageIndex: 0, 
      pageSize: 1,
      searchText: '',
      facetFilter: '',
      prevFacetStatus: '',
      orderBy: '',
      productId: param.variantId
    };

    return this.http.post(this.baseApiUrl + '_list', payload)
    .pipe(map((response: Response) => {  

      //Aggiorno subject variante corrente per i listener (vedere ad es. detail sidebar component)
      this.currentVariantSubject.next({
        currentVariant : response,
        requestedParams: param
      });

      // Replace product nella lista se non sono nel dettaglio prodotto (viene gestito su detail sidebar component perchè ci può essere più di una lista)
      if(param.environment != Environment.ProductDetail)
        this.replaceProduct(response, param);
      return response;
    }));
  }

  /**
   * Replace a single product inside products list
   * @param data 
   * @param productIdToReplace 
   */
  private replaceProduct(data: any, param: any): void{

    if(data.products.length != 1) return;

    let productIdToReplace: number = param.productId;
    let newProduct = data.products.shift();

    //Trovo elemento in lista prodotti che corrisponde al vecchio prodotto da rimpiazzare
    const index = this.list.map(p => p.id).indexOf(productIdToReplace);

    //Sostituisco vecchio prodotto con nuovo nella lista prodotti
    this.list[index] = newProduct;

  }

  /**
   * Parse http response
   * @param res 
   */
  // private refineRawProducts(res: Response) {
  //   let data = res.json();
  private refineRawProducts(data: any) {
    this.list = this.list.concat(data.products);

    if(this.list.length > 0) {
      if(this.paging.current <= 1) {
        this.paging.totalSize = this.list[0].number_of_records;
        this.facets = data.facets;
        this.facetsSubject.next(data.facets);
      }

      // se non sono stati tornati risultati o ne sono stati tornati meno della richiesta -> stop
      if(data.products.length == 0 || data.products.length < this.paging.size)
        this.noMoreData = true;
      
    } else {
      if(this.paging.current <= 1)
        this.paging.totalSize = 0;
    }

    this.loading = false;
    return this.list;
  }


  public setNewListSettings(par: ProductsListSettings){
    this.listSettings = par;
    this.listSettingsSubject.next(par);
  }


  // funzione di supporto per generazione del facetLog (grafica per visualizzazione dei filtri selezionati per l'utente)
  public getFacetFilterHuman(): Array<any> {
    let log = [];

    var facetsRun = function(facet, title, facet_values) {       
        for (let i = 0; i < facet.values.length; i++) {
            let value = facet.values[i];
            if (value.checked) {
                facet_values.push({"title": value.value});
            }
        }
        //se necessario aggiungo la descrizione del facet
        if (facet_values.length > 0) {  
            log.push({
                "type": 1,
                "title": title,
                "values": facet_values
            });
        }
    };

    for (let fi = 0; fi < this.facets.length; fi++) {        
        let facet = this.facets[fi];
        let facet_values = [];

        if(!facet.grouped) {
            facetsRun(facet, facet.text, facet_values);
        } else {
            for (let ig = facet.groups.length - 1; ig >= 0; ig--) {
                facetsRun(facet.groups[ig], facet.text, facet_values);
            };
        }
    }

    if(log.length) {
      this.commonService.getPageDataService().setCurrentQueryString(this.router.url);
    }

    return log;
  }

  clearFacets(): void {
    var facetsRun = function(facet) {                    
        for (let vidx = 0; vidx < facet.values.length; vidx++) {
            let value = facet.values[vidx];
            if (value.checked)
                value.checked = false;
        }
    };

    for (let fidx = 0; fidx < this.facets.length; fidx++){
        let facet = this.facets[fidx];
        facet.expanded = false;

        if(!facet.grouped) {
            facetsRun(facet);
        } else {
            for (var ig = facet.groups.length - 1; ig >= 0; ig--) {
                facetsRun(facet.groups[ig]);
            };
        }
    }

    let currentQueryParam = this.activeRoute.snapshot.queryParams['q'];
     
    if(this.router.url.indexOf('search') != -1){
      this.router.navigate(['/' + this.lang + '/ecommerce/search'], { 
        queryParams: currentQueryParam ? { 'q' : currentQueryParam } : {},
      }); 
    } else {
      this.router.navigate(['/' + this.lang + '/ecommerce/category/' + this.activeCategoryID], { 
        queryParams: currentQueryParam ? { 'q' : currentQueryParam } : {},
      }); 
    }

  }

}
