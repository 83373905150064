export enum Colors{
    red_light = 1,
    red_dark = 2,

    orange_light = 3,
    orange_dark = 4,

    yellow_light = 5,
    yellow_dark = 6,

    green_light = 7,
    green_dark = 8,

    dgreen_light = 9,
    dgreen_dark = 10,

    cian_light = 11,
    cian_dark = 12,

    blue_light = 13,
    blue_dark = 14,

    purple_light = 15,
    purple_dark = 16,

    pink_light = 17,
    pink_dark = 18,

    lightgray_1 = 19,
    lightgray_2 = 20,

    mediumgray_1 = 21,
    mediumgray_2 = 22,

    darkgray_1 = 23,
    darkgray_2 = 24
}
