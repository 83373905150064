import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, UrlTree} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location  } from '@angular/common';
import { Subject, Observable } from 'rxjs';

/**
 * Global utility methods
 */
@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    private breakpoints: any;
    private themeSettingsChangeSubject: Subject<any>;
    private urlSearchStringSubject: Subject<any>;

    constructor(private router: Router, private location: Location, private http: HttpClient){
        this.breakpoints = {
            'xs': 376,
            'sm': 576,
            'md': 768,
            'lg': 992,
            'xl': 1200
        }
        this.themeSettingsChangeSubject = new Subject<any>();
        this.urlSearchStringSubject = new Subject<any>();
    }

    /**
    * Navigate to the desired destination
    * @param destination 
    */
    public goTo(destinationObj: any): void{
        if(destinationObj && destinationObj.destination){
            destinationObj.route ? this.router.navigate(destinationObj.destination, { relativeTo: destinationObj.route }): window.location.href = destinationObj.destination;
        } else {
            this.location.back(); 
        }
    }

    /**
     * Check if object is empty
     * @param object 
     */
    public isObjectEmpty(object): boolean{
        return Object.keys(object).length === 0 && object.constructor === Object;
    }

    /**
     * Get culture list
     * @param object 
     */
    public getCultureList(): any{
        return this.http.get('/api/v1/common/cultures_list');
    }s

    /**
     * 
     * @param url 
     * @param callback 
     */
    public getJson(url:string, callback: (jsonData: any)=>void){
        return this.http.get(url)
                    .subscribe(data => {
                        callback(data);
                    });
    }

    /**
     * Check window size
     * @param object 
     */
    public isWindowSize(breakpointId, windowSize, operator): boolean{
        let result = null;
        switch(operator){
            case '<': 
                result = windowSize < this.breakpoints[breakpointId];
                break;
            case '>': 
                result = windowSize > this.breakpoints[breakpointId];
                break;
            case '=': 
                result = windowSize == this.breakpoints[breakpointId];
                break;
        }
        return result;
    }

  // formattatore per i costi/numeri
  public formatMoney(value, format, currency, includeCurrencyLabel, divider?){   
      
    format = format || ("###0.00 " + (currency || "€"));

    let formattedValue = this.formatNumber(value, 2, '.', ',').toString();

    let splittedValue = formattedValue.split('.');

    let intValue = '<span class="int-value">' + splittedValue[0] + "</span>";
    let decValue = '<span class="dec-value">' + splittedValue[1] + "</span>";
    let currencyLabel = includeCurrencyLabel ? '<span class="currency">€</span>' : '';

    divider = divider ? divider : '.';

    return intValue + divider + decValue + currencyLabel;
  };

  public formatNumber(n, c, d, t){
    var i, s, j;
    c = isNaN(c = Math.abs(c)) ? 2 : c, 
    d = d == undefined ? "." : d, 
    t = t == undefined ? "," : t, 
    s = n < 0 ? "-" : "", 
    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
    j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
  }

  public toggleSidebar(event, scroll ?: boolean){
    event.preventDefault();
    event.stopPropagation();

    let actionsContainer = document.getElementById('sidebar');
    let className = "opened";

    actionsContainer.classList.contains(className) ? actionsContainer.classList.remove(className) : actionsContainer.classList.add(className);

    if(scroll)
        window.scrollTo({top: 0, behavior: 'smooth'});
  }

  public toggleDetailSidebar(event?, scroll ?: boolean){
      
    if(event){
        event.preventDefault();
        event.stopPropagation();
    }

    let sidebarContainer = document.getElementById('detail-sidebar');
    let detailContainer = document.getElementById('detail');

    let className = "opened";

    if(!sidebarContainer.classList.contains('offscreen')) return;

    if (sidebarContainer.classList.contains(className)){
        sidebarContainer.classList.remove(className);
    } else {
        sidebarContainer.classList.add(className);
    }
    
    if(detailContainer.classList.contains(className)){
        detailContainer.classList.remove(className)
    } else {
        detailContainer.classList.add(className);
    }

    if(scroll)
        window.scrollTo({top: 0, behavior: 'smooth'});
  }

  public getThemeSettingsChange():  Observable<any>{
    return this.themeSettingsChangeSubject.asObservable();
  }

  public setThemeSettingsChange(theme): void {
    return this.themeSettingsChangeSubject.next(theme);
  }

  public getUrlQueryStringChange():  Observable<any>{
    return this.urlSearchStringSubject.asObservable();
  }

  public setUrlQueryStringChange(url): void {
    return this.urlSearchStringSubject.next(url);
  }

  public getLangParam(): string{

    let langParam: string = null;
    let parsedUrl: UrlTree = this.router.parseUrl(this.router.url);

    langParam = parsedUrl.root.children.primary ? parsedUrl.root.children.primary.segments[0].path : 'it';
    
    return langParam;

  }

}

