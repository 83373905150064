import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgSwitch } from '@angular/common';

import { StockViewType } from '../../enum/stock-view-type.enum';

import { CommonService } from '@modules/shared-module/shared-module.module';

@Component({
  selector: 'item-stock-view',
  templateUrl: './stock-view.component.html',
  styleUrls: ['./stock-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StockViewComponent implements OnInit {
  @Input() data: any;  // oggetto con dati stock, qui mi aspetto sempre un priceDetail
  @Input() viewType: string;  // vedere come usare poi enum altrove in caso

  notAvailable: boolean;
  
  constructor(
    private commonService: CommonService
  ) { 
  }

  ngOnInit() { 

    if(!this.data.productInfo) {
      this.notAvailable = true;
      return;
    }
    
    if(!this.data.productInfo.stockBehaviour || this.data.productInfo.stockBehaviour == 2)
      this.notAvailable = false;
    else {
      let availability = (this.data.productInfo.stockQuantity < 0 ? 0 : this.data.productInfo.stockQuantity) + 
        (this.data.productInfo.stockIncomingQuantity < 0 ? 0 : this.data.productInfo.stockIncomingQuantity) + 
        (this.data.productInfo.stockCustomerQuantity < 0 ? 0 :  this.data.productInfo.stockCustomerQuantity) + 
        (this.data.productInfo.stockCustomerIncomingQuantity < 0 ? 0 : this.data.productInfo.stockCustomerIncomingQuantity);
      // TODO: gestire step
      let step = this.data.stepQuantity || 1;
      this.notAvailable = (availability == 0 || step > availability);
    }

  }

  ngOnDestroy(): void { }

  getStockQuantityString(val:number, innerHTML?: boolean): string {
    if(val == undefined || !val) return;

    let valString = val.toString();
    // se è un intero tolgo eventuali decimali inesistenti
    if(val % 1 === 0) {
      valString = (valString.indexOf('.') >= 0) ? valString.substr(0, valString.indexOf('.')) : valString;
    } else {
      valString = this.commonService.formatNumber(val, 2,',','.');
    }

    /* TODO: eventuale gestione valore massimo visualizzabile da setting
    var max_val = $rootScope.getSettingNumericValue("stock_quantity.max_value_display");
    if(max_val && max_val < Number(val)){
      val = "+" + max_val;
    }
    */
   if(innerHTML)
    return valString + ' ' + '<span class="um-code">' + this.data.umCode + '</span>';
   else 
    return valString + ' ' + this.data.umCode;
  }

  private checkStockArrivals(): void{
    console.log('checkStockArrivals');
  }
}
