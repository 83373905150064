import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceListService {

  constructor(private http: HttpClient){ }

  public findAll(): Observable<any>{
    return this.http.get('/api/v1/common/prices_list');
  }

}