import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

class PaginationResult{
  count:number;
  rows:Array<any>;
}

//@Injectable({})
export class CustomerFilterService {
  public filterData:Array<any>;
  protected loadingData:boolean;

  constructor(private httpClient: HttpClient) {
    this.filterInitializzationData().subscribe(data=>{
      //per il momento cablo i gruppi, poi si dovrà usare quanto restituito dalla stored
      // TODO: if($rootScope.getSettingBooleanValue('billing_or_shipping_customer')) {
      this.filterData = [];
      this.filterData.push({
        node_code: 'group',
        _id: 'mox_customergroup_gruppo',
        guid: 'mox.customergroup.gruppo',
        hierarchy_behavior: {
          "1": {
            "label": "TR_GROUP",
            "labelClass": "label-primary"
          },
          "2": {
            "label": "TR_SUBGROUP",
            "labelClass": "label-default"
          },
          "3": {
            "label": "TR_SPECIFIC",
            "labelClass": "label-warning"
          }
        }
      });

      this.filterData.push({
        node_code: 'canale',
        _id: 'mox_customergroup_canale',
        guid: 'mox.customergroup.canale',
        hierarchy_behavior: {
          "1": {
            "label": 'TR_CHANNEL',
            "labelClass": 'label-success'
          }
        }
      });
      
      this.filterData.push({
        node_code: 'bill_cust',
        guid: 'bill_cust',
        hierarchy_behavior: {
          "1": {
            "label": 'TR_BILL_CUST',
            "labelClass": 'label-success'
          }
        }
      });

      this.filterData.push({
        node_code: 'ship_cust',
        guid: 'ship_cust',
        hierarchy_behavior: {
          "1": {
            "label": 'TR_SHIP_CUST',
            "labelClass": 'label-success'
          }
        }
      });
      /* TO DO
       } else {
        ans.data.push({
          node_code: 'customer',
          guid: 'customer',
          hierarchy_behavior: {
            "1": {
              "label": 'GENERAL_CUSTOMER',
              "labelClass": 'label-success'
            }
          }
        });
      }
      for (var i = 0; i < ans.data.length; i++) {
          var d = ans.data[i];
          d._id = d.guid.replace(/\./g,'_');
        };
        return ans.data;
      };
      */
    });

  }

  public filterInitializzationData():Observable<any>{
    return this.httpClient.get('/api/v1/promo/customer/initializationData');
  }

  private loadData(tabGuid:string, pageIndex:number, pageSize:number, filter:string):Observable<PaginationResult>{
    if(!pageIndex || pageIndex == 0)
        pageIndex = 1;

    if(!pageSize || pageSize == 0)
        pageSize = 20;

    if(!filter || filter == ''){
        filter = '';
    }

    var data = {
        order:      '',
        filter:     filter,
        pageIndex:  pageIndex,
        pageSize:   pageSize,
        parent_guid: tabGuid
    };

    return this.httpClient.post<PaginationResult>("/api/v1/promo/customer/data" , data);
    /*.pipe(map((response: Response) => {
      let result = {};
      if (response) {
        switch(response.status) {
          case 200: {
            result = response.json();
            break;
          }
          default:
            break;  
        }
      }
      return result;
    }));*/
  }

  public nextPage(tab){
    if (this.loadingData || tab.nomoredata) {
        return;
    }
    this.loadingData = true;
    if(!tab.currentPage){
      tab.currentPage = 0;
    }
    if(!tab.dataToShow){
      tab.dataToShow = [];
    }
    if(!tab.search_clause){
      tab.search_clause = '';
    }

    tab.currentPage += 1;

    var where = tab.search_clause;

    if (where.length > 0 ) {
        where = "vl_name ilike ''%" + where.replace(/'/g, "''''") + "%'' OR code ilike ''%" + where.replace(/'/g, "''''") + "%''";
    }
    this.loadData(tab.guid, tab.currentPage, 0, where)
      .subscribe(data =>{
        if (!data || data.rows.length == 0) {
            tab.nomoredata = true;
        }
        for (var i = 0; data && i < data.rows.length ; i++) {
            var _item = data.rows[i];
            _item.type = tab.node_code;

            tab.dataToShow.push(_item);
        }
        this.loadingData = false;
    });
  };
}
