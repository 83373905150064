export class SiteUser {
  user_id: number;
  first_name: string;
  last_name:string;
  username: string;
  email: string;
  profile_id: number;
  customer_id: number;
  is_anonymous: boolean;
  profile_description: string;
  user_culture: string;
}

export class Functionality{
  functionality_id: number;
  functionality_guid: string;
}

export class SearchCategory{
  node_id: number;
  name: string;
}


export class UserData {
  user: SiteUser;
  functionalities: Functionality[];
  search_categories: SearchCategory[];
}




