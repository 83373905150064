
import {ListViewType} from '../enum/list-view-type.enum';


/**
 * conterrà i parametri che possono essere impostati nel ProductsListToolbarComponent 
 * e passati attraverso subscription ai componenti che includono la toobar
 */
export class ProductsListSettings{
  sortAvailable:any;
  sortField: string;
  viewType: ListViewType;


  constructor(){
    this.viewType = ListViewType.List;
    this.sortField = "product_code asc";

    this.sortAvailable = [
        { id: 'price asc', label: 'GENERAL_ORDER_BY_PRICE_ASC'},
        { id: 'price desc', label: 'GENERAL_ORDER_BY_PRICE_DESC'},
        { id: 'product_code asc', label: 'GENERAL_ORDER_BY_CODE_ASC'},
        { id: 'product_code desc', label: 'GENERAL_ORDER_BY_CODE_DESC'},
        { id: 'node_name asc', label: 'GENERAL_ORDER_BY_NAME_ASC'},
        { id: 'node_name desc', label: 'GENERAL_ORDER_BY_NAME_DESC'}
      ];
  }
}
