import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  ChangeSetItem,
  EntityCacheDispatcher,
  ChangeSet
} from '@ngrx/data';
import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityServicesBase, EntityServicesElements,changeSetItemFactory as cif } from '@ngrx/data';
 
export interface DataStoreInterface{
  addOrUpdateItems(items:Array<any>):Observable<any>;
  removeItems(items:Array<any>):Observable<any>;
};


@Injectable({ providedIn: 'root' })
export class CartItemService extends EntityCollectionServiceBase<any> implements DataStoreInterface {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory,
              private eCacheDispatcher: EntityCacheDispatcher) {
    super('CartItem', serviceElementsFactory);
  }


  removeItems(items: any[]) {
    const changes: ChangeSetItem[] = [cif.delete("CartItem", items)];

    return this.eCacheDispatcher
          .saveEntities(changes, '/api/ecommerce/cart/remove-items', {isOptimistic:true})
          .pipe(
            map(res => {
              if(res.changes && res.changes.length > 0 && res.changes[0])
                return res.changes[0].entities;
              else 
                return res;
            })
          );
  }
  
  addOrUpdateItems(items: any[]) {
    const changes: ChangeSetItem[] = [cif.upsert("CartItem", items)];

    return this.eCacheDispatcher
          .saveEntities(changes, '/api/cartitem/add-or-update-items', {isOptimistic:true})
          .pipe(
            map(res => {
              if(res.changes && res.changes.length > 0 && res.changes[0])
                return res.changes[0].entities;
              else 
                return res;
            })
          )
  }

}

@Injectable({ providedIn: 'root' })
export class SmwItemService extends EntityCollectionServiceBase<any> implements DataStoreInterface {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('SmwItem', serviceElementsFactory);
  }
  removeItems(items: any[]) {
    throw new Error("Method not implemented.");
    return EMPTY;
  }

  addOrUpdateItems(items: any[]) {
    throw new Error("Method not implemented.");
    return EMPTY;
  }


  /*
  getAll(): Observable<any[]> {
    //return super.getAll().pipe(map(cart => cart.details)); //heroes.map(hero => this.mapHero(hero))));
    return super.getAll().pipe(
      map(res => {
        return res.detail;
      }),  
    );
  }*/

}
 
@Injectable()
export class AppEntityServices extends EntityServicesBase {
  constructor(
    elements: EntityServicesElements,
 
    // Inject custom services, register them with the EntityServices, and expose in API.
    readonly _cartItemService: CartItemService,
    readonly _smwItemService: SmwItemService
  ) {
    super(elements);
    this.registerEntityCollectionServices([_cartItemService, _smwItemService]);
  }
 
  get cartItemService(){
    return this._cartItemService;
  }

  get smwItemService(){
    return this._smwItemService;
  }

  /** get the (default) SideKicks service */
  get wishlistService() {
    return this.getEntityCollectionService<any>('Wishlist');
  }

  get comparisonService(){
    return this.getEntityCollectionService<any>('ComparisonItem');
  }
}

/*
E' necessario creare un apposito service solo se ci sono delle funzioni custom da usare. 
Altrimenti si può usare direttamente EntityServices.getEntityCollectionService(entityName) per ottenere il 
service di default dell'entità
@Injectable({ providedIn: 'root' })
export class WishlistStateService extends EntityCollectionServiceBase<any> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Wishlist', serviceElementsFactory);
  }
}

@Injectable({ providedIn: 'root' })
export class ComparisonStateService extends EntityCollectionServiceBase<any> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ComparisonItem', serviceElementsFactory);
  }
}
*/