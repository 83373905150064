import { Component, OnInit, Input, ViewEncapsulation, HostListener } from '@angular/core';
import { CommonService, UtilityService } from '@modules/shared-module/shared-module.module';
import { SidebarPosition } from '../../enum/detail-sidebar-position.enum';
import { ProductListItemComponent } from '../list/product-list-item/product.component';
import { ProductListService } from '../../services/product-list.service';

@Component({
  selector: 'detail-sidebar',
  templateUrl: './detail-sidebar.component.html',
  styleUrls: [
    './detail-sidebar.component.scss'
  ],
  providers: [ProductListService],
  encapsulation: ViewEncapsulation.None
})
export class DetailSidebarComponent implements OnInit {

    /**
     * Note per classi css: la sidebar resta aperta su:
     *  destra se ha classe: opened right
     *  sinistra se ha classe opened left
     *  sotto al contenuto (e quindi diventa una riga a tutti gli effetti (row)) se ha classe row
     */

    private utilityService: UtilityService;
    private position: string;
    private headerPosition: number;
    private sidebarElement: any;
    private headerElement: any;
    private footerElement: any;

    @Input() config: any;

    constructor(private commonService: CommonService, private productListService: ProductListService) { 
        this.utilityService = this.commonService.getUtilityService();
    }

    ngOnInit() { 
      this.headerElement = document.getElementById('ecommerce-header');
      this.headerPosition = this.headerElement.offsetHeight;

      this.sidebarElement =  document.getElementById('detail-sidebar');
      this.footerElement = document.getElementById('app-footer');

      if(this.commonService.isWindowSize('lg', window.innerWidth, '<') && this.sidebarElement.classList.contains('opened')){
        this.utilityService.toggleDetailSidebar();
      }

      this.productListService.currentVariantSubject.subscribe(res => { 
        this.replaceProductVariant(res);
      });

      this.position = this.getPositionClass();
   
    }

    private getPositionClass(): string {

      let cssClass = '';

      switch(this.config.sidebarPosition){
        case SidebarPosition.Left: 
          cssClass = 'left opened';
          break;
        case SidebarPosition.Right: 
          cssClass = 'right opened';
          break;
        case SidebarPosition.Row: 
          cssClass = 'full-row';
          break;
        default:
          break;
      }

      return cssClass;
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll($event) {
  
      if(this.config.sidebarPosition != SidebarPosition.Row){
        const windowScroll = window.pageYOffset;
  
        if(windowScroll >= this.headerPosition){
          this.sidebarElement.classList.add('scrolling');
        } else {
          this.sidebarElement.classList.remove('scrolling');
        }
      }

      
    }

    @HostListener('window:resize', ['$event']) onResize(event) {

      if(this.config.sidebarPosition != SidebarPosition.Row){
        if(this.commonService.isWindowSize('lg', event.target.innerWidth, '<') && this.sidebarElement.classList.contains('opened')){
          this.utilityService.toggleDetailSidebar();
        } else if(this.commonService.isWindowSize('lg', event.target.innerWidth, '>') && !this.sidebarElement.classList.contains('opened')){
          this.utilityService.toggleDetailSidebar();
        }
      }
     
    }
    
    private calculateHeight(): any{

      if(this.config.sidebarPosition != SidebarPosition.Row){
        //Calcola altezza solo se la sidebar è in position absolute e quindi screen size > lg
        if(this.commonService.isWindowSize('lg', window.innerWidth, '>')){
          var body = document.body,html = document.documentElement;
          var domHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
          return domHeight - (this.headerElement.offsetHeight + this.footerElement.offsetHeight) + 'px';
        } else {
          return 'auto';
        }
      }
      return 'auto';
    }

    private replaceProductVariant(params): void{

      let newProduct = params.currentVariant.products.shift();
      let itemFound = false;

      for(let i = 0; i < this.config.data.length && !itemFound; i++){
        let section = this.config.data[i].items;
        let oldProductIndex = section.map(p => p.id).indexOf(params.requestedParams.productId);
        section[oldProductIndex] = newProduct;
      }

    }

}
