import { Action, createReducer, on } from '@ngrx/store';
import * as WishlistActions from '../actions/wishlist-actions';

export interface WishlistState {
    items_count: number;
    items_ids: any[];
    inList: boolean;
}

export const initialWishlistState: WishlistState = {
    items_count: 0,
    items_ids: [],
    inList: false
};

const wishlistReducer = createReducer(
    initialWishlistState,
    on(WishlistActions.update, (state, { wishlist }) => ({ 
        items_count: wishlist.items_count,
        items_ids: wishlist.items_ids, 
        inList: wishlist.inList 
    })),
  );
  
export function reducer(state: WishlistState | undefined, action: Action) {
    return wishlistReducer(state, action);
}

export const UPDATE_ITEM = 'UPDATE_ITEM';
export function updateWishlistReducer(state: any = [], action) {

    switch (action.type) {
        case UPDATE_ITEM:{
            return [...state, action.payload];
        }
        default:
            return state;
    }
}
