import { Component, OnInit, Input } from '@angular/core';

import { CommonService } from '@modules/shared-module/shared-module.module';
import { WishlistService } from '../../../services/actions/wishlist.service';

@Component({
  selector: 'product-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistActionComponent implements OnInit {
  @Input() product: any;
  @Input() label: string;
  
  name: string = '';
  openedModal: boolean = false;  
  addingNew: boolean = false;  

  constructor(
    protected commonService: CommonService,
    protected service: WishlistService
  ) { }

  ngOnInit() {   }

  ngOnDestroy(): void {  }

  openModal(content) {

    if(!this.service.list) {
      this.service.getList()
      .subscribe(res => {
        this.openFunctions();
      });
    } else {
      this.openFunctions();
    }
  }

  private isProductInList(wishlist): boolean{
    return wishlist.inList || (wishlist.items_ids && wishlist.items_ids.indexOf(this.product.id) != -1);
  }

  openFunctions(): void {
    this.openedModal = true;
  }

  addWishlist(): void {
    this.service.addWishlist(this.name)
    .subscribe(res => {
      this.name = "";
      this.addingNew = false;
      this.commonService.sendMessage(this.commonService.getFixedWord('WISHLIST_ADDED'), 'success', true);    
    });
  }

  addToWishlist(wishlist: any): void {

    if(wishlist.inList){
      this.commonService.sendMessage(this.commonService.getFixedWord('WISHLIST_PRODUCT_DUPE'), 'warning', true); 
      return;
    }
    this.service.addOrRemoveProduct(this.product, wishlist.wishlist_id, 'add')
    .subscribe(res => {
      if(res == null){
        this.commonService.sendMessage(this.commonService.getFixedWord('WISHLIST_PRODUCT_DUPE'), 'warning', true); 
      } else {
        this.commonService.sendMessage(this.commonService.getFixedWord('WISHLIST_PRODUCT_ADDED'), 'success', true); 
      }
    });
  }

  remove(wishlist: any): void {
    this.service.addOrRemoveProduct(this.product, wishlist.wishlist_id, 'remove')
    .subscribe(res => {
      this.commonService.sendMessage(this.commonService.getFixedWord('WISHLIST_PRODUCT_REMOVED'), 'success', true); 
    });

  }

}
