import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http'; 
import { HttpClient } from '@angular/common/http'; 

import { Observable, Subject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CommonService, AppInitService } from '@modules/shared-module/shared-module.module';

// singleton
@Injectable({
  providedIn: 'root',
})
export class BuyService {
  private baseApiUrl: string = '/api/v2/ecommerce/buy';

  private headers: Headers;
  private options: RequestOptions;

  openBuy: boolean = false;

  /* buy service data */
  askingPriceCounter: number = 0;
  customerID: number = 1;
  
  //Observable sources
  buySubject: Subject<any> = new Subject<any>();

  //Observable streams
  //questo è lo standard definito da Angular.io:
  //si crea il subject per emettere un nuovo "evento" 
  //si crea l'observable a cui gli interessati dovranno sottoscrivere
  //per convenzione (Angular.io) il nome di un observable termina sempre on il $
  buyRequest$ = this.buySubject.asObservable();

  // a quanto pare l'utilizzo di questo senza poi usarlo lo faceva scazzare.. da rivedere però 
  constructor (
    private http: HttpClient,
    private commonService: CommonService,
    private appInitService: AppInitService
  ) {
    this.headers = new Headers({ 'Content-type': 'application/json' });
    this.options = new RequestOptions({ headers: this.headers });
    this.customerID = this.appInitService.userData.customer_id;
  }

  /**
   * open buy for the passed param
   * param dovrebbe contenere l'id del prodotto e il template da attivare (più altri possibili dati? chi lo sa, il tempo ce lo dirà)
   */
  openBuyModal(param?: any): void {
    this.buySubject.next(param);
  }

  /**
   * ask price standard
   */
  askPrice(item: any, params?:any): Observable<any> {
    var procedureName = 'ask-price';
    if(item.is_master) {
      procedureName = 'ask-parent-price';
    }

    if(params && params.customerID)
      this.customerID = params.customerID || 1; // in caso prendere il customer dell'utente

    if(item.customprice)//usato in caricamento del carrello, dal db arriva il campo customprice e deve essere usato per il calcolo del prezzo
      item.customUnitPrice = item.customprice;
    
    if(item.custom_tax_rate) {
      item.customTaxRate = item.custom_tax_rate;
    }

    /* in caso prendere il customer dell'utente
    if(!this.customerID) {
      return;
    }
    */

    var payload;

    if((item.customDiscount1 != null && item.customDiscount1 !== "") || (item.customDiscount2 != null && item.customDiscount2 !== "") 
        || (item.customDiscount3 != null && item.customDiscount3 !== "") || (item.customDiscount4 != null && item.customDiscount4 !== "")) {
      if(item.customDiscount1 == null)
          item.customDiscount1 = "";
      if(item.customDiscount2 == null)
          item.customDiscount2 = "";
      if(item.customDiscount3 == null)
          item.customDiscount3 = "";
      if(item.customDiscount4 == null)
          item.customDiscount4 = "";
      
      item.customDiscountString = item.customDiscount1 + "+" + item.customDiscount2 + "+" + item.customDiscount3 + "+" + item.customDiscount4;

      if(item.priceDetail) {
        item.priceDetail.customDiscountString = item.customDiscountString;
      }
    } else {
      item.customDiscount = null;
      item.customDiscountString = null;
    }

    item.askingPrice = true;

    if(!this.askingPriceCounter)
      this.askingPriceCounter = 1;

    this.askingPriceCounter++;

    if(item.priceDetail && item.priceDetail.productID && item.priceDetail.productID >= 0) {
      payload = item.priceDetail;
      payload.quantity = item.quantity;
      payload.customUnitPrice = item.customUnitPrice;
      payload.customUnitTax = item.customTax;
      payload.customTaxRate = item.customTaxRate;
      payload.appliedPriceListID = null; // nota, è la PL a ricavare il listino corretto in base alle info passate
      delete payload.appliedPriceListName;
      delete payload.appliedPriceListNumber;

      payload.customerID = payload.customerID || this.customerID;

      payload.requestID = this.askingPriceCounter;

      return this.http.post(this.baseApiUrl + '/' + procedureName, payload)
      .pipe(map((response: Response) => {
        let result = this.refinePriceDetail(response, item, payload);
        return result;
      }));
    } else {
      if(!item.quantity || item.quantity == undefined)
        item.quantity = 0;

      payload = { 
        customerID:                this.customerID,
        productID:                 item.id,
        umID:                      item.um_id,
        appliedPriceListID:        null, // nota, è la PL a ricavare il listino corretto in base alle info passate
        quantity:                  item.quantity,
        freeQuantity:              item.free_quantity,
        customDiscount:            item.customDiscount,
        customDiscountString:      item.customDiscountString,
        customUnitPrice:           item.customUnitPrice,
        customTax:                 item.customTax,
        customTaxRate:             item.customTaxRate,
        customBenefitValue:        item.customBenefitValue,
        useThisCustomBenefitValue: item.useThisCustomBenefitValue,
        documentID:           (params && params.documentID) ? params.documentID : null,
        charge:               null
      };

      payload.requestID = this.askingPriceCounter;

      return this.http.post(this.baseApiUrl + '/' + procedureName, payload)
      .pipe(map((response: Response) => {
        let result = this.refinePriceDetail(response, item, payload);
        return result;
      }));
    }
  }
  
  private refinePriceDetail(result: any, item:any, payload: any): any {
    item.askingPrice = false;
    
    if(result && result.requestID && result.requestID != payload.requestID) {
      console.log("Risposta dalla askPrice ignorata per responseID non valido");
      return;
    }

    if(!result.error) {
      item.priceDetail = result;  
      item.quantity = item.priceDetail.quantity;
      item.step_quantity = item.priceDetail.stepQuantity;  
    }
    return result; 
  }

}
