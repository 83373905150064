import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

//Clarity UI dependencies
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ClarityModule } from "@clr/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MasterComponent } from './components/master/master.component';
import { AppNotificationComponent } from '@modules/shared-module/shared-module.module';

import { EcommerceSharedModule} from '@modules/ecommerce-shared-module/ecommerce-shared-module.module';
import { AppInitService} from '@modules/shared-module/shared-module.module';

import { BuyModule } from '@modules/buy-module/buy-module.module';

import { DefaultDataServiceConfig, EntityDataModule, EntityServices } from '@ngrx/data';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromWishlist from './ngrx/reducers/wishlist.reducer';


//import { CartStateService,WishlistStateService,ComparisonStateService } from './ngrx/services/ngrx.service';
import { entityConfig } from './ngrx/entities/ngrx.entity-metadata';
import { AppEntityServices, CartItemService } from './ngrx/services/ngrx.service';


 
export function initializeAppData(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.Init();
  }
}


@NgModule({
  declarations: [
    AppComponent,
    MasterComponent,
    AppNotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClarityModule,
    HttpClientModule,
    FormsModule,
    EcommerceSharedModule,
    BuyModule,
    StoreModule.forRoot({ wishlist: fromWishlist.updateWishlistReducer } ),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig)
  ],
  bootstrap: [AppComponent],
  providers:[
    AppEntityServices,
    CartItemService,
    { provide: EntityServices, useExisting: AppEntityServices },

    AppInitService,
    { provide: APP_INITIALIZER,useFactory: initializeAppData, deps: [AppInitService], multi: true},
    
  ],
})
export class AppModule { }
