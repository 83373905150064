import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs';
 
@Injectable({
    providedIn: 'root'
  })
export class MessageService {

    private messageSubject: Subject<Array<any>>;
    private messages: Array<any>;
    private alertType: any;
    private alertIcon: any;
    private timeout: number;

    constructor(){
        this.messageSubject = new  Subject<Array<any>>();
        this.messages = new Array<any>();
        this.alertType = {
            error: "alert-danger",
            success: "alert-success",
            warning: "alert-warning",
            info: "alert-info"
        };
        this.alertIcon = {
            error: "exclamation-circle",
            success: "check-circle",
            warning: "exclamation-triangle",
            info: "info-circle"
        };
        this.timeout = 5000;
    }

    /**
     * Broadcast message to all subscribers
     * @param message 
     * @param severity 
     * @param icon 
     * @param link
     */
    public sendMessage(message: string, severity?: string, icon?: boolean, link?: string) {
        let messageObj: Object = { 
            text: message,
            severity: severity ? this.alertType[severity] : this.alertType.info,
            icon: icon ? this.alertIcon[severity] : null,
            link: link? link: null
        };
        this.messages.push(messageObj);
        this.messageSubject.next(this.messages);
        setTimeout(() => this.clearMessage(messageObj), this.timeout);
    }

    public logError(error:any){
        console.log(error);
    }
 
    public clearMessage(message: any): void{
        let index = this.messages.indexOf(message);
        this.messages.splice(index,1);
    }
 
    public getMessages(): Observable<Array<any>> {
        return this.messageSubject.asObservable();
    }

}