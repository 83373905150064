import { Injectable, Injector } from '@angular/core';
import { Colors } from './color.model';
 
@Injectable({
  providedIn: 'root'
})
export class ColorService{
  
  constructor(){ }
    
  public getBorderColorClass(colorId: number): string{
    return 'border_' + Colors[colorId];
  }

  public getBackgroundColorClass(colorId: number): string{
    return 'bkg_' + Colors[colorId];
  }

}