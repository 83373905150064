import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterComponent } from './components/master/master.component';

const routes: Routes = [
  {
    path: ':lang/ecommerce',
    component: MasterComponent,
    children: [
      /*{childrenRoutes}*/
{ path : 'cart' , loadChildren : './cart/cart.module#CartModule' },
{ path : 'category' , loadChildren : './category/category.module#CategoryModule' },
{ path : 'comparison' , loadChildren : './comparison/comparison.module#ComparisonModule' },
{ path : 'item' , loadChildren : './detail/detail.module#DetailModule' },
{ path : 'download' , loadChildren : './download/download.module#DownloadModule' },
{ path : 'home' , loadChildren : './homepage/homepage.module#HomepageModule' },
{ path : 'interactive' , loadChildren : './interactive/interactive.module#InteractiveModule' },
{ path : 'my-documents' , loadChildren : './my-documents/my-documents.module#MyDocumentsModule' },
{ path : 'search' , loadChildren : './search/search.module#SearchModule' },
{ path : 'wishlist' , loadChildren : './wishlist/wishlist.module#WishlistModule' }

    ],
    /*{mainRoutes}*/
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
