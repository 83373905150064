import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http'; 

import { Observable, Subject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { CommonService } from '@modules/shared-module/shared-module.module';

/*
{
  //providedIn: 'root'
} */
@Injectable()
export class DownloadService {
  private baseApiUrl: string = '/api/v2/ecommerce/download';

  private headers: Headers;
  private options: RequestOptions;

  loading: boolean = false;

  constructor (
		private http: Http,
    private commonService: CommonService
	) {
    this.headers = new Headers({ 'Content-type': 'application/json' });
    this.options = new RequestOptions({ headers: this.headers });
  }
 
  /* caricamento dati download di un prodotto
   */

  getProductDownloadData(productID: number): Observable<any> {
    return this.http.get(this.baseApiUrl + '/product-data/' + productID)
    .pipe(map((response: Response) => {
      let data: any = {};
      if (response) {
        switch(response.status) {
          case 200: {
            data = response.json();
            break;
          }
          default:
            break;  
        }
      }
      return data;
    }));
  }
}
