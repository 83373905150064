import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { ProductListService } from '../../../services/product-list.service';

import { MessageService } from '@modules/shared-module/shared-module.module';
import { UtilityService } from '@modules/shared-module/shared-module.module';
import { CommonService } from '@modules/shared-module/shared-module.module';

import { Observable, Subscription, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';


@Component({
    selector: 'facets-component',
    templateUrl: './facets.component.html',
    styleUrls: ['./facets.component.scss'],
    encapsulation: ViewEncapsulation.None,    // NB: necessario per far recepire gli styles a tag all'interno di innerHtml
})

export class FacetsComponent implements OnInit {
    lastFacet: any; // inserire struttura dato?

    facetLog: Array<any>; // anche qui, capire se ci sono problemi a mantenerli qui, dato che vengono visualizzati al di fuori del componente

    constructor( 
        protected activeRoute: ActivatedRoute,
        protected router: Router,
        protected location: Location,
        protected service: ProductListService,
        private commonService: CommonService,
    ){ }

    ngOnInit() { 
        // per variazioni run-time es. selezione di un facet o cambio categoria
        this.service.facetsSubject.subscribe(facets => {
            // gestione client del lastFacet -> ultimo facet selezionato.
            // in questo caso all'utente va lasciato l'ultimo facet per avere ancora i valori "fratelli" dell'ultimo selezionato
            // per evitare di passare un ulteriore paramentro lungo al server intanto lascio qui questa gestione
            if(this.lastFacet) {
                for (var i = 0; i < this.service.facets.length; ++i) {
                    var f = this.service.facets[i];
                    if(this.lastFacet && f.id == this.lastFacet.id) {
                        this.service.facets[i] = JSON.parse(JSON.stringify(this.lastFacet));
                        break;
                    } 
                }
                // dopo l'utilizzo resetto il this.lastFacet, che non servirà più, anzi causa problemi in caso di utilizzo tasto back del browser
                this.lastFacet = null;
            }
        });
    }

    refineFilterChange(facet: any): void {
        // salvataggio ultimo filtro selezionato per corretto comportamento filtri
        this.saveClickedFacet(facet);
        // update della situazione filtri, sia nella stringa dei facet, sia nel salvataggio della struttura corrente
        let updateResult: any = this.getUpdatedFacetStatus();

        this.router.navigate([], {
            queryParams: updateResult,
            queryParamsHandling: "merge",
            relativeTo: this.activeRoute
        }).then(()=>{
            this.commonService.getPageDataService().setCurrentQueryString(this.router.url);
        });

    }


    // salvataggio nel componente dell'ultimo facet selezionato per mantenera aperta la lista dei filtri relativi
    saveClickedFacet(facet: any): void {
        this.lastFacet = facet;

        var getCheckedValuesCount = function(facet) {
            var checkedCount = 0;
            for (let i = 0; i < facet.values.length; i++) {
                let value = facet.values[i];
                if (value.checked) 
                    checkedCount++;
            }
            return checkedCount;
        };

        if(!this.lastFacet.grouped) {
            this.lastFacet.checkedCount = getCheckedValuesCount(this.lastFacet);
        } else {
            let totalGroupCount = 0;
            for(var ig = 0; ig < this.lastFacet.groups.length; ig++) {
                totalGroupCount += getCheckedValuesCount(this.lastFacet.groups[ig]);
            }
            this.lastFacet.checkedCount = totalGroupCount;
        }

        // se count == 0 -> facet totalmente deselezionato
        if(!this.lastFacet.checkedCount)
            this.lastFacet = null;
    }
    // vedere se va usata in caricamento come nella precedente versione.. io direi anche di no
    /*
        SPIEGAZIONE GIRO PRECEDENTE: 
            1) la selezione del facet in interfaccia richiamava la funzione saveClickedFacet
            2) successivamente veniva eseguito il caricamento della lista prodotti e all'interno della loadProducts era presente la chiamata a questa funzione
            3) quindi il salvataggio dei qs era fatto prima del caricamento

        - io unirei tutto ciò con la saveClickedFacet, e in fondo a questa eseguire il cambio URL per eseguire il caricamento corretto nella resolve in automatico

    */
    getUpdatedFacetStatus(): void {
        let currentFacetsStatus = "";
        let currentFacetsFilter = "";
        var facetStatus = {};

        var facetsRun = function(f, fiedID) {              
            for(let j = 0; j < f.values.length; j++) {
                let v = f.values[j];
                if(v.checked) {
                    if(!facetStatus[fiedID]) {
                        facetStatus[fiedID] = {
                            chk: []
                        };
                    }
                    // facetStatus[fiedID].chk.push({val: v.value});
                    facetStatus[fiedID].chk.push({val: v.id}); // test per id
                }
            }
        };

        for (let i=0; i < this.service.facets.length; i++) {
            let f = this.service.facets[i];

            if(!f.grouped) {
                facetsRun(f, f.id);
            } else {
                for(var ig = 0; ig < f.groups.length; ig++) {
                    facetsRun(f.groups[ig], f.id);
                }
            }
        }
        currentFacetsStatus = JSON.stringify(facetStatus);
        currentFacetsFilter = this.getFacetFilter();

        if(currentFacetsStatus == undefined || currentFacetsStatus == null || currentFacetsStatus.length == 0) {
            currentFacetsStatus = "{}";
        }

        /* TODO: impostazione dei 2 parametri nell'URL come in modulo search

        if(Object.keys(JSON.parse(currentFacetsStatus)).length == 0)     
        currentFacetsStatus = null;

        if(!currentFacetsFilter || currentFacetsFilter.length == 0)
        currentFacetsFilter = null;

        $location.search('ff', currentFacetsFilter); 
        $location.search('fs', currentFacetsStatus); 
        */

        let result: any = {
            ff: currentFacetsFilter,
            fs: currentFacetsStatus
        };
        return result;
    }

    // funzione che calcola la stringa del filtro facets in base ai campi attualmente selezionati
    getFacetFilter(): string {
        let filter = "";

        var facetsRun = function(facet) {
            for (var vidx=0; vidx < facet.values.length; vidx++) {
                var value = facet.values[vidx];
                if (value.checked) {
                  facetFilter = facetFilter + value.id + "|";
                }
            }
        };

        for (let i=0; i < this.service.facets.length; i++) {
            let facet = this.service.facets[i];
            var facetFilter = "";

            if(!facet.grouped) {
                facetsRun(facet);
            } else {
                // gestione facet raggruppati
                for(var ig = 0; ig < facet.groups.length; ig++) {
                    facetsRun(facet.groups[ig]);
                }
            }

            if (facetFilter.length > 0) {
                facetFilter = "(" + facetFilter.substring(0, facetFilter.length-1) + ")";
                if (filter.length > 0) {
                    filter = filter + "&";
                }
                filter = filter + facetFilter;
            }
        }

        return filter;
    }

}