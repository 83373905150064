import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Input, forwardRef } from '@angular/core';
import { NgSwitch } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonService } from '../../../services/common/common.service';
import { FilterToolbarService } from '../../../services/filter-toolbar/filter-toolbar.service';
import { Observable, Subscription, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { query } from '@angular/animations';
//

@Component({
  selector: 'app-filter-toolbar',
  templateUrl: './filter-toolbar.component.html',
  styleUrls: ['./filter-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,//questa property permette di andare a sovrascrivere le regole css dei componenti "padri" di questo compoente
    //quindi permette di scrivere dentro filter-toolbar.component.scss delle regole che cambiano lo stile dei contenitori
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FilterToolbarComponent),
    },
    NgSwitch,
    CookieService, 
    FilterToolbarService
  ]
})

export class FilterToolbarComponent implements OnInit{

  private isToolbarOpened: boolean = false;
  private showModal: boolean = false;
  private showUpdateModal: boolean = false;
  private showDeleteModal: boolean = false;
  private showSelectedFilter: boolean = false;
  private form: FormGroup;
  private ready: boolean;
  private stackViewItemSelected: boolean;
  private data: any;
  private savedFilters : Array<any>;
  private activeFilters: Array<any>;
  private activeFiltersSubcription: Subscription;
  private filterName: string = "";
  private selectedFilter: any;
  private currentFilter: any;
  private jsonQueryTranslation:any;
  constructor(private cdRef:ChangeDetectorRef, private commonService: CommonService, private cookieService: CookieService, private filterService: FilterToolbarService, private router: Router) { 
    this.ready = false;
    this.form = new FormGroup({});
    this.savedFilters = new Array<any>();
    this.selectedFilter = {};
  }

  //questo campo sarà valorizzato con il contenuto dell attributo [data] passato (vedi file masters.component.html)
  //il suo valore sarà un Observable<any> (da qui il '$': è una convenzione di angular per indicare le variabili Observable)
  //successivamente dentro ngAfterViewChecked l'Observable viene tratoddo in json con i dati del filtro.
  @Input("data")
  set item(data$:Observable<any>) {
    if(data$ && !this.ready){
      data$.subscribe(val => {
        this.data = val;
        //if(this.data.items && this.data.items.length){
        this.initFormModel(val);
        /*
        si è trattato di un esperimento temporaneo. Ora che la confurazione di un modulo è stata spostata in un file, con anche i filtri, questa
        sezione non è più necessaria
        }
        else{
          this.commonService.getUtilityService().getJson("/jsonConfigurations/filters/" + this.data.config.id + "/base.json", 
              function(d){
                console.log(d);
                this.data.items = d;
                val.items = d;
                this.initFormModel(val);
              }.bind(this));
        }*/
      })
      this.cdRef.detectChanges();
    }
  }

  ngOnInit() { 
    this.activeFiltersSubcription = this.commonService.getActiveFilters().subscribe(
      filters => { 
        if(!filters || !filters.length)
          this.onClear();
        else 
          this.activeFilters = filters;
      }
    );

    this.commonService.getUtilityService().getJson("/jsonConfigurations/filters/queryTranslation.json", 
        function(d){
          this.jsonQueryTranslation = d;
        }.bind(this));
    
  }

  ngOnDestroy(): void {
    this.activeFiltersSubcription.unsubscribe();
  }


  ngAfterViewChecked() {
    
  }

  private initFormModel(data): void {

    let formModel = {};

    for(let item of data.items){
      if(!item.children){ 
        formModel[item.id] = new FormControl('');
      } 
      else { // Form item with children (stack view)
        let childrenFormModel = {};
        for (let block of item.children){
          if(block.children){
            childrenFormModel[block.id] = new FormGroup({});
            for(let child of block.children){
              childrenFormModel[block.id].addControl(child.id, new FormControl(''));
            }
          } else {
            childrenFormModel[block.id] = new FormControl('');
          }
        }
        formModel[item.id] = new FormGroup(childrenFormModel); 
      }
    }
    this.form = new FormGroup(formModel);

    let cookieString = this.cookieService.get('savedFilters');
    if(cookieString != "") {
      this.savedFilters = JSON.parse(cookieString); 
      var selectedFilterInCookies = this.savedFilters.filter(element => element.selected === true).shift();
      this.selectedFilter = selectedFilterInCookies ? selectedFilterInCookies : {name: '', values: [], selected: false, id: ''};
      this.showSelectedFilter = (this.selectedFilter && this.commonService.isObjectEmpty(this.selectedFilter)) ? false : true; 
      this.reloadSelectedFilter();
    }
    else {
      this.filterService.read(this.router.url).subscribe(
        (data: any) => {
          if(data){
            this.savedFilters = data;
          }
        },
        error => {
          console.log(error);
        }
      );  
    }
    this.ready = true;
  }

  private toggleSelectedFilter(filter){
    for(let filter of this.savedFilters){
      filter.selected = false;
    }
    filter.selected = !filter.selected;
    this.selectedFilter = filter;
    this.showSelectedFilter = true;
    this.reloadSelectedFilter();
  }

  private reloadSelectedFilter(){
    this.form.reset();
    this.deselectStackViewItems(this.data.items.find(x => x.type == 'stackView').children, 0);
    for(let item of this.selectedFilter.values){
      if(this.form.controls[item.id]){
        this.form.controls[item.id].patchValue(item.value);
      } else {
        if(item.group)
          this.setSelected(item.id, item.group, item.parentGroup, true);
        else 
          this.setSelected(item.id, item.parentGroup, null, true);
      }
    }
    this.commonService.setActiveFilters(this.selectedFilter.values);
  }

  private setSelected(id: string, group: string, parentGroup?: string, selected?: boolean): void{ 
    !this.stackViewItemSelected ? this.stackViewItemSelected = true : this.stackViewItemSelected = false;
    this.getNestedControl(group, parentGroup).controls[id].patchValue(id);
    //Toggle check icon on stack view
    this.selectStackViewItem(this.data.items.find(x => x.type == 'stackView').children, 0, id, selected);
  }

  private getNestedControl(group, parentGroup?): any{
    if(!parentGroup)
      return this.form.get(group);
    return this.form.get(parentGroup).get(group);
  }

  private toggle(): void{
    this.isToolbarOpened = !this.isToolbarOpened;
  }

  private onChange(): void {
    this.setActiveFilters();
  }

  private onClear(): void {
    this.form.reset();
    this.stackViewItemSelected = false;
    this.selectedFilter = {};
    this.showSelectedFilter = false;
    this.deselectStackViewItems(this.data.items.find(x => x.type == 'stackView').children, 0);
    for(let i of this.savedFilters)
      i.selected = false;
    //Reset form model and observables
    this.onChange();
  }

  private onSave(action): void{
    switch(action){
      case 'create':
        let filter = {'name': this.filterName, 'values': this.activeFilters, 'route': this.router.url};
        this.savedFilters.push(filter);
        this.filterService.create(filter).subscribe(
          data => {
            this.selectedFilter = filter;
            this.selectedFilter.filter_id = data;
            this.showSelectedFilter = true;
            this.cookieService.set('savedFilters',JSON.stringify(this.savedFilters));
            this.commonService.sendMessage(this.commonService.getFixedWord('GENERAL_FILTER_SAVED'), 'success', true);
          },
          error => {
            this.commonService.sendMessage(this.commonService.getFixedWord('GENERAL_FILTER_SAVE_ERROR'), 'error', true);
          }
        );  
        break;
      case 'update':
        this.selectedFilter.values = this.activeFilters;
        let done = false;
        for(let i = 0; i < this.savedFilters.length && !done; i++){
          if(this.savedFilters[i].filter_id == this.selectedFilter.filter_id){
            this.savedFilters[i].values = this.activeFilters;
            done = true;
          }
        }
        this.filterService.update(this.selectedFilter).subscribe(
          data => {
            this.cookieService.set('savedFilters',JSON.stringify(this.savedFilters));
            this.commonService.sendMessage(this.commonService.getFixedWord('GENERAL_FILTER_UPDATED'), 'success', true);
          },
          error => {
            this.commonService.sendMessage(this.commonService.getFixedWord('GENERAL_FILTER_UPDATE_ERROR'), 'error', true);
          }
        );
        break;
      default:
        break;
    }

  }

  private checkForm(action): void {
    switch(action){
      case 'create':
        if(this.form.pristine && this.stackViewItemSelected == false){
          this.commonService.sendMessage(this.commonService.getFixedWord('GENERAL_NO_FILTER_SELECTED'), 'warning', true);
          break;
        } else {
          this.showModal = true;
          break;
        }
      case 'update':
        this.showUpdateModal = true;
        break;
    }
  }

  private setActiveFilters(): void {

    let filters = [];

    for(let item of this.data.items){
      let currentFilter: any = {};
      switch(item.type){
        case 'stackView':{
          for(let child of item.children){
            if(child.children){
              for(let subchild of child.children){
                let value = this.getNestedControl(child.id, item.id).controls[subchild.id].value; 
                if(subchild.id == value) {
                  currentFilter = {
                    'id': subchild.id,
                    'value': value,
                    'userFriendlyLabel': value,
                    'parentGroup': item.id,
                    'group': child.id
                  }
                  filters.push(currentFilter);
                }
              }
            } else {
              let value = this.getNestedControl(item.id).controls[child.id].value;
              if(child.id == value && child.selected){
                currentFilter = {
                  'id': child.id,
                  'value': value,
                  'userFriendlyLabel': value,
                  'parentGroup': item.id
                }
                filters.push(currentFilter);
              }
            }
            currentFilter = {};
          }
        }
        break;
        case 'select': {
          for(let option of item.options){
            if(option.value == this.form.value[item.id]){
              currentFilter.id = item.id;
              currentFilter.value = option.value;
              currentFilter.userFriendlyLabel = option.label;
              currentFilter.color = item.color;
            }
          }
        }
        break;
        case 'input': {
          (this.form.value[item.id] && this.form.value[item.id] !== '') ? currentFilter = { 
            'id': item.id, 
            'value': this.form.value[item.id], 
            'color' : item.color ? item.color : null,
            'userFriendlyLabel': this.form.value[item.id],
          } : currentFilter = {};
        }
        break;
      }
      if(!this.commonService.isObjectEmpty(currentFilter))
        filters.push(currentFilter);
    } 
    this.commonService.setActiveFilters(filters.length > 0 ? filters : [{}]);

    if(filters.length > 0 && this.jsonQueryTranslation){
      this.translateToQuery(filters);
    }
  }

  private translateToQuery(filters):string{
    let query:string = "";
    
    return query;
  }

  private selectStackViewItem(array, index, id, selected){
    if(index >= array.length) 
      return;
    if(array[index].id == id && !array[index].children) {
      array[index].selected = selected ? selected : !array[index].selected;
      return;
    }
    if(array[index].children)
      this.selectStackViewItem(array[index].children, 0 , id, selected);
    return this.selectStackViewItem(array, index + 1, id, selected);
  }

  private deselectStackViewItems(array, index){
    if(index >= array.length) return;
    array[index].selected = false;
    if(array[index].children)
      this.deselectStackViewItems(array[index].children, 0);
    return this.deselectStackViewItems(array, index + 1);
  }

  private deleteFilter(){
    if(this.currentFilter === this.selectedFilter) {
      this.showSelectedFilter = false;
      this.selectedFilter = {};
    }
    this.savedFilters.forEach((item, index) => {
      if(item === this.currentFilter) this.savedFilters.splice(index,1);
    });
    this.filterService.delete(this.currentFilter.filter_id).subscribe(
      data => {
        this.cookieService.set('savedFilters',JSON.stringify(this.savedFilters));
        this.commonService.sendMessage(this.commonService.getFixedWord('GENERAL_FILTER_DELETED'), 'success', true);
      },
      error => {
        this.commonService.sendMessage(this.commonService.getFixedWord('GENERAL_FILTER_SAVE_ERROR'), 'error', true);
      }
    );  

  }

/*   writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState(isDisabled: boolean): void {}  */
}

