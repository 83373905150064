import { NgModule } from '@angular/core';
import { ClarityModule } from "@clr/angular";

import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule }   from '@angular/forms';
import { ImgFallbackModule } from 'ngx-img-fallback';

//Directives
import {BuyTemplateDirective} from './directives/buy-template.directive';

//Services
import {BuyService} from './services/buy.service';
//import {CartService} from './services/cart.service';

import {StandardBuyService} from './services/specific/standard-buy.service';
import {GridBuyService} from './services/specific/grid-buy.service';

//Components
import { BuyComponent } from './components/buy.component';
import { StandardBuyComponent } from './components/standard/standard-buy.component';
import { GridBuyComponent } from './components/grid/grid-buy.component';


import { PriceFormatterComponent } from './components/utils/price-formatter/price-formatter.component';
import { PromoModalComponent } from './components/utils/promo-modal/promo-modal.component';

/* In questo elenco vanno inseriti service, models, enums, ovvero gli elementi che non vengono riportati all'interno di @NgModule sotto */
export {  
  // service
  BuyService, 
  //CartService,     // aggiunto per usarlo anche dal cart...
  
  // components
  BuyComponent
};

@NgModule({
  imports: [ 
    ClarityModule,
    CommonModule,
    NgbModule, 
    FormsModule,
    ImgFallbackModule
  ],
  declarations:[    
    // directives
    BuyTemplateDirective,
    //components
    BuyComponent,
    StandardBuyComponent,
    GridBuyComponent,
    // utils components
    PriceFormatterComponent,
    PromoModalComponent
  ],
  entryComponents: [ StandardBuyComponent, GridBuyComponent ],
  providers:[
    // CartService ,
    // BuyService,
    StandardBuyService,
    GridBuyService
  ],
  exports: [
    //components
    BuyComponent,
    PromoModalComponent
  ],
})
export class BuyModule { }

