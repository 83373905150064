import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { PageData } from './page-data.model';
//import { Http } from '@angular/http'; 
import { HttpClient } from '@angular/common/http';
 
declare var cultureID: number;
//In TypeScript the declare keyword is used where you want to define a variable that may not have originated from a TypeScript file.
//questa variabile sarà inizializzata nell'ejs. Questo serve per poter caricare la configurazione del modulo in base alla culture
//senza attendere il caricamento dell'utente.


/**
 * Gestisce il caricamento dei dati della pagina. Header, footer, eventuali toolbars (es: Filtri)
 * Il service ha due modalità di funzionamento.
 * Il primo si va a caricare la configurazione di un modulo da un file json e si attiva usando il metodo loadPageData
 * il secondo riceve direttamente in input la configurazione e si attiva usando il metodo setPageData
 */
@Injectable({
    providedIn: 'root'
  })
export class PageDataService {

    private pageDataSubject: Subject<PageData>;
    private formChangesSubject: Subject<any>;
    private activeFiltersSubject: Subject<any>;
    private searchSubject: Subject<any>;
    private pageDataObs: Observable<PageData>;
    private currentQueryString: string;


    //constructor(){
    constructor(private http:HttpClient){        
        this.pageDataSubject = new Subject<PageData>(); 
        this.formChangesSubject = new Subject<any>(); 
        this.activeFiltersSubject = new Subject<any>(); 
        this.searchSubject = new Subject<any>();
        this.currentQueryString = '';
    }

    /**
     * Inizializza il servizio facendo in modo che si prenda i dati della pagina dal file json di configurazione
     * @param moduleName nome del modulo
     * @param runtimeConfiguration 
     */
    public loadPageData(moduleName:string, runtimeConfiguration:any){
        this.pageDataObs = this.http.get<PageData>('/jsonConfigurations/'+moduleName+'.'+cultureID+'.json');       
    }


    /********************************/
    /********************************/
    /* 
        Metodi SET per gli observable a livello di pagina
        questi observable sono stati inseriti in questo punto in quanto possono essere comuni a 
        più pagine.
        Funzionamento: 
        -le componenti che "producono" i dati (es: header con box di ricerca) useranno
        le set passando il dato.
        -le componenti che "usano" i dati (es: search nel nell'ecommerce) useranno la get
        per registrarsi e ricevere gli "aggiornamenti"
    */
    /********************************/
    /********************************/
    /**
     * 
     * @param data 
     */
    public setPageData(data:PageData): void {
        this.pageDataSubject.next(new PageData(data));
    }

    public setActiveFilters(filters): void {
        return this.activeFiltersSubject.next(filters);
    }

    public setFormChanges(data): void {
        return this.formChangesSubject.next(data);
    }

    public setSearchRequest(data): void {
        return this.searchSubject.next(data);
    }

    public setCurrentQueryString(queryString: string) {
        this.currentQueryString = queryString;
    }

    get getCurrentQueryString(): string {
        return this.currentQueryString;
    }


    /********************************/
    /********************************/
    /* 
        Metodi GET per gli observable a livello di pagina
        questi observable sono stati inseriti in questo punto in quanto possono essere comuni a 
        più pagine.
        Funzionamento: 
        -le componenti che "producono" i dati (es: header con box di ricerca) useranno
        le set passando il dato.
        -le componenti che "usano" i dati (es: search nel nell'ecommerce) useranno la get
        per registrarsi e ricevere gli "aggiornamenti"
    */
    /********************************/
    /********************************/
    
    public getPageData(): Observable<PageData> {
        if(this.pageDataObs)
            return this.pageDataObs;
        else 
            return this.pageDataSubject.asObservable();
    }

    public getActiveFilters(): Observable<any> {
        return this.activeFiltersSubject.asObservable();
    }

    public getFormChanges(): Observable<any>{
        return this.formChangesSubject.asObservable();
    }
    
    public getSearchRequest(): Observable<any> {
        return this.searchSubject.asObservable();
    }

}