import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http'; 

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { CommonService } from '@modules/shared-module/shared-module.module';
import { AppEntityServices } from '../../../ngrx/services/ngrx.service';


/*
{
  //providedIn: 'root'
} */
@Injectable()
export class WishlistService {
  private baseApiUrl: string = '/api/v2/ecommerce/wishlist';

  private headers: Headers;
  private options: RequestOptions;

  loading: boolean = false;
  list: Array<any> = null;
  currentWishlist: any;

  constructor (
		private http: Http,
    private commonService: CommonService,
    public entityServices: AppEntityServices,
	) {
    this.headers = new Headers({ 'Content-type': 'application/json' });
    this.options = new RequestOptions({ headers: this.headers });
    this.currentWishlist = {
      'data': {},
      'details': []
    } 
  }
 
  public getNgrxService(): any{
    return this.entityServices.wishlistService
  }
  /* caricamento delle wishlist dell'utente (per selezione in aggiunta prodotto)
     - per evitare di caricare ad ogni click le wishlist le tengo in memoria nel service o nel component
   */
  getList(): Observable<Array<any>> { 
    if(this.loading)
      return null; 

    this.loading = true;

    return this.http.get(this.baseApiUrl + '/all', this.options)
    .pipe(map((response: Response) => {
      if (response) {
        switch(response.status) {
          case 200: {
            let data = response.json();
            this.list = data;
            break;
          }
          default:
            break;  
        }
      }
      this.loading = false;
      return this.list;
    }));
  }

  /* aggiunta nuova wishlist
   * 
   */
  addWishlist(name: string): Observable<any>{
    let payload = {
      name: name
    };
    //Ngrx service
    return this.entityServices.wishlistService.add(payload).pipe(map((response: Response) => {
      let result = {};
      if (response) {
        let data: any = response;
        result = data;
        this.list.push(data);
      } 
      return result;
    }));
  }

  /* Gestione articoli  wishlist
   * 
   */
  addOrRemoveProduct(product: any, wishlistID: any, action: string): Observable<any>{
    
    let payload = {
      product_id: product.id,
      wishlist_id: wishlistID,
      action: action
    };

    return this.entityServices.wishlistService.update(payload).pipe(map((response: Response) => {
      let result = {};
      if (response) {
        let data: any = response;
        result = data;
      } 
      return result;
    }));
  }


  getWishlistbyID(wishlistID: number): Observable<Array<any>> { 
    return this.http.get(this.baseApiUrl + '/' + wishlistID, this.options)
    .pipe(map((response: Response) => {
      let result: Array<any>;
      if (response) {
        switch(response.status) {
          case 200: {
            let data = response.json();
            this.currentWishlist = data;
            result = data;
            break;
          }
          default:
            break;  
        }
      }
      return result;
    }));
  }


}
