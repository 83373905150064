import { Component, OnInit, HostListener, ViewEncapsulation, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { CommonService, SiteUserService, SiteUser, UtilityService, AppInitService } from '@modules/shared-module/shared-module.module';
/* import { SiteUserService } from '../../services/site-user/site-user.service'; */
import { Subscription, Observable } from 'rxjs';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { Environment } from '../../enum/environment.enum';

import { AppEntityServices } from '../../../ngrx/services/ngrx.service';
import { EntityCollectionService } from '@ngrx/data';
import { PopoverService } from '../../services/popover.service';
import { StringifyOptions } from 'querystring';

//import { CartStateService } from '../../../../../../../ecommerce-main/client/ecommerce-main/src/app/ngrx/services/user-lists.service';
/* import { ProductListToolbarCompoennt } from '../list/products-list/toolbar/toolbar.component'; */

import { ComparisonService } from '../../services/actions/comparison.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./styles/header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ CommonService ]
})
export class HeaderComponent implements OnInit {

  private userData: any;
  private headerData$: Observable<any>;
  private headerDataSubscription: Subscription;
  private headerData: any;
  private showMobileSubnav: boolean;
  private search: any;
  private showElements: any;
  private headerPosition: number;
  private stickyHeader: boolean;
  private isMobile: boolean;
  private siteUser:SiteUser;
  private environment: Environment;
  private utilityService: UtilityService;

  private loadingCart$: Observable<boolean>;
  private cart$: Observable<any[]>;

  private cartService: EntityCollectionService<any>;
  private comparisonService: EntityCollectionService<any>;
  private wishlistService: EntityCollectionService<any>;
  private lang: string;

  constructor(private commonService: CommonService,   
            protected router: Router, private eRef: ElementRef,
            protected siteUserService: SiteUserService,
            private entityServices: AppEntityServices,
            private popoverService: PopoverService,
            private activeRoute: ActivatedRoute,
            private appInitService: AppInitService
    ) { 

    this.lang = this.commonService.getUtilityService().getLangParam();

    this.search = {
      "text": '',
      "node_id": null
    };
    this.userData = new SiteUser();
    this.showElements = {
      'tools': false,
      'search': false,
      'cart': false,
      'wishlist': false,
      'comparison': false,
      'cultures': false
    };
    this.stickyHeader = false;

    this.headerData = {};
    this.headerDataSubscription = this.commonService.getHeaderData().subscribe(
      data => {
        this.headerData = data;
      }
    );
    this.isMobile = this.commonService.isWindowSize('md', $(window).width(), '<');


    this.cartService = entityServices.cartItemService;//.getEntityCollectionService("CartItem");
    this.comparisonService = entityServices.comparisonService;
    this.wishlistService = entityServices.wishlistService;

    
    this.utilityService = this.commonService.getUtilityService();

    this.userData = this.appInitService.userData;
  }

  @ViewChild('ecommerceHeader', {static: false}) ecommerceHeader: ElementRef;

  objectKeys = Object.keys;

  ngOnInit() { 
    this.activeRoute.params.subscribe(params => {    
      this.lang = this.commonService.getUtilityService().getLangParam();
    });
  
    this.headerData = {  
      withContainer: false,
      showThemeSelector: true,
      showLanguageSelector: true,
      leftSideTools: [
        { 
          'iconShape': 'email',
          'label': null,
          'action': {
            'href' : 'mailto:info@lottiitaly.com',
          }
        },
        { 
          'iconShape': 'phone-handset',
          'label': null,
          'action': {
            'href' : 'tel:0386733876',
          }
        },
      ],
      toolbar: [
        { 
          'iconShape': 'certificate',
          'label': this.commonService.getFixedWord('MYDOCUMENTS_TITLE'),
          'badge': false,
          'action': {
            'type': 'link',
            'href': '/' + this.lang + '/ecommerce/my-documents'
          }
        },
/*         {
          'iconShape': 'download',
          'label': null,
          'badge': {
            'count': 2,
            'async': false
          },
          'action': {
            'type': 'popoverTrigger',
            'href': '#',
            'params': { 'target': '' }
          }
        }, */
        {
          'iconShape': 'star',
          'label': null,
          'badge': {
            'count': this.wishlistService.selectors$.count$,
            'async': true
          },
          'action': {
            'type': 'popoverTrigger',
            'popoverType': this['wishlist']
          }
        },
        {
          'iconShape': 'two-way-arrows',
          'label': null,
          'badge': {
            'count': this.comparisonService.selectors$.count$,
            'async': true
          },
          'action': {
            'type': 'popoverTrigger',
            'popoverType': this['comparison']
          }
        },
      ],
      context: [
        {
          'id': 'applicationsMenu',
          'iconShape': 'applications',
          'iconClass': 'is-solid',
          'label': null,
          'badge': false,
          'type': 'dropdown',
          'cssClasses': 'context-submenu-toggle position-relative',
          'children': [
            {
              'id': 'ecommerce',
              'label': 'E-commerce',
              'iconShape': 'store',
              'href': '/' + this.lang + '/ecommerce/home',
            },
            {
              'id': 'backoffice',
              'label': this.commonService.getFixedWord('GENERAL_ADMINISTRATION'),
              'iconShape': 'dashboard',
              'href': '/admin',
              'hide': !this.commonService.getSiteUserService().checkFunctionality('backend.access')
            },
            {
              'id': 'documents',
              'label': this.commonService.getFixedWord('HOMEPAGE_DOCUMENTS'),
              'iconShape': 'file-group',
              'href': '/' + this.lang + '/documents',
              'hide': !this.commonService.getSiteUserService().checkFunctionality('public.sellmoreweb.access')
            }/*,
            {
              'id': 'crm',
              'label': 'CRM',
              'iconShape': 'organization',
              'href': '#',
            }, */
          ],
          'hide': this.userData.is_anonymous
        },
/*         {
          'id': 'notifications',
          'iconShape': 'bell',
          'iconClass': '',
          'label': null,
          'badge': false,
          'type': '',
          'action': { },
        }, */
        {
          'id': 'usermenu',
          'iconShape': 'user',
          'iconClass': '',
          'label': this.userData.is_anonymous ? this.commonService.getFixedWord('GENERAL_LOG_IN') : this.userData.username,
          'badge': false,
          'type': 'dropdown',
          'cssClasses': 'context-submenu-toggle position-relative last',
          'children': [
/*             {
              'id': 'profile',
              'label': 'Profilo utente',
              'iconShape': 'home',
              'href': '#',
            }, */
            {
              'id': 'logout',
              'label': 'Log out',
              'iconShape': 'logout', 
              'href': '/logout',
              'hide': this.userData.is_anonymous
            },
            {
              'id': 'login',
              'label': this.commonService.getFixedWord('GENERAL_LOG_IN'),
              'iconShape': 'login',
              'href': '/login',
              'hide': !this.userData.is_anonymous
            },
          ],
        },
      ],
      middleTools: [
        {

        },
      ],
/*       submenu: [
         {
          type: 'megamenu-1',
          title: 'Catalogue',
          groups: [
            {
              title: 'Weapons',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/d82fda76-1716-41b0-88a4-51db972fe476.jpg',
              viewMore: {
                href: '#',
                title: 'View More'
              },
              links: [
                {
                  title: 'Beretta Weapons',
                  href: '#'
                },
                {
                  title: 'SAKO Weapons',
                  href: '#'
                },
                {
                  title: 'Victrix Weapons',
                  href: '#'
                },
                {
                  title: 'Umarex Weapons',
                  href: '#'
                },
              ]
            },
            {
              title: 'Optics',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/b2fdf282-006e-45da-917e-773107f0589a.jpg',
              links: [
                {
                  title: 'Buris',
                  href: '#'
                },
                {
                  title: 'Steiner',
                  href: '#'
                },
              ]
            },
            {
              title: 'Clothing',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/695d446f-5480-4f16-cfdb-29ef495ddada.jpg',
              viewMore: {
                href: '#',
                title: 'View More'
              },
              links: [
                {
                  title: 'B-Classic',
                  href: '#'
                },
                {
                  title: 'Gentleman Hunt',
                  href: '#'
                },
                {
                  title: 'Victory',
                  href: '#'
                },
                {
                  title: 'Culture',
                  href: '#'
                },
                {
                  title: 'Hunt',
                  href: '#'
                },
              ]
            },
            {
              title: 'Ammunitions',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/c3655880-7000-4f64-f31f-21f9f57c53c3.jpg',
              links: [
                {
                  title: 'Shells',
                  href: '#'
                },
                {
                  title: 'Bullets',
                  href: '#'
                },
                {
                  title: 'Cartridges',
                  href: '#'
                },
              ]
            },
          ],
          highlight: {
            href: '#',
            imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/fd7b2bad-54d3-4b28-df2f-4064a8a645a7.jpg'
          }
        }, 
         {
          type: 'megamenu-2',
          title: 'Test',
          groups: [
            {
              title: 'Weapons',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/d82fda76-1716-41b0-88a4-51db972fe476.jpg',
              viewMore: {
                href: '#',
                title: 'View More'
              },
              links: [
                {
                  title: 'Beretta Weapons',
                  href: '#'
                },
                {
                  title: 'SAKO Weapons',
                  href: '#'
                },
                {
                  title: 'Victrix Weapons',
                  href: '#'
                },
                {
                  title: 'Umarex Weapons',
                  href: '#'
                },
              ]
            },
            {
              title: 'Optics',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/b2fdf282-006e-45da-917e-773107f0589a.jpg',
              links: [
                {
                  title: 'Buris',
                  href: '#'
                },
                {
                  title: 'Steiner',
                  href: '#'
                },
              ]
            },
            {
              title: 'Clothing',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/695d446f-5480-4f16-cfdb-29ef495ddada.jpg',
              viewMore: {
                href: '#',
                title: 'View More'
              },
              links: [
                {
                  title: 'B-Classic',
                  href: '#'
                },
                {
                  title: 'Gentleman Hunt',
                  href: '#'
                },
                {
                  title: 'Victory',
                  href: '#'
                },
                {
                  title: 'Culture',
                  href: '#'
                },
                {
                  title: 'Hunt',
                  href: '#'
                },
              ]
            },
            {
              title: 'Ammunitions',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/c3655880-7000-4f64-f31f-21f9f57c53c3.jpg',
              links: [
                {
                  title: 'Shells',
                  href: '#'
                },
                {
                  title: 'Bullets',
                  href: '#'
                },
                {
                  title: 'Cartridges',
                  href: '#'
                },
              ]
            },
          ],
          highlight: {
            href: '#',
            imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/fd7b2bad-54d3-4b28-df2f-4064a8a645a7.jpg'
          }
        } 
      ] , */
      popoverSettings: {

        cart: {
          'type': 'cart',
          'title': this.commonService.getFixedWord('CART_PRODUCTS_IN_CART'),
          'button': {
            'label': this.commonService.getFixedWord('CART_BUTTON'),
            'iconShape': 'shopping-cart',
            'url': '/' + this.lang + '/ecommerce/cart'
          },
          'remove':  (item: any) => { this.cartService.removeItems([item]); }
        }, 
        wishlist: {
          'type': 'wishlist',
          'title': this.commonService.getFixedWord('WISHLIST_QUEUE'),
          'button': {
            'label': this.commonService.getFixedWord('WISHLIST_POPUP_BUTTON'),
            'iconShape': 'star',
            'url': '/' + this.lang + '/ecommerce/wishlist'
          },
          'remove':  (item: any) => { this.wishlistService.delete(item).subscribe(res => { this.commonService.sendMessage(this.commonService.getFixedWord('WISHLIST_REMOVED'), 'success', true);  }); }
        },
        comparison: {
          'type': 'comparison',
          'title': this.commonService.getFixedWord('COMPARE_QUEUE'),
          'button': {
            'label': this.commonService.getFixedWord('COMPARE_BUTTON'),
            'iconShape': 'two-way-arrows',
            'url': '/' + this.lang + '/ecommerce/comparison'
          },
          'remove':  (item: any) => { this.comparisonService.delete(item); }
        },
      } 
    };
    if(this.headerData.with_container){
      this.showMobileSubnav = this.commonService.isWindowSize('md', $(window).width(), '<');
    }

    for(let element of this.headerData.context){
      if(element.type == 'dropdown')
        this.showElements[element.id] = false;
    }
    
    this.activateHoverOnMenu();
    this.popoverService.setPopoverList(this.headerData.popoverSettings);

  }

  ngAfterViewInit(){
    this.headerPosition = this.ecommerceHeader.nativeElement.offsetHeight;
  }


  @HostListener('window:resize', ['$event']) onResize(event) {
    if(this.headerData.with_container){
      this.showMobileSubnav = this.commonService.isWindowSize('md', event.target.innerWidth, '<');
    }

    this.isMobile = this.commonService.isWindowSize('md', $(window).width(), '<');
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:mouseover', ['$event'])
  clickout(event) {
    //Detect click / mouseover outside header component
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.hideAllPopups();
    } 
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll($event) {

    const windowScroll = window.pageYOffset;

    if(windowScroll >= this.headerPosition){
      this.stickyHeader = true;
      this.popoverService.setHeaderSticky(true);
    } else {
      this.stickyHeader = false;
      this.popoverService.setHeaderSticky(false);
    }
    
  }

  private getSidebarTogglerVisibility(): boolean{
    return (this.router.url.indexOf('category') != -1 
            || this.router.url.indexOf('search') != -1 
            || this.router.url.indexOf('wishlist') != -1)  
            && (this.isMobile || this.stickyHeader) 
            && this.isMobile;
  }

  private getLinkClass(templateType: string, isActive?: boolean): string{
    let classes = '';
    if(templateType == 'b2b'){
      classes += ' nav-link ';
    }
    if(isActive){
      classes += ' active ';
    }
    return classes;
  }

  public activateHoverOnMenu(): void{

    $( document ).ready(function() {

      bindMenuEvents();
      $(window).resize(function(){
        bindMenuEvents();
      });

      $(window).on("orientationchange", function(){
        bindMenuEvents();
      });

    });

    var bindMenuEvents = function(){

        if($(window).width() > 768){

          $(".dropdown-menu").addClass('adjust-top');
          
          //Toggle dropdown on hover
          $(".dropdown-toggle").hover(
            function() {
              showMenu(this);
            },
            function() {
              hideMenu(this);
            }
          );
  
          $(".dropdown-menu").hover(
            function() { },
            function() {
              $(this).addClass('is-off-screen');
              $(this).prev().removeClass('active');
            }
          );
        } else {
          $(".dropdown-menu").removeClass('adjust-top');
          $('.dropdown-toggle').click(function(event){
              event.preventDefault();
              event.stopPropagation();
              $(this).removeClass('active');
              $(this).next().slideDown(200);
    
            });
          }
      

    }

    var showMenu = function(trigger){
      if(!$(trigger).hasClass('active')){
        $(trigger).addClass('active');
        $(trigger).next().removeClass('is-off-screen');
      }
    }

    var hideMenu = function(trigger){
      if(!$(trigger).next().is(":hover")){
        $(trigger).removeClass('active');
        $(trigger).next().addClass('is-off-screen');
      }
    }

  }

  private hideAllPopups = function(element ?): void{

    for (let [key, value] of Object.entries(this.showElements)) {
      this.showElements[key] = false;
    }
  }

  public toggle(event, element): void{

    if((event.type == 'mouseenter' || event.type == 'mouseleave') && this.commonService.isWindowSize('md', $(window).width(), '<'))
      return;

    event.preventDefault();
    
    event.stopPropagation();

    this.hideAllPopups();

    if(this.showElements[element]){
      this.hideAllPopups(element);
    } else {
      this.showElements[element] = true;
    } 

  }

  public doSearch(): void{
    var par = {
      search_text: this.search.text,
      node_id: this.search.node_id
    };
    console.log('do search');
    this.commonService.getPageDataService().setSearchRequest(par);
  }

  public scrollToTop(): void{
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  public changeColorTheme(id): void{
    this.utilityService.setThemeSettingsChange(id);
  }

  public changeLanguage(culture: any): void {
    this.siteUserService.changeLanguage(culture)
    .subscribe(
      data => {
        this.router.navigateByUrl(this.router.url.replace(this.lang, data.url_code));
        window.location.reload();
      }
    );
  }

  protected getLangRedirectURL(culture): string {
    return encodeURIComponent(this.router.url.replace(this.lang, culture.url_code));
  } 

  private togglePopover(event, popoverType): void{
   
    event.stopPropagation();

    this.popoverService.togglePopover(popoverType);
  }

}


