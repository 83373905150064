import { Injectable } from '@angular/core';
import { Observable, Subject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class PopoverService {
  
    private popoverListSubject: Subject<any>;
    private isHeaderSticky: boolean = false;
    public popoverList: any;

    constructor(){
        this.popoverListSubject = new Subject<any>(); 
        this.popoverList = [];

        this.getPopoverList().subscribe(list => {
            this.popoverList = list;
        });
    }

    

    public get headerSticky(): boolean{
        return this.isHeaderSticky;
    }

    public setHeaderSticky(sticky: boolean): void{
        this.isHeaderSticky = sticky; 
    }
    
    public getPopoverList(): Observable<any> {
        return this.popoverListSubject.asObservable();
    }

    public setPopoverList(data): void {
        this.popoverListSubject.next(data);
    }

    public togglePopover(type: string){

        let status = false;
        for (let [key, value] of Object.entries(this.popoverList)) {
            if(key != type)
                value['opened'] = false;
            else {
                status = value['opened'];
            }
            
        }
        this.popoverList[type].opened = !status;
        this.setPopoverList(this.popoverList);
    }

    get list(): any{
        return this.popoverList;
    }



}
