import { Component, OnInit, Input } from '@angular/core';

import { CommonService } from '@modules/shared-module/shared-module.module';
import { ComparisonService } from '../../../services/actions/comparison.service';

@Component({
  selector: 'product-comparison',
  templateUrl: './comparison.component.html',
  // styleUrls: ['./comparison.component.scss'],
})
export class ComparisonActionComponent implements OnInit {

  @Input() product: any;
  @Input() label: string;
  
  constructor(
    protected commonService: CommonService,
    protected service: ComparisonService
  ) { }

  ngOnInit() { 
  }

  ngOnDestroy(): void {  }

  addToCompare(): void {
    // gestire callback subscribe non so
    this.service.getNgrxService().add(this.product).subscribe(res => {
      this.commonService.sendMessage(this.commonService.getFixedWord(res.message.text), res.message.type, true);    
    });
  }

  removeFromCompare(): void {
    // gestire callback subscribe non so
    this.service.getNgrxService().remove(this.product).subscribe(res => {
      console.log('Prodotto rimosso dalla comparazione: ' + this.product.id);
      //this.commonService.sendMessage(this.commonService.getFixedWord(res.message.text), res.message.type, true);     
    });
  }

}
