
/**
 * Rappresenta gli ambienti disponibili. Attualmente viene usata per passare ad un componente condiviso
 * l'informazione relativa all'ambiente in cui è incluso il componente.
 */
export enum Environment {
  Search,
  PublicCatalog,
  SmwCatalog,
  ProductDetail,
  WishlistDetail
}
