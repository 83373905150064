import { Directive, ElementRef, Inject, HostListener, Input, Renderer2, HostBinding} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';

@Directive({
  exportAs: 'appFixOnScroll',
  selector: '[appFixOnScroll]'
})
export class FixOnScrollDirective {

    private config: any;

    constructor(@Inject(DOCUMENT) document, elementRef: ElementRef, renderer: Renderer2) {
      this.config = {
        container: $('.content-area'),
        element: elementRef,
        renderer: renderer,
        sidebar: $('.filter-toolbar'),
        distanceFromRight: '',
        mobileBreakpoint: 691,
        offsetRight: 41
      }
    }

    ngOnInit() { }

    ngAfterViewInit(){
      this.onContentScroll(this.config);
    }

    private onContentScroll(config){
      config.container.scroll(function(){
        config.distanceFromRight = (config.sidebar.length && window.outerWidth > config.mobileBreakpoint) ? config.sidebar.width()+ config.offsetRight + 'px' : '1rem';
        config.renderer[config.container.scrollTop() > 0 ? 'addClass' : 'removeClass'](config.element.nativeElement, 'sticky');
        config.renderer.setStyle(config.element.nativeElement, 'right', config.distanceFromRight);
      });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      if(this.config.element.nativeElement.className.indexOf('sticky') != -1){
        this.config.distanceFromRight = (this.config.sidebar.length && window.outerWidth > this.config.mobileBreakpoint) ? this.config.sidebar.width() + this.config.offsetRight + 'px' : '1rem';
        this.config.renderer.setStyle(this.config.element.nativeElement, 'right', this.config.distanceFromRight);
      }
    }
   
}