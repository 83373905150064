
import { Injectable }  from '@angular/core';

import { SiteUserService } from '../site-user/site-user.service';
 
@Injectable()
export class AppInitService {

    private userDataObj: any;
 
    constructor(private siteUserService: SiteUserService) { }
    
    Init() {
 
        return new Promise<void>((resolve, reject) => {
            this.siteUserService.getSiteUser().subscribe(data => {
                this.userDataObj = data;
                resolve();
            });
        });

    }

    get userData(): any {
        return this.userDataObj;
    }
}
 