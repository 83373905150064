import { Component, Output, ViewChild } from '@angular/core';
import { Injectable }    from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';
 
@Injectable({
    providedIn: 'root'
})
export class DialogService{

    private events: Subject<any> = new Subject();

    public modalEvents(data?: any): Observable<any>{
        this.events.next(data);
        return this.events.asObservable();
    }

}