import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilterToolbarService {

  constructor(private httpClient: HttpClient) { }

  public create(data){
    return this.httpClient.post('/api/v1/toolbar-filter', data);
  }

  public read(params){
    let param = new HttpParams().set('route', '/master/document-type');
    return this.httpClient.get('/api/v1/toolbar-filter', { params: param });
  }

  public update(data){
    return this.httpClient.put('/api/v1/toolbar-filter/' + data.filter_id, data);
  }

  public delete(id){
    return this.httpClient.delete('/api/v1/toolbar-filter/' + id);
  }

}
