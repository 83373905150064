import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http'; 
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { CommonService, AppInitService } from '@modules/shared-module/shared-module.module';
import { AppEntityServices } from '../../../ngrx/services/ngrx.service';

/*
{
  //providedIn: 'root'
} */
@Injectable()
export class ComparisonService {
  private baseApiUrl: string = '/api/v2/ecommerce/comparison';

  private headers: Headers;
  private options: RequestOptions;

  private currentUser: any;
  private anonymous: boolean;

  // variabile da utilizzare per controllare la comparabilità tra prodotti diversi... (forse solo in gestione client con cookie)
  private dataschemaID: number;

  constructor (
    private http: HttpClient,
    private cookieService: CookieService,
    private commonService: CommonService,
    public entityServices: AppEntityServices,
    private appInitService: AppInitService
  ) {
    this.headers = new Headers({ 'Content-type': 'application/json' });
    this.options = new RequestOptions({ headers: this.headers });

    let user = this.appInitService.userData;
    this.currentUser = user;
    this.anonymous = user.is_anonymous;

  }
 
  public getNgrxService(): any{
    return this.entityServices.comparisonService
  }

  /* caricamento prodotti presenti nella lista di comparazione dell'utente
   * 2: diverse gestioni: cookie/db, da rivedere in futuro implementando un'interfaccia o qualcosa di simile
   */
  getList(): Observable<Array<any>> { // Observable<Array<any>>  probabilmente
    if(this.anonymous) {
      // payload da cookie e usare la stessa chiamata?
    }
    return this.authenticatedList();
  }

  // probabilmente qui posso anche direttamente richiamare lo stesso end point passando o meno il contenutop del cookie
  // qualora dovessi fare il login però? come gestire la cosa?
  // dovrei sempre dare precedenza al cookie in caricamento dati se presente
  // e nel caso di utente loggato salvare nel db alla prima richiesta (sia essa di lista o di aggiunta prodotto)
  // nota: nella precedente versione questo problema non si poneva in quanto la lista del carrello (unica con questa versione) veniva caricata subito ad ogni caricamento di pagina
  // quindi subito dopo un eventuale login era direttamente la get_list ad aggiornare il tutto se veniva passata la lista del cookie
  // in questo caso invece rischio di trovarmi a fare l'aggiunta di un prodotto alla comparazione senza avere effettivamente la lista aggiornata sul db
  // oppure si fa in modo di fare una chiamara dedicata al login di un utente che spari su gli eventuali dati dei cookie
  // il problema si ripeterà comunque con il carrello quindi tanto vale pensare a qualcosa
  /*
  anonymousList(): Observable<Array<any>> {
    // gestione cookie
    return [];
  } 
  */
  authenticatedList(): Observable<Array<any>> {
    return this.http.post(this.baseApiUrl + '/list', null)
    .pipe(map((response: Response) => {
      let result: Array<any>;
      if (response) {
        switch(response.status) {
          case 200: {
            let data = response.json();
            result = data;
            break;
          }
          default:
            break;  
        }
      }
      return result;
    }));
  } 


  /* aggiunta prodotto alla comparazione
   * 
   */
  addProduct(product: any): void{
    if(this.anonymous) {
      this.anonymousAdd();
    } else {
      this.authenticatedAdd(product)
      .subscribe(resolveData => {
        if(resolveData.success)
          this.commonService.sendMessage(this.commonService.getFixedWord('COMPARISON_ADDED'), 'success', true); 
        else {
          switch(resolveData.error) {
            case 'product.notComparable': {
              this.commonService.sendMessage(this.commonService.getFixedWord('COMPARISON_DSERROR'), 'danger', true); 
              break;
            }
            case 'product.noSchema': {
              this.commonService.sendMessage(this.commonService.getFixedWord('COMPARISON_NO_DS'), 'warning', true); 
              break;
            }
            case 'product.dupe': {
              this.commonService.sendMessage(this.commonService.getFixedWord('COMPARISON_DUPE'), 'warning', true); 
              break;
            }
            default:
              break;  
          }
        }
      });
    }
  }

  anonymousAdd(): void {
    // gestione cookie
  } 
  authenticatedAdd(product: any): Observable<any>  {
    let payload = {
      productID: product.id
    };

    return this.http.post(this.baseApiUrl + '/add-product', payload)
    .pipe(map((response: Response) => {
      // let result = {};
      // if (response) {
      //   switch(response.status) {
      //     case 200: {
      //       let data = response.json();
      //       result = data;
      //       console.log(data);
      //       break;
      //     }
      //     default:
      //       break;  
      //   }
      // }
      return response;
    }));
  } 

  removeProduct(product: any): void {
    if(this.anonymous) {
      // gestione con cookie
      this.anonymousRemove();
    } else {
      this.authenticatedRemove(product)
      .subscribe(resolveData => {
        this.commonService.sendMessage('Prodotto rimosso dalla comparazione!', 'success', true); 
      });
    }
  }
  anonymousRemove(): void {
    // gestione cookie
  } 

  authenticatedRemove(product: any): Observable<any>  {
    let payload = {
      productID: product.product_id || product.id
    };

    return this.http.post(this.baseApiUrl + '/remove-product', payload)
    .pipe(map((response: Response) => {
      let result = {};
      if (response) {
        switch(response.status) {
          case 200: {
            let data = response.json();
            result = data;
            console.log(data);
            break;
          }
          default:
            break;  
        }
      }
      return result;
    }));
  } 

}
