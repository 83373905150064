import { Component, OnInit, Input, ViewEncapsulation, SimpleChanges, HostListener, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { CommonService } from '@modules/shared-module/shared-module.module';
import { PopoverService } from '../../services/popover.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'popover',
  templateUrl: './popover.component.html',
  styleUrls: [
    './popover.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PopoverComponent implements OnInit {

    @Input() data: any;
    @Input() settings: any;

    @ViewChild('popover', { static: true} ) popover: ElementRef;
    @ViewChild('triangle', { static: true} ) triangle: ElementRef;

    private items: Array<any>;
    private status: any;
    private popoveListSubscription: Subscription;
    private popoverList: any;
    private top: number;
    private offsetTop: any;
    private scrolling: boolean;

    constructor(private commonService: CommonService, private popoverService: PopoverService, private renderer: Renderer2) { 
      this.status = { opened: false, type: '' };
      this.popoverList = {};
      this.offsetTop = {
        'minified': 46,
        'uppermenu': 37,
        'middlemenu': 99
      }
      this.scrolling = false;


    }

    ngOnInit(){ 
      if(!this.popoveListSubscription){
        this.popoveListSubscription = this.popoverService.getPopoverList().subscribe(list => {
          this.popoverList = list;

          if(!this.popoverService.headerSticky){
            this.popover.nativeElement.classList.remove('stick-to-header');
            this.repositionTriangle();
          }
          else
            this.popover.nativeElement.classList.add('stick-to-header');

          if(list[this.settings.type].opened){
            this.popover.nativeElement.classList.add('opened');
          } else {
            this.popover.nativeElement.classList.remove('opened');
          }
        });
      }

    }

    ngAfterViewInit(): void { }

    ngOnDestroy(){
      this.popoveListSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes['data'] && changes['data'].currentValue) {
        this.items = changes['data'].currentValue;
      }
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll($event) { 
      if(this.popoverService.headerSticky)
        this.popover.nativeElement.classList.add('stick-to-header');
      else
        this.popover.nativeElement.classList.remove('stick-to-header');
    }

    private repositionTriangle(): void{

      let windowWidth = window.innerWidth;

      let anchor = document.getElementById(this.settings.type + '-toggler');

      let anchorPosition = this.getElementPositionInDOM(anchor);

      let right = windowWidth - anchorPosition.right + ((anchorPosition.width - 30) / 2);

      this.triangle.nativeElement.style.right = right+'px';
    }

    private hidePopover(): void{
      this.popoverService.togglePopover(this.settings.type);
    }

    private getOffsetTop(): number{

      let top: number = 0;

      if(this.popoverService.headerSticky){
        top = this.offsetTop.minified;
      } else {
        if(this.settings.type == 'cart'){
          top = this.offsetTop.middlemenu;
        } else {
          if(this.commonService.getUtilityService().isWindowSize('sm', window.innerWidth, '<')){
            top = 80;
          } else {
            top = this.offsetTop.uppermenu;
          }
        }
      }
      return top;
    }


  private getElementPositionInDOM(el): any{
    var rect = el.getBoundingClientRect(),
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, right: rect.right, width: rect.width }
  }

}
