import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

  @Output() onModalAction: EventEmitter<string> = new EventEmitter<string>();

  show = false;
  private modalData: any = { };

  public open(data: any){
    this.modalData = data;
    this.show = true;
  }

  //Da gestire sul parent component
  public onModalSubmit(action: string){
    this.onModalAction.emit(action);
  }

}

