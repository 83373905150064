import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { CommonService } from '@modules/shared-module/shared-module.module';

import { BuyComponentInterface } from '../../interfaces/buy.interface';

import { BuyService } from '../../services/buy.service';
import { GridBuyService } from '../../services/specific/grid-buy.service';

import { AppEntityServices, DataStoreInterface } from '../../../ngrx/services/ngrx.service';

//import { CartService} from '../../services/cart.service';

@Component({
  selector: 'grid-buy',
  templateUrl: './grid-buy.component.html',
  styleUrls: ['./grid-buy.component.scss', '../buy.component.scss'],
  encapsulation: ViewEncapsulation.None    // NB: necessario per far recepire gli styles a tag all'interno di innerHtml
})

export class GridBuyComponent implements OnInit, BuyComponentInterface {
  @Input() productID: number;
  @Input() buyManagerService: DataStoreInterface;

  /* 
   * grid buy items
  */
  columns: Array<any>;
  rows: Array<any>;
  titles: any = {};

  items: any = {};
  selectedItem: string;
  selectedText: string;
  currentDetail: any = {};

  productInfo: any = {};
  productKeyMapping: any = {};

  /* 
   * view utility variables
  */
  title: string = '';
  totalDetail: any;

  loading: boolean = false;
  errorOnLoad: boolean = false;

  allRowsDisabled: boolean = true;
  currencyFormatString: string;

  /*
   * promotions variables
  */
  applyingPromo: boolean = false;
  stackedPromoModal: boolean = false;
  currentPromo: any;
  promoAlert: boolean = false;
  currentPromoQuantity: number = 0;

  //buyManagerService: DataStoreInterface;

  counter: number;
  totalCount: number;

  invalidInput: boolean;

  constructor( 
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    protected location: Location,
    protected buyService: BuyService,
    protected service: GridBuyService,
    protected commonService: CommonService,

    //protected buyManagerService: CartService,    // TODO: un giorno qui dovrà essere importata l'interfaccia corretta in base all'ambiente corrente: catalogo/smw
    protected entityServices: AppEntityServices
  ){
    //TODO: per ora faccio cosi, in modo da vederlo funzionare, poi questi componenti dovranno avere il buyManagerService in input
    /*let env = 'Catalog';
    if(env == 'Catalog')
        this.buyManagerService = entityServices.cartItemService;//.getEntityCollectionService("CartItem");

    if(env == 'smw')
        this.buyManagerService = entityServices.smwItemService;//(SmwItemService) entityServices.getEntityCollectionService("SmwItem");
    */
  }

  ngOnInit() { 
    this.loading = true;
    this.totalDetail = {};

    let payload = {
      product_id: this.productID
    };

    this.service.loadData(payload).subscribe(data => {
      // this.data = data;
      if(data.title)
        this.title = data.title;

      /* riporto codice a standard t/c 
       * nota: per il momento riporto il necessario al funzionamento del grid buy in catalogo
      */
      var hierarchy = data.raw_structure;
      var colsIdx, rowsIdx;

      if(hierarchy.length > 0) {
        this.columns = hierarchy[0].variants;
        this.titles.columns = hierarchy[0].var_name;
        if(hierarchy.length == 1) {
          this.rows = [{
            "caption":""
          }];
          this.titles.rows = '-'
        } else {
          if(hierarchy[0].node_code == "COL"){
            this.columns = hierarchy[1].variants;
            this.titles.columns = hierarchy[1].var_name;

            this.rows = hierarchy[0].variants;    
            this.titles.rows = hierarchy[0].var_name;
          } else {
            this.rows = hierarchy[1].variants;
            this.titles.rows = hierarchy[1].var_name;
          }
        }
      }

      if(!this.columns || !this.rows) {
        this.errorOnLoad = true;
        this.loading = false;
        return;
      }

      // nota da togliere: mantengo la gestione aggiornata a rif. 2020/03/06 refactoring per caricamento prezzi da cache per taglia/colore:
      // in questo punto arriverà direttamente un oggetto priceDetail con all'interno il netUnitPrice da visualizzare
      // se ho quindi un oggetto priceDetail non dovrò fare l'askPrice, che verrà fatta però al primo click sulla cella del prodotto.
      // per il momento se non mi ritorna nessun oggetto priceDetail mantengo la gestione precedente
      var products = data.products;
      this.counter = 0; this.totalCount = data.products.length;
      products.forEach(product => {
        if(!product.quantity)
          product.quantity = 0;

        product.promoID = [];

        if(product.promos && product.promos.length > 0) {
          for (let i = 0; i < product.promos.length; ++i) {
            let promo = product.promos[i];
            promo.template = promo.benefits[0].template;
            promo.valueobj = promo.benefits[0].valueobj;
            promo.type = promo.benefits[0].type;
            product.promoID.push(promo.promo_id);
          }
        }  

        if(product.canvass && product.canvass.length > 0) 
          product.activeCanvass = true;  

        if(product.promo)
          this.currentPromo = product.promo;      

        if(product.priceDetail && product.custom_benefit_value && product.custom_benefit_value.length > 0) {
          product.priceDetail.customBenefitValue = product.custom_benefit_value;   
          product.priceDetail.useThisCustomBenefitValue = true;   
        }

        this.buyService.askPrice(product).subscribe(priceDetail => {
            this.gridProductCB(product, data);
        });
      });
    });
  }

  setCurrentDetail(column: any, row: any): void {
    var key = this.getKey(column, row);
    if (!this.items[key] || this.items[key].disabled) return;

    if(this.selectedItem != key) {
      this.selectedItem = key;
      this.selectedText = column.caption + ', ' + row.caption;

      if(this.items[key] && this.items[key].priceDetail) {
        var price = this.items[key].priceDetail;
        this.items[key].editPrice = this.items[key].priceDetail.customUnitPrice ? true:false;

        this.currentDetail = {
          "netPrice": this.commonService.formatMoney(price.netUnitPrice, price.currencyFormatString, price.currencyCode, true),
          "quantity": this.commonService.formatNumber(this.items[key].quantity,0,',','.'),
          "total": this.commonService.formatMoney(price.netTotalPrice, price.currencyFormatString, price.currencyCode, true),
          "umDescription": this.items[key].default_um_code,
          "publicPrice": this.commonService.formatMoney(this.items[key].public_price, price.currencyFormatString, price.currencyCode, true),
          "currencyCode": price.currencyCode
        };
        if(price.grossUnitPrice) {
          this.currentDetail.grossUnitPrice = this.commonService.formatMoney(price.grossUnitPrice, price.currencyFormatString, price.currencyCode, true);
        }
        if(price.originalUnitPrice) {
          this.currentDetail.originalUnitPrice = this.commonService.formatMoney(price.originalUnitPrice, price.currencyFormatString, price.currencyCode, true);
        }
      }
    }     
  }

  calculatePrice(): void {
    if(!this.items || this.items.length == 0) 
      return;

    var total = 0;
    var totalQty = 0;
    var currencyCode = '';

    var key;
    for(key in this.items) {
      var item = this.items[key];

      var qty = 0;
      var price = 0;
      var cellTotal = 0;
    
      if (item.quantity) qty = item.quantity;
      if (item.priceDetail && item.priceDetail.netUnitPrice) price = item.priceDetail.netUnitPrice;
      if (item.priceDetail && item.priceDetail.netTotalPrice) cellTotal = item.priceDetail.netTotalPrice;
      if (item.priceDetail && item.priceDetail.currencyCode) currencyCode = item.priceDetail.currencyCode;
   
      // aggiornamento totali
      total += cellTotal;
      totalQty += qty;

      var detail: any = {
        "grossPrice": this.commonService.formatMoney(price, item.priceDetail.currencyFormatString, item.priceDetail.currencyCode, true),
        "netPrice": this.commonService.formatMoney(price, item.priceDetail.currencyFormatString, item.priceDetail.currencyCode, true),
        "quantity": this.commonService.formatNumber(qty, 0,',','.'),
        "total": this.commonService.formatMoney(cellTotal, item.priceDetail.currencyFormatString, item.priceDetail.currencyCode, true),
        "umDescription": item.default_um_code,
        "currencyCode": currencyCode,
        "publicPrice": this.commonService.formatMoney(item.public_price, item.priceDetail.currencyFormatString, item.priceDetail.currencyCode, true)
      };
      if(item.priceDetail.grossUnitPrice) {
        detail.grossUnitPrice = this.commonService.formatMoney(item.priceDetail.grossUnitPrice, item.priceDetail.currencyFormatString, item.priceDetail.currencyCode, true);
      }
      if(item.priceDetail.originalUnitPrice) {
        detail.originalUnitPrice = this.commonService.formatMoney(item.priceDetail.originalUnitPrice, item.priceDetail.currencyFormatString, item.priceDetail.currencyCode, true);
      }

      item.detail = detail;

      if(key == this.selectedItem) {
        this.currentDetail = detail;
      }
    };

    this.totalDetail.totalQuantityText = this.commonService.formatNumber(totalQty,0,',','.');
    this.totalDetail.totalQuantity = totalQty;
    this.totalDetail.totalText = this.commonService.formatMoney(this.round(total), null, '€', true, ',');
    this.totalDetail.total = this.round(total);
  }

  cellStockAvailableQuantity(column: any, row: any): number {
    var key = this.getKey(column, row);
    var availability = 0;

    if(this.items[key] && this.items[key].priceDetail && this.items[key].priceDetail.productInfo) {
      var stock = this.items[key].priceDetail.productInfo.stockQuantity || 0;
      var incoming = this.items[key].priceDetail.productInfo.stockIncomingQuantity || 0;
      // customer
      var customerStock = this.items[key].priceDetail.productInfo.stockCustomerQuantity || 0;
      var customerIncoming = this.items[key].priceDetail.productInfo.stockCustomerIncomingQuantity || 0;
      availability = stock + incoming + customerStock + customerIncoming;
    }
    return availability;
  }

  increaseQuantity(column: any, row: any): void {
    // inventare un modo per capire se sto già calcolando un prezzo o magari ritardare il calcolo al prossimo giro.. aiuto un timeout magari
    var key = this.getKey(column, row);
    var value;

    if(this.cellDisabled(column, row) || this.items[key].notInStock)
      return;

    if(this.items[key]) {
      if(this.items[key].disabled)
        return;

      value = this.items[key].quantity;

      if(value == null) {value = 0};
    } else {
      value = 0;
    };

    this.selectedItem = key;
    this.selectedText = column.caption + ', ' + row.caption;

    var stepQuantity = this.items[key].step_quantity;
    if (!stepQuantity || stepQuantity == 0) stepQuantity = 1;

    // controlli sulla giacenza
    var availability = this.cellStockAvailableQuantity(column, row);

    if(value + stepQuantity <= availability 
      || (this.items[key].priceDetail && this.items[key].priceDetail.productInfo.stockBehaviour != 1)
      // || !(fctBuyInterface.considerateStock && fctBuyInterface.considerateStock()) TODO
    ) {
      value += stepQuantity;
    } else {
      this.commonService.sendMessage(this.commonService.getFixedWord('CART_STOCK_QTY_ERROR_MESSAGE'), 'warning', true); 
    }
    this.items[key].quantity = value;

    this.finalizeCellEdit(key);
  }

  decreaseQuantity(column: any, row: any): void {
    var key = this.getKey(column, row);

    if(this.cellDisabled(column, row) || this.items[key].notInStock)
      return;

    var value;
    if (this.items[key]) {
      if (this.items[key].disabled) {return;};
      value = this.items[key].quantity;

      if (value == null) {value = 0};
    } else {
      value = 0;
    };

    this.selectedItem = key;
    this.selectedText = column.caption + ', ' + row.caption;

    var stepQuantity = this.items[key].step_quantity;
    if (!stepQuantity || stepQuantity == 0) stepQuantity = 1;

    if(value - stepQuantity <= 0)
      value = 0;
    else 
      value -= stepQuantity;

    this.items[key].quantity = value;

    this.finalizeCellEdit(key);
  }

  quantityChanged(column: any, row: any): void {
    this.invalidInput = false;

    var key = this.getKey(column, row);

    this.selectedItem = key;
    this.selectedText = column.caption + ', ' + row.caption;

    if(!this.items[key].quantity) this.items[key].quantity = 0;

    // eventuale correzione di inserimento manuale
    if(!this.commonService.getSiteUserService().checkFunctionality('buyinterface.quantity_not_locked_to_step') && this.items[key].quantity < step) {
      this.items[key].quantity = step;
    }

    var stockMsg = false;
    
    // TODO TODO: (fctBuyInterface.considerateStock && fctBuyInterface.considerateStock()) {
    // controllo per stock: se supero la quantità disponibile imposto il limite
    var availability = this.cellStockAvailableQuantity(column, row);
   
    if(this.items[key].quantity > availability && this.items[key].priceDetail && this.items[key].priceDetail.productInfo.stockBehaviour == 1) { 
      this.invalidInput = true;
      this.items[key].quantity = availability;
      stockMsg = true;
    }
    // arrotondamento a step
    var step = Number(this.items[this.getKey(column, row)].step_quantity);
    if((!this.commonService.getSiteUserService().checkFunctionality('buyinterface.quantity_not_locked_to_step') && 
        this.items[key].quantity % step) || (this.items[key].quantity % 1)) {
      this.items[key].quantity = Math.ceil((this.items[key].quantity) / step ) * step;
      this.invalidInput = true;
      if(!stockMsg) {
        this.commonService.sendMessage(this.commonService.getFixedWord('CART_ROUND_QUANTITY_MESSAGE'), 'warning', true); 
      }
    }

    // if(fctBuyInterface.considerateStock && fctBuyInterface.considerateStock()) {
    if(this.items[key].quantity > availability && this.items[key].priceDetail && this.items[key].priceDetail.productInfo.stockBehaviour == 1) {
      this.invalidInput = true;
      this.items[key].quantity -= step;
      stockMsg = true;
    }

    if(stockMsg) {
      this.commonService.sendMessage(this.commonService.getFixedWord('CART_STOCK_QTY_ERROR_MESSAGE'), 'warning', true); 
    }

    this.finalizeCellEdit(key);
  };

  /* funzioni di supporto per view */
  getKey(column: any, row: any): string {
    return column.node_id + '_' + row.node_id;
  };

  isCellSelected(column: any, row: any): boolean {
    var key = this.getKey(column, row);

    if(key == this.selectedItem) {
      return true;
    }
    return false;
  };

  colSelected(column: any): boolean {
    if(!this.selectedItem) return false;

    var colID = this.selectedItem.split('_')[0];

    if (colID == column.node_id) {
      return true;
    }
    return false;
  };

  rowSelected(row: any): boolean {
    if(!this.selectedItem) return false;

    var rowID = this.selectedItem.split('_')[1];

    if (rowID == row.node_id) {
      return true;
    }
    return false;
  };

  rowDisabled(row: any) {
    for (var i = 0; i < this.columns.length; i++) {
      // se trovo una cella abilitata lascio visibile la riga
      if(!this.cellDisabled(this.columns[i], row)) {
        return false;
      }
    };
    // non ho trovato celle abilitate: nascondo la riga
    return true;
  }

  cellDisabled(column: any, row: any): boolean {
    var key = this.getKey(column, row);
    
    if(!this.items[key] || this.items[key].disabled) {
      return true;
    }
    return false;
  }

  cellPrice(column: any, row: any): string {
    var key = column.node_id + '_' + row.node_id;

    if(this.items[key] && this.items[key].priceDetail && this.items[key].priceDetail.netUnitPrice) {
      var rtn = this.commonService.formatMoney(this.items[key].priceDetail.netUnitPrice, this.items[key].priceDetail.currencyFormatString, this.items[key].priceDetail.currencyCode, true);
      
      if(this.items[key].disabled) return "";

      return rtn || '-';
    }
  }

  round(value: number): number {
    return Math.round(value * 100)/100;
  }

  /* funzione di supporto per il primo caricamento dati */
  gridProductCB(product: any, data: any): void {
    let priceDetail = product.priceDetail;

    if(!priceDetail) {
      priceDetail = {
        productInfo: {
          stockQuantity: 0,
          stockIncomingQuantity: 0,
          stockCustomerQuantity: 0,
          stockCustomerIncomingQuantity: 0
        }
      };
    }
    // TODO: gestione settings && (!fctBuyInterface.avoidCheckPrices || !fctBuyInterface.avoidCheckPrices())
    if(!priceDetail.netUnitPrice) {
      product.disabled = true;
    } else {
      this.currencyFormatString = priceDetail.currencyFormatString;

      var step = priceDetail.stepQuantity || 1;

      // TODO: riporto l'attuale standard, anche se sento che si potrebbe fare meglio rispetto a tutto ciò...
      // controllo giacenze globali
      var stock = priceDetail.productInfo.stockQuantity || 0;
      var incoming = priceDetail.productInfo.stockIncomingQuantity || 0;
      // giacenze customer
      var custStock = priceDetail.productInfo.stockCustomerQuantity || 0;
      var custIncoming = priceDetail.productInfo.stockCustomerIncomingQuantity || 0;
      var availability = stock + incoming + custStock + custIncoming;

      // se il prodotto non è disponibile disattivo la cella sulla griglia
      // modifica richieste per visualizzare qualcosa di diverso dalla X per "Prodotto esaurito"
      // nota: aggiunto controllo su funzionalità "not_lockjed" in modo da dare comunque a questi utenti la possibilità di acquistare, senza disabilitare l'interfaccia
      // mana questa eventuale gestione custom della vecchia versione: && (!fctBuyInterface.considerateStock || fctBuyInterface.considerateStock())
      if((!data.forecastDocument || data.forecastDocument == undefined) && priceDetail.productInfo.stockBehaviour == 1) {
        if(availability <= 0 
            || 
          (availability > 0 && availability < step 
            && !this.commonService.getSiteUserService().checkFunctionality('buyinterface.quantity_not_locked_to_step')
          )
        ) {
          product.notInStock = true;
        }
      }

      // dopo aver chiesto i prezzi aggiorno le info se c'è almeno un prodotto della griglia già presente nel carrello
      if(priceDetail.quantity > 0) {
        this.totalDetail.totalQuantity += priceDetail.quantity;
        this.totalDetail.totalQuantityText = this.commonService.formatNumber(this.totalDetail.totalQuantity,0,',','.');
        this.totalDetail.total += priceDetail.netTotalPrice;
        this.totalDetail.totalText = this.commonService.formatMoney(this.round(this.totalDetail.total), priceDetail.currencyFormatString, priceDetail.currencyCode, true, ',');
      }
      this.totalDetail.currencyCode = priceDetail.currencyCode;
      this.totalDetail.umDescription = product.default_um_code;
    }

    if(!product.nodes) {
      this.errorOnLoad = true;
      this.loading = false;
      return;
    }

    var node1 = product.nodes[0];
    var node2 = product.nodes[1];  
    // compongo la key: column_id / row_id
    var key = ""; var rowID;
    if (node1.parent_id == this.columns[0].parent_id) {
      rowID = node2.node_id;
      key = node1.node_id + "_" + node2.node_id;
    } else {
      rowID = node1.node_id;
      key = node2.node_id + "_" + node1.node_id;
    }

    // aggiungo agli item il prodotto
    if(!product.disabled) {
      // c'è almeno un prodotto, posso visualizzare la tabella
      this.allRowsDisabled = false;

      this.items[key] = product; 
      this.productKeyMapping[product.id] = key;
    } 
    // assegnazione immagini
    for(var j = 0; j < this.rows.length; j++) {
      // se il prodotto fa parte della riga assegno l'immagine 
      if(this.rows[j].node_id == rowID && !this.rows[j].image && product.image && product.image.length > 0) {
        this.rows[j].image = product.image;
        break;
      }
    };

    this.counter++;
    if(this.counter == this.totalCount) {
      this.calculatePrice();
      this.loading = false;
    }
  };

  addOrUpdateItems(): void {
    // evitare problema di input non valid se il blur del controllo viene saltato da un click diretto sul pulsante di aggiunta ?
    if(this.invalidInput) {
      this.invalidInput = false;
      return;
    }

    /* check promozione selezionata */
    if(this.currentPromo && this.currentPromo.min_quantity) { 
      this.currentPromoQuantity = 0;
      for(var key in this.items) {
        let item = this.items[key];
        if(item.promo && item.promo.promo_id == this.currentPromo.promo_id) {
          this.currentPromoQuantity += item.quantity;
        }
      }
      if(this.currentPromoQuantity < this.currentPromo.min_quantity) {
        this.promoAlert = true;
        return
      }
    }

    let payload = [];
    for(var key in this.items) {
      let item = this.items[key];
      if(item.quantity > 0 || item.flagged) {          
        item.product_id = item.id;
        payload.push({
          product_id: item.product_id,
          quantity: item.quantity,
          um_id: item.priceDetail.umID,
          ship_date: item.ship_date,    // formattare la data probabilmente
          extra_data: item.extra_data || null,
          customprice: item.priceDetail.customUnitPrice, 
          discount: item.priceDetail.discount, 
          discount_string: item.priceDetail.discountString, 
          custom_tax_rate: item.priceDetail.customTaxRate,
          promo_id: item.promo ? item.promo.promo_id : null,
          custom_benefit_value: item.custom_benefit_value,
          // dati per visualizzazione diretta in cart popover
          code: item.code,
          name: item.name,
          image: item.image
        });
      } 
    }

    this.buyManagerService.addOrUpdateItems(payload)
    .subscribe(result => {
      if(result && !result.error) {
        this.commonService.sendMessage(this.commonService.getFixedWord('CART_ADDED_ITEMS_MESSAGE'), 'success', true); 
        this.buyService.openBuy = false;
      }
    });
  }

  getPromoType(promo:any): string {
    switch (promo.type) {
      case "%":
        return "extra";
        break;

      case "€":
        return "prezzo finito";
        break;
      
      default:
        return "omaggi a scelta";
        break;
    }
  }

  openPromoModal(promo: any): void {
    this.currentPromo = promo;
    this.stackedPromoModal = true;

    // view helpers     
    for (var i = 0; i < this.currentPromo.benefits.length; i++) {
      if(this.currentPromo.benefits[i].type_code == 'MER') {                
        this.currentPromo.hasGifts = true;
        break;
      }
    }
  }
  cancelPromoModal(): void {
    this.currentPromo = null;
    this.stackedPromoModal = false;    
  }

  confirmPromo() {
    this.currentPromoQuantity = 0;
    for(let index in this.items) {
      let item = this.items[index];
      if(item.quantity && this.isCurrentPromoValidForRow(item)) {
        this.currentPromoQuantity += item.quantity;
      }
    } 

    for(let index in this.items) {
      let item = this.items[index];
      if(item.quantity && this.isCurrentPromoValidForRow(item)) {
        this.confirmCellPromo(false, index);
      }
    }
  }

  isCurrentPromoValidForRow(item: any): boolean {
    if(!item.promos)
      return false;

    for (var i = 0; i < item.promos.length; i++) {
      if(item.promos[i].promo_id == this.currentPromo.promo_id)
        return true;
    }
    return false;
  }

  confirmCellPromo(skipPromoSet: boolean = false, key: string = null): void {
    if(!key)
      key = this.selectedItem;

    if(!skipPromoSet) {
      this.removeCellPromo(false, key);
     /* TODO: probabile necessità di gestire customization in apertura, 
              almeno nel caso Lotti per chiedere il prezzo post attibazione di eventuali promo */

     /* TODO*2?: la domanda sorge spontanea... non sarebbe il caso di impostare 
                queste cose su una stored procedure apposita da richiamare alla fine?.. rallenta un po' ma forse da più controlli.. */
      if(this.currentPromo) {
        this.currentPromo.applyingPromo = true;
        this.items[key].custom_benefit_value = [];

        for (var i = 0; i < this.currentPromo.benefits.length; i++) {
          var benefit = this.currentPromo.benefits[i];
          var extra = benefit.benefit_type_extra;

 
          // se la promo è già applicata rifaccio il conto con la nuova quantità, altrimenti uilizzo la currentPromoQuantity presente, che sarà calcolata nella cinfirm
          if(this.items[key].promo) {
            this.currentPromoQuantity = 0;
            for(let subKey in this.items) {
              let item = this.items[subKey];
              if(item.promo && item.promo.promo_id == this.currentPromo.promo_id) {
                this.currentPromoQuantity += item.quantity;
              }
            }
          }

          if((!benefit.quantity_from || benefit.quantity_from <= this.currentPromoQuantity) 
                 && (!benefit.quantity_to || benefit.quantity_to >= this.currentPromoQuantity)) {

            switch (benefit.type_code) {
              case "PRC":
                // supporto per salvataggio dati benefit applicati
                extra.benefit_id = benefit.benefit_id;
                extra.type = benefit.type_code;
                this.items[key].custom_benefit_value.push(extra);
                break;

              case "FIX":
                var code = this.items[key].code;
                var rules = extra.filter(function (r) { 
                  return r.productcode == code; 
                });

                if(rules[0] && rules[0].fixedprice) {
                  this.items[key].custom_benefit_value.push({   
                    benefit_id: benefit.benefit_id,
                    type: benefit.type_code,
                    products: rules
                  });
                }

                break;

              case "MER":
                // supporto per salvataggio dati benefit applicati
                extra.benefit_id = benefit.benefit_id;
                extra.type = benefit.type_code;
                this.items[key].custom_benefit_value.push(extra) // aggiunto l'extra per segnalare e salvare la promo, la selezione omaggi avverrà in fase di checkout
                break;
            }
          }
        }
      } 

      this.items[key].priceDetail.customBenefitValue = this.items[key].custom_benefit_value;
      this.items[key].priceDetail.useThisCustomBenefitValue = true;
    }

    this.buyService.askPrice(this.items[key]).subscribe(priceDetail => {
      this.stackedPromoModal = false;
      this.currentPromo.applyingPromo = false;

      if(!skipPromoSet)
        this.items[key].promo = JSON.parse(JSON.stringify(this.currentPromo));

      // ricalcolo totali
      this.calculatePrice();
    });
  }

  removeCellPromo(refresh: boolean = false, key: string = null, resetCurrent: boolean = false): void {
    if(!key)
      key = this.selectedItem;

    this.items[key].custom_benefit_value = [];       
    this.items[key].priceDetail.customBenefitValue = [];   
    this.items[key].priceDetail.useThisCustomBenefitValue = true;   

    if(refresh) {                
      this.buyService.askPrice(this.items[key]).subscribe(priceDetail => {
        this.stackedPromoModal = false;
        this.items[key].promo = null;

        if(resetCurrent)
          this.currentPromo = null;
      });
    }
  } 

  finalizeCellEdit(key: string): void { 
    // se c'è una promo selezionata ed ha benefici a scaglioni, allora va ricalcolato il totale corrente (va in background)
    if(this.currentPromo && this.currentPromo.benefit_brackets) {      
      this.confirmPromo();
      return;
    }

    // condizioni per auto-attivazione promo: dev'esserci una promo corrente e dev'essere attivabile per il prodotto corrente
    if(this.currentPromo && this.items[key].promoID.indexOf(this.currentPromo.promo_id) >= 0) {
      if(this.items[this.selectedItem].quantity) {
        let skipPromoSet = (this.items[this.selectedItem].promo && this.items[this.selectedItem].promo.promo_id == this.currentPromo.promo_id);

        this.items[this.selectedItem].promo = JSON.parse(JSON.stringify(this.currentPromo));
        this.confirmCellPromo(skipPromoSet);
      } else {  
        if(this.items[this.selectedItem].promo) {
          this.items[this.selectedItem].promo = null;
          this.removeCellPromo(true);
        }
      }
    } else { 
      // normale richeista prezzo e aggiornamento totali
      this.buyService.askPrice(this.items[key]).subscribe(priceDetail => {
        this.calculatePrice();
      });
    }
  }
}