
export class PageData{
    title: string;
    showHeaderToolbar: boolean;
    showFilterToolbar: boolean;
    headerToolbarData: any;
    filterToolbarData: any;

    constructor(params: any){
        this.title = params.title;
        this.showHeaderToolbar = params.showHeaderToolbar;
        this.showFilterToolbar = params.showFilterToolbar;
        this.headerToolbarData = params.headerToolbarData;
        this.filterToolbarData = params.filterToolbarData;
    }
}