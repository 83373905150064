import { Directive, OnInit, Input, TemplateRef } from "@angular/core";

@Directive({
  selector: '[tplId]'
})
/**
 * Questa direttiva viene usata per permettere la dichiarazione di template all'interno di un component per usarli dinamicamente a run-time.
 * Es: vedi customer-filter component
 * 
 * La direttiva è applicata a tutti gli elementi che hanno l'attributo tplId
 */
export class ShowkTemplate implements OnInit {
  @Input() tplId: string;
  constructor(
    public template: TemplateRef<any>
    ) {
      console.log(template);
    }
  ngOnInit(): void {
    console.log(this.tplId);
  }
}