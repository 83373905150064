import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgSwitch } from '@angular/common';

import { CommonService } from '@modules/shared-module/shared-module.module';


@Component({
  selector: 'promo-modal',
  templateUrl: './promo-modal.component.html',
  styleUrls: ['./promo-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromoModalComponent implements OnInit, OnChanges {
  @Input() flag: boolean; 
  @Input() promo: any; 


  /* funzioni da eseguire */
  @Output("apply") apply: EventEmitter<any> = new EventEmitter();
  @Output("cancel") cancel: EventEmitter<any> = new EventEmitter();
  @Output("remove") remove: EventEmitter<any> = new EventEmitter();

    constructor( 
        protected commonService: CommonService,
    ){ }


  ngOnInit() { 
  }

  ngOnChanges(): void { 
  }

  cancelModal(): void {
    // dai test si evince che queste variabili non sono riferimenti alle variabili del papà
    // quindi mi passo una funzione anche per resettare quelli del componente padre
    // senza resettare il componente padre le modifiche non vengono recepite e il modale non si apre più
    this.flag = null;
    this.promo = null; 

    this.cancel.emit();
  }

  applyPromo(): void {    
    this.apply.emit();
  }

  removePromo(): void {    
    this.remove.emit();
  }
}
