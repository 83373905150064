import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { MessageService } from '../../services/message-service/message.service';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: [
    './styles/app-notification.component.scss'
  ],
})
export class AppNotificationComponent implements OnInit {

    private notificationSubscription: Subscription;
    private notifications$: any;

    constructor(private service: MessageService, private commonService: CommonService) {  }

    ngOnInit() {
        this.notificationSubscription = this.service.getMessages().subscribe(
            notifications => {
              this.notifications$ = notifications;
            }
        );
     }

    ngOnDestroy(): void {
        this.notificationSubscription.unsubscribe();
    }

}
