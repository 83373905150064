import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { CommonService, UtilityService } from '@modules/shared-module/shared-module.module';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: [
    './breadcrumb.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {

    private environmentClass: string;
    private lang: string;
    private urlSearchStringSubscription: Subscription;
    private latestUrlVisited: string;
    
    @Input() data: Array<any>;
    @Input() environment: any;

    constructor(private commonService: CommonService, private router: Router, private location: Location) { 
        this.latestUrlVisited = this.commonService.getPageDataService().getCurrentQueryString;
    }

    ngOnInit() {

        this.lang = this.commonService.getUtilityService().getLangParam();

        switch(this.environment){
            case 0: case 1: {
                this.environmentClass = 'catalog';
                break;
            }
            case 2: {
                this.environmentClass = 'smw';
                break;
            }
            case 3: {
                this.environmentClass = 'detail';
                break;
            }
        }
            
    }

    private goBack(): void{
        window.scrollTo(0,0);
        this.location.back();
    }

}
