import { Injectable, Injector } from '@angular/core';
import { DictionaryService } from '../dictionary/dictionary.service';
import { UtilityService } from '../utility-service/utility.service';
import { MessageService } from '../message-service/message.service';
import { SiteUserService } from '../site-user/site-user.service';
import { FirmService } from '../firm-data/firm-data.service';
import { PageDataService } from '../page-data/page-data.service';
import { HeaderDataService } from '../header-data/header-data.service';
import { DialogService } from '../dialog/dialog.service';
import { ViewElementService } from '../view-element/view-element.service';
import { ColorService } from '../color/color.service';
import { CheckboxElementService } from '../checkbox-element/checkbox-element.service';
import { Colors } from '../color/color.model';
import { CheckboxElement } from '../checkbox-element/checkbox-element.interface';
import { Observable } from 'rxjs';
import { ThemeService } from 'ng2-charts';

/**
 * Rif Gabriele: Implements facade design pattern
 */
@Injectable({
  providedIn: 'root',
})
export class CommonService{

  private _dictionaryService: DictionaryService;
  private _utilityService: UtilityService;
  private _messageService: MessageService;
  private _siteUserService: SiteUserService;
  private _pageDataService: PageDataService;
  private _headerDataService: HeaderDataService;
  private _dialogService: DialogService;
  private _viewElementService: ViewElementService;
  private _colorService: ColorService;
  private _checkboxElementService: CheckboxElementService;
  private _firmService:FirmService;

  constructor(private injector: Injector) {  
    this._utilityService = this.injector.get(UtilityService);
    this._siteUserService = this.injector.get(SiteUserService);
    this._firmService = this.injector.get(FirmService);
    this._pageDataService = this.injector.get(PageDataService);
    this._messageService = this.injector.get(MessageService);
    this._dictionaryService = this.injector.get(DictionaryService);
    this._headerDataService = this.injector.get(HeaderDataService);
    this._dialogService = this.injector.get(DialogService);
  }

  private getDictionaryService(): DictionaryService {
    return this._dictionaryService;
  }

  public getUtilityService(): UtilityService {
    return this._utilityService;
  }

  private getMessageService(): MessageService {
    return this._messageService;
  }

  public getSiteUserService(): SiteUserService {
    return this._siteUserService;
  }

  public getFirmService():FirmService{
    return this._firmService;
  }

  public getPageDataService(): PageDataService {
    return this._pageDataService;
  }

  private getHeaderDataService(): HeaderDataService {
    return this._headerDataService;
  }

  private getDialogService(): DialogService {
    return this._dialogService;
  }

  private getViewElementLocalizedService(): ViewElementService {
    if(!this._viewElementService){
      this._viewElementService = this.injector.get(ViewElementService);
    }
    return this._viewElementService;
  }

  private getCheckboxElementService(): CheckboxElementService {
    if(!this._checkboxElementService){
      this._checkboxElementService = this.injector.get(CheckboxElementService);
    }
    return this._checkboxElementService;
  }
  
  private getColorService(): ColorService {
    if(!this._colorService){
      this._colorService = this.injector.get(ColorService);
    }
    return this._colorService;
  }

  public getFixedWord(word: string, default_value?: string){
    return this.getDictionaryService().getFixedWord(word, default_value);
  } 

  public goTo(destination): void{
    return this.getUtilityService().goTo(destination);
  }

  public isWindowSize(breakpointId, windowSize, operator): boolean{
    return this.getUtilityService().isWindowSize(breakpointId, windowSize, operator);
  }

  public isObjectEmpty(object): boolean{
    return this.getUtilityService().isObjectEmpty(object);
  }

  public sendMessage(message: string, severity?: string, icon?: boolean, link?: string) {
    return this.getMessageService().sendMessage(message, severity, icon, link);
  }

  public setPageData(data): void {
    return this.getPageDataService().setPageData(data);
  }

  public setHeaderData(data): void {
    return this.getHeaderDataService().setHeaderData(data);
  }

  public getPageData(): Observable<any> {
    return this.getPageDataService().getPageData();
  }

  public getHeaderData(): Observable<any> {
    return this.getHeaderDataService().getHeaderData();
  }

  public getFormChanges(): Observable<any> {
    return this.getPageDataService().getFormChanges();
  }
  public setFormChanges(data): void {
    return this.getPageDataService().setFormChanges(data);
  }

  public setActiveFilters(filters): void {
    return this.getPageDataService().setActiveFilters(filters);
  }

  public getActiveFilters(): Observable<any> {
    return this.getPageDataService().getActiveFilters();
  }

  public clearMessage(element: any): void {
    return this.getMessageService().clearMessage(element);
  }

  public getSiteUser(): Observable<any>{
    return this.getSiteUserService().getSiteUser();
  }

  public modalEvents(data?: any): Observable<any>{
    return this.getDialogService().modalEvents(data);
  }

  public updateViewElementLocalized(json: any): Observable<any>{
    return this.getViewElementLocalizedService().updateViewElementLocalized(json);
  }

  public deleteViewElementLocalized(id: number): Observable<any>{
    return this.getViewElementLocalizedService().deleteViewElementLocalized(id);
  }

  public getBorderColorClass(colorId: number): string{
    return this.getColorService().getBorderColorClass(colorId);
  }

  public getBackgroundColorClass(colorId: number): string{
    return this.getColorService().getBackgroundColorClass(colorId);
  }

  public getCheckboxElements(items: Array<any>, key: string): Array<CheckboxElement>{
    return this.getCheckboxElementService().getCheckboxElements(items, key);
  }

  public formatMoney(value: number, format: string, currency: string, includeCurrencyLabel: boolean, divider?: string): string{
    return this.getUtilityService().formatMoney(value, format, currency, includeCurrencyLabel, divider);
  }  
  public formatNumber(n: number, c: number, d: string, t: string): string{
    return this.getUtilityService().formatNumber(n, c, d, t);
  }

  public getSettingBooleanValue(guid: string): boolean {
    return this.getFirmService().getSettingBooleanValue(guid);
  }

  public toggleSidebar(event, scroll ?: boolean): void{
    return this.getUtilityService().toggleSidebar(event, scroll);
  }
}
