import { Component, Input } from '@angular/core';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

    private test: any;
    @Input() sliderConfig: any;

    constructor(){
        console.log(this.sliderConfig);
    }

    ngOnInit(){ 
        this.test = this.sliderConfig;
        console.log(this.test);
    }

}

