import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgSwitch } from '@angular/common';

@Component({
  selector: 'price-formatter',
  templateUrl: './price-formatter.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class PriceFormatterComponent implements OnInit, OnChanges {
  @Input() data: any;  // oggetto con dati prezzi, qui mi aspetto sempre un priceDetail
  @Input() key: string;  // chiave corrispondente al valore da formattare

  intPart: string;
  decPart: string;
  
  constructor() { 
  }

  ngOnInit() { 
    if(!this.data)
      return;
    
    var price = this.data[this.key];
    if(!price)
      price = 0;

    price = Math.round(price * 100) / 100;
    var split = price.toString().split(".");
    this.intPart = split[0];
    this.decPart = split[1] || '00';

    if(this.decPart.length == 1)
      this.decPart = this.decPart + '0';
  }

  ngOnChanges(): void { 
    if(!this.data)
      return;
    
    var price = this.data[this.key];
    if(!price)
      price = 0;

    price = Math.round(price * 100) / 100;
    var split = price.toString().split(".");
    this.intPart = split[0];
    this.decPart = split[1] || '00';

    if(this.decPart.length == 1)
      this.decPart = this.decPart + '0';
  }
}
