import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Firm } from './firm-data.model';
import { MessageService } from '../message-service/message.service'; 

@Injectable({
  providedIn: 'root'
})
export class FirmService {

  private baseApiUrl : string = '/api/v2/common/firm_data';
  public firm = new Subject<any>();
  public firmData:Firm;

  constructor(private http: HttpClient){
    this.loadFirmData()
      .subscribe(f => {
        this.firm.next(f);
        if(f || f.length > 0){
          this.firmData = f[0];
        }
      });
  }

  private loadFirmData(){
    return this.http.get<Firm[]>(this.baseApiUrl);
  }
  public getFirm():Firm {
    return this.firmData;
  }

  public getSettingBooleanValue(guid: string): boolean {
    if(!this.firmData || !this.firmData.settings) return false;
    
    for (var i = 0; i < this.firmData.settings.length; i++) {
      if(this.firmData.settings[i].name == guid) {
        return this.firmData.settings[i].value == 'true';
      }
    };
  };
  /*
  public getFirmData(): Observable<Firm[]>{
    return this.firm.asObservable();
  }*/
}