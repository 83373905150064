import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { SiteUserService } from '../../services/site-user/site-user.service';

/* l'equivalente della vecchia direttiva for-functionality 
 * esempio di utilizzo: <div *functionality="'guid.1,guid.2'">...</div>
*/
@Directive({
  selector: '[functionality]'
})
export class ForFunctionalityDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private siteUserService: SiteUserService) { }

  @Input() set functionality(guid: string) {
    let check = this.siteUserService.checkFunctionality(guid);

    if(check && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!check && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}