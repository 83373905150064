import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Input, forwardRef, 
          TemplateRef, Directive, ContentChildren, QueryList, ViewChildren } from '@angular/core';
import { NgSwitch } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonService } from '../../../services/common/common.service';
import { CustomerFilterService } from '../../../services/customer-filter/customer-filter.service';
import { Observable, Subscription, of } from 'rxjs';
import { query } from '@angular/animations';
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { ShowkTemplate } from '../../../directives/showk-template.directive';
import { BooleanOpertators } from '../../../enums/boolean-operators.enum';
  
enum DropItemType{
  Container="container",
  List="list",
  Group="group",
  Billing="bill_cust",
  Shipping="ship_cust",
  Cannel="canale"
}


class NestableListItem {
  //content:string;
  id:string;
  inclusive:boolean;
  type:DropItemType;
  editable:boolean;
  operator:BooleanOpertators;
  disable?:boolean;
  handle?:boolean;
  customDragImage?:boolean;
  children?:NestableListItem[];
  level?:number;
  class?:string;

  collapseList?:boolean=false;
}



@Component({
  selector: 'customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.scss'],
  providers: [
    CustomerFilterService
  ]
})
export class CustomerFilterComponent implements OnInit{
  @ViewChildren(ShowkTemplate)
  templates: QueryList<ShowkTemplate>;
  
  BooleanOps = BooleanOpertators;
  private currentDraggableEvent:DragEvent;
  private currentDragEffectMsg:string;
  private tplList = {};

  constructor(
    private service: CustomerFilterService, 
    private router: Router,
    private commonService: CommonService,) { 
  };

  private booleanQuery:NestableListItem[] = [{
        "type": DropItemType.Container,
        "id": this.commonService.getFixedWord('GENERAL_CUSTOMERS'),
        "inclusive": true,
        "operator": BooleanOpertators.AND,
        "editable": false,
        "children": [
          {
            "type": DropItemType.Container,
            "id": this.commonService.getFixedWord('TR_INCLUSIONS'),
            "inclusive": true,
            "editable": false,
            "operator": BooleanOpertators.OR,
            "children":[]      
          },
          {
            "type": DropItemType.Container,
            "id": this.commonService.getFixedWord('TR_EXCLUSIONS'),
            "inclusive": false,
            "editable": false,
            "operator": BooleanOpertators.AND,
            "children":[]  
          }
        ]
    }
  ];

  //ngAfterContentInit() {
  ngAfterViewInit() {
    console.log(this.templates);
    this.templates.forEach(x => this.tplList[x.tplId] = x.template);
    /*this.templates.changes.subscribe((comps: QueryList<DnDTemplate>) =>
    {
      console.log(comps);
    });*/
  }

  private getItemTemplate(i:NestableListItem):string{
    return i.type == DropItemType.Container? "container":"item";
  }

  ngOnInit(): void {
    console.log(this.service.filterData);
  }

  onDragStart( event:DragEvent ) {

    this.currentDragEffectMsg = "";
    this.currentDraggableEvent = event;

    //this.snackBarService.dismiss();
    //this.snackBarService.open( "Drag started!", undefined, {duration: 2000} );
  }

  dragStart(item){

  }

  copiedCB(item, tab){

  }


  onDragged( item:any, list:any[], effect:DropEffect ) {

    this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;

    if( effect === "move" ) {

      const index = list.indexOf( item );
      list.splice( index, 1 );
    }
  }

  onDragEnd( event:DragEvent ) {

    this.currentDraggableEvent = event;
    //this.snackBarService.dismiss();
    //this.snackBarService.open( this.currentDragEffectMsg || `Drag ended!`, undefined, {duration: 2000} );
  }

  onDrop( event:DndDropEvent, list?:any[] ) {

    if( list
      && (event.dropEffect === "copy"
        || event.dropEffect === "move") ) {

      let index = event.index;

      if( typeof index === "undefined" ) {
        index = list.length;
      }
      let item:NestableListItem = event.data;

      for (var i = 0; i < this.service.filterData.length; i++) {
        if(this.service.filterData[i].node_code.toLowerCase() == item.type.toLowerCase() && this.service.filterData[i].hierarchy_behavior) {
          item.class=this.service.filterData[i].hierarchy_behavior[item.level || 1].labelClass
        }
      };

      list.splice( index, 0, item );
    }
  }

  // ritorna il nome del tipo di entità per l'item corrente, scorrendo tra le entità disponibili per il modulo (le tabs)
  getEntityLabel(item:NestableListItem, tabs) {
    for (var i = 0; i < tabs.length; i++) {
      if(tabs[i].node_code.toLowerCase() == item.type.toLowerCase() && tabs[i].hierarchy_behavior) {
        return this.commonService.getFixedWord(tabs[i].hierarchy_behavior[item.level || 1].label);
      }
    };

    //RIF IVANO. Se in futuro hierarchy_behavior (che in questo momento non so a cosa serve :-D ) 
    //verrà usato anche per gli agenti allora la funzione terminerà al primo ciclo
    return this.commonService.getFixedWord("GENERAL_" + item.type.toUpperCase());
  };
}
