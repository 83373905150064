import { Component, Output, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable, Subject } from 'rxjs';
 
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class RouteGuardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {

    /**
     * Check if the component can be Activated (i.e. check permission to access a route)
     * @param component 
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        /**
         * TODO: check : user is logged in, user has permission to access route, ...
         */
        return true;
    }
    
    /**
     * Check if the component can be deactivated (i.e. change route)
     * @param component 
     */
    canDeactivate(component: CanComponentDeactivate) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }

}