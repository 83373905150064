import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@modules/shared-module/shared-module.module';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './styles/footer.component.scss'
  ],
})
export class FooterComponent implements OnInit {

  private footerData$: Observable<any>;
  private footerDataSubscription: Subscription;
  private footerData: any;
  private boostrapGridMax: number;
  private formInput: string;
  private user: any;
  private firmData: any;

  constructor(protected activeRoute: ActivatedRoute, private commonService: CommonService) { 

    this.formInput = '';
    this.footerData = {};
    this.boostrapGridMax = 12;
  }

  ngOnInit() { 

    this.user = this.commonService.getSiteUserService().allData.user;
    this.firmData = this.commonService.getFirmService().firmData;

    this.footerData = {  
      themeClass: 'light-theme',
      withContainer: false, 
      sections: [
        {
          type: 'logoAndSocial',
          socials: [
            {
              'title': 'Youtube',
              'iconClass': 'fa fa-youtube',
              'href': 'https://www.youtube.com/c/Lottiitaly/videos',
              'target': '_blank'
            },
          ]
        },
        {
          title: this.commonService.getFixedWord('GENERAL_CUSTOMER_SERVICE'),
          type: 'menu',
          items: [
            {
              'label': this.commonService.getFixedWord('GENERAL_CONTACTS'),
              'href': 'http://www.lottiitaly.com/contacts/',
              'target': '_blank'
            },
            {
              'label': this.commonService.getFixedWord('GENERAL_WHERE_WE_ARE'),
              'href': 'https://www.google.it/maps/place/Via+Abetone+Brennero,+149,+46025+Poggio+Rusco+MN/@44.971621,11.110775,15z/data=!4m18!1m12!4m11!1m3!2m2!1d11.11084!2d44.9715603!1m6!1m2!1s0x477f9939817ef963:0xce0d2e9c99d810c0!2sVia+Abetone+Brennero,+149,+46025+Poggio+Rusco+MN!2m2!1d11.111048!2d44.971485!3m4!1s0x477f9939817ef963:0xce0d2e9c99d810c0!8m2!3d44.971485!4d11.111048',
              'target': '_blank'
            },
            {
              'label': this.commonService.getFixedWord('GENERAL_WHO_WE_ARE'),
              'href': 'http://www.lottiitaly.com/company/',
              'target': '_blank'
            },
            {
              'label': this.commonService.getFixedWord('GENERAL_COMPANY'),
              'href': 'http://www.lottiitaly.com/',
              'target': '_blank'
            },
             {
              'label': this.commonService.getFixedWord('GENERAL_SALE_CONDITIONS'),
              'href': this.firmData.base_url + 'Condizioni-Vendita.pdf',
              'target': '_blank'
            }, 
          ]
        },
        {
          title: this.commonService.getFixedWord('GENERAL_MY_ACCOUNT'),
          type: 'menu',
          showUserData: true,
          items: [
            {
              'label': this.commonService.getFixedWord('GENERAL_MY_ORDERS'),
              'href': '/my-documents',
            },
          ]
        },
/*         {
          title: 'Newsletter',
          type: 'newsletter',
          data: {
            subtitle: 'Subscribe to our newsletter',
            placeholder: 'Your email address',
            emailTo: 'sgabriele@moxsolutions.it',
            button: {
              label: 'Send',
              icon: 'email'
            }
          }
        } */
      ],
      lower: {
        text: 'Powered by MOX Solutions'
      }


    };

    $( document ).ready(function() {
      
    });

  }

  private getContainerClass(sectionCount): string{
    let spacing = this.boostrapGridMax / sectionCount < 3 ? 3 : this.boostrapGridMax / sectionCount;
    return 'col-6 col-md-' + spacing;
  }

  public onSubmit(){
    console.log(this.formInput);

  }

}
