import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService, PageDataService, UtilityService} from '@modules/shared-module/shared-module.module';
import { PopoverService } from '@modules/ecommerce-shared-module/ecommerce-shared-module.module';
import { Subscription} from 'rxjs';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: [
    './master.component.scss',
    '../../../styles/themes/theme-light.scss',
    '../../../styles/themes/theme-dark.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class MasterComponent implements OnInit {

  private searchSubcription: Subscription;
  private themeClassSubcription: Subscription;
  private dialogSubscription: Subscription;

  private pageData: PageDataService;
  private utilityService: UtilityService;
  private themeClass: string;
  private siteColorThemes: any;
  private messagesModal: any;
  private lang: string;

  constructor(private commonService: CommonService, private router:Router, private renderer: Renderer2, private popoverService: PopoverService) {
    this.siteColorThemes = {
      1: 'theme-light',
      2: 'theme-dark'
    };
    this.messagesModal = { show: false, title: '', body: ''};
   }

  ngOnInit() { 

    var storedThemeClass = JSON.parse(localStorage.getItem('themeClass'));

    this.lang = this.commonService.getUtilityService().getLangParam();

    if(storedThemeClass)
      this.themeClass = storedThemeClass.theme;
    else 
      this.themeClass = this.detectUserColorScheme();


    this.pageData = this.commonService.getPageDataService();
    this.utilityService = this.commonService.getUtilityService();

    //la richiesta di ricerca arriva dall'header. Non posso quindi gestirla all'interno di search
    //va gestita all'interno del componente principale della pagina
    this.searchSubcription = this.pageData.getSearchRequest().subscribe(
      data => {   

        console.log('getSearchRequest');
        
        let params = {q:data.search_text};
        if(data.node_id)
          params["c"] = data.node_id;

        this.router.navigate(['/' + this.lang + '/ecommerce/search'], {
          queryParams: params
        }).then(() =>{
          this.pageData.setCurrentQueryString(this.router.url);
        });
      }
    );

    //Subscription su cambio tema (dark/light)
    this.searchSubcription = this.utilityService.getThemeSettingsChange().subscribe(
      id => {   
        this.themeClass = this.siteColorThemes[id];
        localStorage.setItem('themeClass', JSON.stringify({theme: this.themeClass }));
      }
    );

    /**
     * Subscribe to dialog service
    */
    this.dialogSubscription = this.commonService.modalEvents().subscribe(
      data => {
        if(data.params){
          this.messagesModal.title = data.params.title;
          this.messagesModal.body = data.params.body;
          this.messagesModal.show = data.params.show;
        }
      }
    );

    /**
     * Gestione del click fuori dai popover. Messo qui perchè dentro il componente popover l'evento viene triggerato più volte, dal momento che ci sono più di un componente 
     * di tipo popover.
     * Chude il popover quando si clicca fuori dal component.
     */
    this.renderer.listen('window', 'click',(e:Event)=>{
      for (let [key, value] of Object.entries(this.popoverService.list)) {    
        if(value['opened']){
          let popoverElement = document.getElementById(key + '-container');
          if(e['path'].indexOf(popoverElement) == -1){
            this.popoverService.togglePopover(key);
          } 
        }
      }
    });
  }

    /**
   * Dismiss dialog 
   * @param b 
   */
  private dismiss(confirm: boolean): void{
    this.messagesModal.show = false;
    this.commonService.modalEvents({confirm: confirm});
  }
  

  private detectUserColorScheme(): string {

    let themeClass: string = this.siteColorThemes[1]; //Default

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      themeClass =  this.siteColorThemes[2];
    } 

    localStorage.setItem('themeClass', JSON.stringify({theme: themeClass }));

    return themeClass;
  }

  ngOnDestroy(): void {  }

}
