declare var dictionary:any;

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

 
@Injectable({
  providedIn: 'root'
})
export class DictionaryService{
  
  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location){ }
  /**
   * 
   * @param word 
   * @param default_value 
   */
  public getFixedWord(word: string, default_value: string){
    default_value = default_value || '';

    if(dictionary && dictionary[word]){
      var fixedWord = dictionary[word].user ? dictionary[word].user : dictionary[word].base;

      if(fixedWord != undefined && fixedWord != null && fixedWord.length > 0)
        return fixedWord;
      else
        return default_value ? default_value : word;
    }    
    return word;
  }
}