import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  CartItem: {
    selectId: (item: any) => item.product_id,
    entityDispatcherOptions: { optimisticAdd: true, optimisticUpdate: true } //usando product_id come chiave, il dispatcher può essere ottimista ;-D 
  },
  Wishlist: {
    selectId: (item: any) => item.wishlist_id
  },
  ComparisonItem: {
    selectId: (item: any) => item.product_id
  },
  SmwItem:{
    
  }
};

// because the plural of "hero" is not "heros"
const pluralNames = { 
  //Cart: 'Cart', //per il caricamento ogni utente carica il suo carrello
  //Wishlist: 'WishLists' 
};
 
export const entityConfig = {
  entityMetadata,
  pluralNames
};