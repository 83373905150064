import { Component, OnInit, Input, ViewEncapsulation, Renderer2 } from '@angular/core';
import { NgSwitch } from '@angular/common';
import { Environment } from '../../../enum/environment.enum';
import { ListViewType } from '../../../enum/list-view-type.enum';

import { CommonService, AppInitService } from '@modules/shared-module/shared-module.module';
import { BuyService } from '@modules/buy-module/buy-module.module';
import { ProductListService } from '@modules/ecommerce-shared-module/services/product-list.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'product-list-item',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductListItemComponent implements OnInit {

  private sliderConfig;
  private isLoading: boolean = false;
  private lang: string;
  private togglerIcon: string;
  private outOfProduction: boolean = false;
  private anonymous: boolean = false;

  @Input() product: any;
  @Input() viewType: ListViewType;
  @Input() environment: Environment;
  
  constructor(
    private commonService: CommonService,
    private buyService: BuyService,
    private productListService: ProductListService,
    private router: Router,
    private renderer: Renderer2,
    protected activeRoute: ActivatedRoute,
    private appInitService: AppInitService
  ) {
    this.togglerIcon = 'ellipsis-horizontal';

    this.anonymous = appInitService.userData.is_anonymous;
  }

  ngOnInit() { 
    this.lang = this.commonService.getUtilityService().getLangParam();

    if(this.environment == Environment.WishlistDetail){
      this.product.inWishlist = true;
    }

    this.sliderConfig = {
      'slidesPerView': 1,
      'spaceBetween': 0,
      'centeredSlides': true,
      'loop': true,
      'pagination': {
        'el': '.product-image-pagination-' + this.product.id,
        'type': 'bullets',
        'clickable': true,
      },
    };

    if(this.product.metadata && this.product.metadata.tag){
      for(let i = 0; i < this.product.metadata.tag.length; i++){
        if(this.product.metadata.tag[i] == 'OOP')
          this.outOfProduction = true;
      }
    }

    //Selezione variante corrispondente al prodotto stesso
    if(this.product.variants_list && this.product.variants_list.length){
      this.product.variants_list.find(p => p.product_id == this.product.id).selected = true;
    }

  }

  ngOnDestroy(): void { }

  /**
   * TODO: gestire visualizzazione prodotto in carrello se è effettivamente dentro al carrello
   */
  public addToCart(product): void {

    //this.product.in_cart = true;

    let param = {
      productID: product.id,
    };

    //Show standard buy on mobile
    if(this.commonService.isWindowSize('md', window.innerWidth, '<')){
      param['customBuyComponent'] = '';
    } else {
      param['customBuyComponent'] = product.buy_template_component;
    }


    this.buyService.openBuyModal(param);
  }

  private getStatusClass(variant): string {
    let cssClass = '';
    switch(variant.availability_status){
      case 0:
        cssClass = 'bkg_dgreen_light'; break;
      case 1:
        cssClass = 'bkg_yellow_dark'; break;
      case 2:
        cssClass = 'bkg_red_light'; break;
      default: 
        cssClass = 'bkg_dgreen_light'; break;
    }
    return cssClass;
  }

  private variantSelected(variant): void {

    if(variant.product_id == this.product.id) return;

    this.productListService.loadVariantData({ productId: this.product.id, variantId: variant.product_id, environment: this.environment })
    .subscribe(res => { });

  }

  public removeFromCart(id): void {
    //this.product.in_cart = false;
  }

  public toggleActions(event, id, leavingContainer?: boolean): void{

    event.preventDefault();
    event.stopPropagation();

    if((event.type == 'mouseenter' || event.type == 'mouseleave') && this.commonService.isWindowSize('md', window.innerWidth, '<')){
      return;
    }

    let actionsContainer = document.getElementById('product-actions-' + id);
    if(!actionsContainer)
      return;
    
    let className = "expanded";

    if(!leavingContainer){
      actionsContainer.classList.contains(className) ? actionsContainer.classList.remove(className) : actionsContainer.classList.add(className);
    } else {
      actionsContainer.classList.remove(className); 
    }

  }

  public toggleCanvass(event, id, leavingContainer?: boolean): void{

    event.preventDefault();
    event.stopPropagation();

    if((event.type == 'mouseenter' || event.type == 'mouseleave') && this.commonService.isWindowSize('md', window.innerWidth, '<')){
      return;
    }

    let canvassContainer = document.getElementById('product-canvass-' + id);
    if(!canvassContainer)
      return;
    
    let className = "expanded";

    if(!leavingContainer){
      canvassContainer.classList.contains(className) ? canvassContainer.classList.remove(className) : canvassContainer.classList.add(className);
    } else {
      canvassContainer.classList.remove(className); 
    }

  }

  

  private goToDetail(event, productId): void{
    this.router.navigate(['/' + this.lang + '/ecommerce/item/' +  productId]).then( () => {
      window.scrollTo(0, 0);
    });
  }

  private getVariantCssClass(variant): string{

    let classList: string = '';

    variant.selected ? classList += ' selected': '';
    /* variant.availability_status == 2 ? classList += ' disabled' : classList += ' hover-reveal'; */

    return classList;
  }

}
