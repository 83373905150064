import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

//import service
import { ProductListService } from '../../../services/product-list.service';

import { Environment } from '../../../enum/environment.enum';

import { MessageService } from '@modules/shared-module/shared-module.module';
import { UtilityService } from '@modules/shared-module/shared-module.module';
import { CommonService } from '@modules/shared-module/shared-module.module';


import { Observable, Subscription, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ProductsListSettings } from '../../../model/products-list-settings.model';


@Component({
    selector: 'products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.scss']
})

export class ProductsListComponent implements OnInit {
    @Input("environment") env: Environment;

    listSettings: ProductsListSettings;

    listSettingsSubscription: Subscription;

    constructor( 
        protected activeRoute: ActivatedRoute,
        protected router: Router,
        protected location: Location,
        protected service: ProductListService,
        private commonService: CommonService,
    ){
        this.listSettings = service.listSettings;
        this.listSettingsSubscription = new Subscription();

        this.listSettingsSubscription = service.listSettingsChanged$.subscribe(
            newSettings => {

                this.listSettings = newSettings;

                this.service.nextPage({reset: true})
                .subscribe(res => { });
            }
        );
    }

    ngOnInit() {  }
    /*
    */

    nextPage(): void {
        if(this.service.loading || this.service.noMoreData)
            return;

        this.service.nextPage()
        .subscribe(res => {
            // TODO
        });
    }




}