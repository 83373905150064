import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SiteUser, UserData } from './site-user.model';
import { MessageService } from '../message-service/message.service'; 

@Injectable({
  providedIn: 'root'
})
export class SiteUserService {

  private baseApiUrl : string = '/api/v2/common/user_data';
  public allData: UserData = new UserData();
  public siteUser: SiteUser;
  private currentUser = new Subject<any>();

  constructor(private http: HttpClient){
    this.loadSiteUser()
      .subscribe(all => {
        this.allData = all;
        this.currentUser.next(this.allData.user);
      } );
  }

  private loadSiteUser(){
    return this.http.get<UserData>(this.baseApiUrl);
  }

  public getSiteUser(): Observable<SiteUser>{
    return this.currentUser.asObservable();
  }

  public checkFunctionality(guid: string): boolean {
    let functs = this.allData.functionalities;
    if(!functs || functs.length == 0)
      return false;

    let guidArr = [];
    if(guid.indexOf(',') > -1) {
      guidArr = guid.split(',');
    } else {
      guidArr.push(guid);
    }

    for (var i = 0; i < functs.length; i++) {
      if(guidArr.indexOf(functs[i].functionality_guid) >= 0) {
        return true;
      }
    }
    return false;
  }

  public changeLanguage(culture: any): Observable<any> {
    return this.http.post(this.baseApiUrl + '/change-language', culture)
    .pipe(map((response: Response) => {
      let result = this.refineResult(response);
      return result;
    }));
  }
  
  private refineResult(result: any): any {
    return result; 
  }
}