import { Injectable } from '@angular/core';
import { UtilityService } from '../../services/utility-service/utility.service';
import { ViewElementService } from '../../services/view-element/view-element.service';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class CultureListResolver implements Resolve<Observable<UtilityService>> {
  constructor(private service: UtilityService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.service.getCultureList();
  }
}

@Injectable()
export class ViewelementLocalizedResolver implements Resolve<Observable<ViewElementService>> {
  constructor(private service: ViewElementService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.service.getViewElementLocalized(+route.paramMap.get('localized'));
  }
  
}
