import { Component, OnInit, Input } from '@angular/core';

import { CommonService } from '@modules/shared-module/shared-module.module';
import { DownloadService } from '../../../services/actions/download.service';

@Component({
  selector: 'product-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadActionComponent implements OnInit {
  @Input() product: any;
  
  data: any = '';
  openedModal: boolean = false;  

  constructor(
    protected commonService: CommonService,
    protected service: DownloadService
  ) { }

  ngOnInit() { 
  }

  ngOnDestroy(): void {  }

  openModal(content) {
    // la lista la dovrò caricare qua in qualche modo.. credo convenga mantenerla nel service comunque
    this.service.getProductDownloadData(this.product.id)
    .subscribe(res => {
      this.data = res;
      console.log(this.data.sheetsTypes);
      this.openedModal = true;
    });
  }

  toggleSelectedCategory(index): void {
    for(var i = 0; i < this.data.sheetsTypes[index].templates.length; i++) {
      this.data.sheetsTypes[index].templates[i].checked = this.data.sheetsTypes[index].checked;
    }
  };
}
