import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, Subscription, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { CommonService } from '@modules/shared-module/shared-module.module';
import { BuyService } from '../services/buy.service';

import { BuyTemplateDirective } from '../directives/buy-template.directive';
import { BuyComponentInterface } from '../interfaces/buy.interface';

import { buyComponents } from '../constants/buy-components.const';
import { PriceFormatterComponent } from '../components/utils/price-formatter/price-formatter.component';

import { AppEntityServices, DataStoreInterface } from '../../ngrx/services/ngrx.service';
@Component({
    selector: 'buy-manager',
    templateUrl: './buy.component.html',
    styleUrls: ['./buy.component.scss'],
    encapsulation: ViewEncapsulation.None  
})

export class BuyComponent implements OnInit {
    @ViewChild(BuyTemplateDirective, {static: true}) buyTemplate: BuyTemplateDirective;

    openBuy: boolean = false;
    customBuyComponent: string = 'standard';

    productID: number;

    buyRequestSubscription: Subscription;

    constructor( 
        protected activeRoute: ActivatedRoute,
        protected router: Router,
        protected location: Location,
        protected service: BuyService,
        private commonService: CommonService,
        private componentFactoryResolver: ComponentFactoryResolver,
        protected entityServices: AppEntityServices
    ){
        this.buyRequestSubscription = service.buyRequest$.subscribe(
            buySettings => {
                let requestedComponent = buySettings.customBuyComponent || 'standard';

                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(buyComponents[requestedComponent]);

                const viewContainerRef = this.buyTemplate.viewContainerRef;
                viewContainerRef.clear();

                const componentRef = viewContainerRef.createComponent(componentFactory);
                (<BuyComponentInterface>componentRef.instance).productID = buySettings.productID;

                /*Viene impostato il buymanager ovvero il servizio che andrà a salvare i dati dei prodotti aggiunti
                 prendendo l'entityService di ngrx corretta
                */
                if(buySettings.environment && buySettings.environment == 'smw')
                    (<BuyComponentInterface>componentRef.instance).buyManagerService = entityServices.smwItemService;//.getEntityCollectionService("CartItem");
                else
                    (<BuyComponentInterface>componentRef.instance).buyManagerService  = entityServices.cartItemService;//(SmwItemService) entityServices.getEntityCollectionService("SmwItem");


                // this.customBuyComponent = buySettings.customBuyComponent || 'standard';
                // this.productID = buySettings.productID;

                console.log(buySettings);

                this.service.openBuy = true;
            }
        );
    }

    ngOnInit() { 
    } 
}