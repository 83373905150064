import { Component, OnInit, HostListener, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { SiteUserService } from '../../services/site-user/site-user.service';
import { Subscription, Observable } from 'rxjs';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { SiteUser } from '../../services/site-user/site-user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./styles/header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  private userDataSubscription: Subscription;
  private userData: SiteUser;
  private headerData$: Observable<any>;
  private headerDataSubscription: Subscription;
  private headerData: any;
  private showMobileSubnav: boolean;
  private searchText: string;
  private showElements: any;
  private headerPosition: number;
  private stickyHeader: boolean;
  private siteUser:SiteUser;
  private lang: string;


  constructor(private commonService: CommonService,   
            protected activeRoute: ActivatedRoute, private eRef: ElementRef,
            protected siteUserService: SiteUserService
    ) { 
    this.searchText = '';
    this.userData = new SiteUser();
    this.showElements = {
      'tools': false,
      'search': false,
      'popover': false
    };
    this.stickyHeader = false;
    this.userDataSubscription = this.commonService.getSiteUser().subscribe(
      data => {
        this.userData = data;
      }
    );
    this.headerData = {};
    this.headerDataSubscription = this.commonService.getHeaderData().subscribe(
      data => {
        this.headerData = data;
      }
    );
    this.lang = this.commonService.getUtilityService().getLangParam();
  }

  @ViewChild('ecommerceHeader', {static: false}) ecommerceHeader: ElementRef;

  ngOnInit() { 
    this.siteUser = this.siteUserService.siteUser;
    console.log(this.siteUser);
    this.headerData = {  
      template: 'backoffice',
      themeClass: 'light-theme',
      withContainer: false,
      contactTools: [
        { 
          'iconShape': 'email',
          'label': null,
          'action': {
            'href' : 'mailto:sgabriele@temovo.com',
          }
        },
        { 
          'iconShape': 'phone-handset',
          'label': null,
          'action': {
            'href' : 'tel:0423722279',
          }
        },
      ],
      toolbar: [
        { 
          'iconShape': 'certificate',
          'label': this.commonService.getFixedWord('MYDOCUMENTS_TITLE'),
          'badge': {
            'count': 0
          },
          'action': {
            'type': 'link',
            'href': '/IT/mydocuments'
          }
        },
        {
          'iconShape': 'download',
          'label': null,
          'badge': {
            'count': 2
          },
          'action': {
            'type': 'popoverTrigger',
            'href': '#',
            'params': { 'target': '' }
          }
        },
        {
          'iconShape': 'star',
          'label': null,
          'badge': {
            'count': 4
          },
          'action': {
            'type': 'popoverTrigger',
            'href': '#',
            'params': { 'data-target': '#wishlistModal' }
          }
        },
        {
          'iconShape': 'two-way-arrows',
          'label': null,
          'badge': {
            'count': 0
          },
          'action': {
            'type': 'popoverTrigger',
            'href': '#',
            'params': { 'data-target': '#compareModal' }
          }
        },
      ],
      context: [
        {
          'id': 'applicationsMenu',
          'iconShape': 'applications',
          'iconClass': 'is-solid',
          'label': null,
          'badge': false,
          'type': 'dropdown',
          'cssClasses': 'context-submenu-toggle position-relative',
          'children': [
            {
              'id': 'ecommerce',
              'label': 'E-commerce',
              'iconShape': 'store',
              'href': '/' + this.lang + '/ecommerce/homepage',
            },
            {
              'id': 'backoffice',
              'label': 'Administration',
              'iconShape': 'dashboard',
              'href': '/admin',
            },
            {
              'id': 'documents',
              'label': 'Documents management',
              'iconShape': 'file-group',
              'href': '/IT/documents',
            },
/*             {
              'id': 'crm',
              'label': 'CRM',
              'iconShape': 'organization',
              'href': '#',
            }, */
          ],
        },
        {
          'id': 'notifications',
          'iconShape': 'bell',
          'iconClass': '',
          'label': null,
          'badge': false,
          'type': '',
          'action': { },
        },
        {
          'id': 'usermenu',
          'iconShape': 'user',
          'iconClass': '',
          'label': null,
          'badge': false,
          'type': '',
          'action': { },
        },
      ],
      middleTools: [
        {

        },
      ],
/*       submenu: [
        {
          type: 'megamenu-1',
          title: 'Catalogue',
          groups: [
            {
              title: 'Weapons',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/d82fda76-1716-41b0-88a4-51db972fe476.jpg',
              viewMore: {
                href: '#',
                title: 'View More'
              },
              links: [
                {
                  title: 'Beretta Weapons',
                  href: '#'
                },
                {
                  title: 'SAKO Weapons',
                  href: '#'
                },
                {
                  title: 'Victrix Weapons',
                  href: '#'
                },
                {
                  title: 'Umarex Weapons',
                  href: '#'
                },
              ]
            },
            {
              title: 'Optics',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/b2fdf282-006e-45da-917e-773107f0589a.jpg',
              links: [
                {
                  title: 'Buris',
                  href: '#'
                },
                {
                  title: 'Steiner',
                  href: '#'
                },
              ]
            },
            {
              title: 'Clothing',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/695d446f-5480-4f16-cfdb-29ef495ddada.jpg',
              viewMore: {
                href: '#',
                title: 'View More'
              },
              links: [
                {
                  title: 'B-Classic',
                  href: '#'
                },
                {
                  title: 'Gentleman Hunt',
                  href: '#'
                },
                {
                  title: 'Victory',
                  href: '#'
                },
                {
                  title: 'Culture',
                  href: '#'
                },
                {
                  title: 'Hunt',
                  href: '#'
                },
              ]
            },
            {
              title: 'Ammunitions',
              imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/c3655880-7000-4f64-f31f-21f9f57c53c3.jpg',
              links: [
                {
                  title: 'Shells',
                  href: '#'
                },
                {
                  title: 'Bullets',
                  href: '#'
                },
                {
                  title: 'Cartridges',
                  href: '#'
                },
              ]
            },
          ],
          highlight: {
            href: '#',
            imageURL: 'https://s3-eu-west-1.amazonaws.com/beretta/images/lambdaresized/170x160/upload/fd7b2bad-54d3-4b28-df2f-4064a8a645a7.jpg'
          }
        }
      ]   */
    };

    if(this.headerData.with_container){
      this.showMobileSubnav = this.commonService.isWindowSize('md', $(window).width(), '<');
    }

    for(let element of this.headerData.context){
      if(element.type == 'dropdown')
        this.showElements[element.id] = false;
    }

    this.activateHoverOnMenu();

  }

  ngAfterViewInit(){
    this.headerPosition = this.ecommerceHeader ? this.ecommerceHeader.nativeElement.offsetHeight : 0;
  }


  @HostListener('window:resize', ['$event']) onResize(event) {
    if(this.headerData.with_container){
      this.showMobileSubnav = this.commonService.isWindowSize('md', event.target.innerWidth, '<');
    }
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:mouseover', ['$event'])
  clickout(event) {
    //Detect click / mouseover outside header component
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.hideAllPopups();
    } 
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll($event) {

    const windowScroll = window.pageYOffset;
    if(windowScroll >= this.headerPosition){
      this.stickyHeader = true;
    } else {
      this.stickyHeader = false;
    }

  }



  private getLinkClass(templateType: string, isActive?: boolean): string{
    let classes = '';
    if(templateType == 'b2b'){
      classes += ' nav-link ';
    }
    if(isActive){
      classes += ' active ';
    }
    return classes;
  }

  public activateHoverOnMenu(): void{

    $( document ).ready(function() {

      bindMenuEvents();
      $(window).resize(function(){
        bindMenuEvents();
      });

      $(window).on("orientationchange", function(){
        bindMenuEvents();
      });

    });

    var bindMenuEvents = function(){

        if($(window).width() > 768){

          $(".dropdown-menu").addClass('adjust-top');
          
          //Toggle dropdown on hover
          $(".dropdown-toggle").hover(
            function() {
              showMenu(this);
            },
            function() {
              hideMenu(this);
            }
          );
  
          $(".dropdown-menu").hover(
            function() { },
            function() {
              $(this).addClass('is-off-screen');
              $(this).prev().removeClass('active');
            }
          );
        } else {
          $(".dropdown-menu").removeClass('adjust-top');
          $('.dropdown-toggle').click(function(event){
              event.preventDefault();
              event.stopPropagation();
              $(this).removeClass('active');
              $(this).next().slideDown(200);
    
            });
          }
      

    }

    var showMenu = function(trigger){
      if(!$(trigger).hasClass('active')){
        $(trigger).addClass('active');
        $(trigger).next().removeClass('is-off-screen');
      }
    }

    var hideMenu = function(trigger){
      if(!$(trigger).next().is(":hover")){
        $(trigger).removeClass('active');
        $(trigger).next().addClass('is-off-screen');
      }
    }

    var detectTablet = function(){
      if (
        navigator.userAgent.match(/Tablet/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/Kindle/i) ||
          navigator.userAgent.match(/Playbook/i) ||
          navigator.userAgent.match(/Nexus/i) ||
          navigator.userAgent.match(/Xoom/i) ||
          navigator.userAgent.match(/SM-N900T/i) || 
          navigator.userAgent.match(/GT-N7100/i) ||
          navigator.userAgent.match(/SAMSUNG-SGH-I717/i) || 
          navigator.userAgent.match(/SM-T330NU/i) 
      
      ){ return true; }
      else return false;
    }
  }

  private hideAllPopups = function(): void{
    for (let [key, value] of Object.entries(this.showElements)) {
      this.showElements[key] = false;
    }
  }

  public toggle(event, element): void{
    event.preventDefault();
    this.hideAllPopups();
    this.showElements[element] = true;
  }

  public doSearch(): void{
    var par = {search_text: this.searchText};
    this.commonService.getPageDataService().setSearchRequest(par);
  }

}


