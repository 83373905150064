import { NgModule } from '@angular/core';
import { ClarityModule } from "@clr/angular";
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule }   from '@angular/forms';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CookieService } from 'ngx-cookie-service';

//Directives

//Services
import {ProductListService} from './services/product-list.service';
import {ComparisonService} from './services/actions/comparison.service';
import {WishlistService} from './services/actions/wishlist.service';
import {DownloadService} from './services/actions/download.service';
import {PopoverService} from './services/popover.service';

//Resolvers

//Model/Enum - interface
import {Environment} from './enum/environment.enum';
import { SidebarPosition } from './enum/detail-sidebar-position.enum';

//Components
import { ProductListItemComponent } from './components/list/product-list-item/product.component';
import { ProductsListComponent } from './components/list/products-list/products-list.component';
import { ProductsListToolbarComponent } from './components/list/products-list/toolbar/toolbar.component';
import { FacetsComponent } from './components/list/facets/facets.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DetailSidebarComponent } from './components/detail-sidebar/detail-sidebar.component';
import { ComparisonActionComponent } from './components/actions/comparison/comparison.component';
import { WishlistActionComponent } from './components/actions/wishlist/wishlist.component';
import { DownloadActionComponent } from './components/actions/download/download.component';
import { PopoverListItemComponent } from './components/popover-list-item/popover-list-item.component';
import { PopoverComponent } from './components/popover/popover.component';
import { LightboxComponent } from './components/lightbox/lightbox.component';

import { StockViewComponent } from './components/stock-view/stock-view.component';

//Carousel
import { SwiperModule } from 'ngx-swiper-wrapper';

/* In questo elenco vanno inseriti service, models, enums, ovvero gli elementi che non vengono riportati all'interno di @NgModule sotto */
export {  
  //service
  ProductListService, 
  ComparisonService,
  Environment,
  SidebarPosition,

  //components
  ProductsListComponent,
  ProductListItemComponent,
  FacetsComponent,
  ComparisonActionComponent,
  WishlistActionComponent,
  DownloadActionComponent,
  StockViewComponent,
  BreadcrumbComponent,
  DetailSidebarComponent,
  HeaderComponent,
  FooterComponent,
  PopoverListItemComponent,
  PopoverComponent,
  PopoverService,
  LightboxComponent,
  WishlistService
};
/*
NOTA: quando si crea un componente condiviso (es FacetsComponent) ed usato da più
"moduli", è necessario inserirlo in declarations e exports.

Nel modulo che poi dovrà usare il componente è necessario solamente importare EcommerceSharedModule
NON è necessario importare (o dichiarare il componente).

Per quanto riguarda i service, non è necessario indicarli qui. 
Sarà cura del modulo importarsi i services necessari aggiungendoli tra i providers 
*/
@NgModule({
  imports: [ 
    ClarityModule,
    CommonModule,
    NgbModule, 
    FormsModule,
    ImgFallbackModule,
    InfiniteScrollModule,
    SwiperModule,
    RouterModule.forChild([]),
  ],
  declarations:[    
    //components
    ProductListItemComponent,
    FacetsComponent,
    ProductsListComponent,
    HeaderComponent,
    FooterComponent,
    ProductsListToolbarComponent,
    ComparisonActionComponent,
    WishlistActionComponent,
    DownloadActionComponent,
    StockViewComponent,
    BreadcrumbComponent,
    DetailSidebarComponent,
    PopoverListItemComponent,
    PopoverComponent,
    LightboxComponent
  ],
  providers:[
    ComparisonService,  // aggiunto per errore StaticInjectorError NullInjectorError: No provider for .. ok, ma perché non necessario in facets quando si importa il produlctListService?
    CookieService,           // anche questo aggiunto per l'utilizzo in comparisonService o da errore analaogo 
    WishlistService,
    DownloadService,
    PopoverService,
    ProductListService
  ],
  exports: [
    //components
    ProductListItemComponent,
    FacetsComponent,
    HeaderComponent,
    FooterComponent,
    ProductsListComponent,
    ProductsListToolbarComponent,
    ComparisonActionComponent,
    WishlistActionComponent,
    DownloadActionComponent,
    StockViewComponent,
    BreadcrumbComponent,
    DetailSidebarComponent,
    PopoverListItemComponent,
    PopoverComponent,
    LightboxComponent,
  ],
})
export class EcommerceSharedModule { }

