import { NgModule } from '@angular/core';

import { DndModule } from "ngx-drag-drop";

import { ClarityModule } from "@clr/angular";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Directives
import { FixOnScrollDirective } from './directives/fix-on-scroll/fix-on-scroll.directive';
import { ForFunctionalityDirective } from './directives/for-functionality/for-functionality.directive';

import { ShowkTemplate } from './directives/showk-template.directive';

//Services
import { DialogService } from './services/dialog/dialog.service';
import { MessageService } from './services/message-service/message.service';
import { UtilityService } from './services/utility-service/utility.service';
import { RouteGuardService } from './services/route-guard/route-guard.service';
import { ViewElementService } from './services/view-element/view-element.service';
import { CommonService } from './services/common/common.service';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { SliderComponent } from './components/slider/slider.component';
import { SiteUserService } from './services/site-user/site-user.service';
import { FirmService } from './services/firm-data/firm-data.service';
import { PageDataService } from './services/page-data/page-data.service';
import { PriceListService } from './services/pricelist/pricelist.service';
import { ColorService } from './services/color/color.service';
import { CheckboxElementService } from './services/checkbox-element/checkbox-element.service';
import { AppInitService } from './services/app-init/app-init.service';

//Resolvers
import { CultureListResolver, ViewelementLocalizedResolver } from './resolvers/common/common.resolver';

//Model - interface
import { Colors } from './services/color/color.model';
import { CheckboxElement } from './services/checkbox-element/checkbox-element.interface';
import { SiteUser } from './services/site-user/site-user.model';

//Components

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FilterToolbarComponent } from './components/tools/filter-toolbar/filter-toolbar.component';
import { HeadToolbarComponent } from './components/tools/head-toolbar/head-toolbar.component';
import { CustomerFilterComponent } from './components/tools/customer-filter/customer-filter.component';
import { AppNotificationComponent } from './components/app-notification/app-notification.component';

//Carousel
import { SwiperModule, SwiperConfigInterface } from 'ngx-swiper-wrapper';

//Slickgrid

import * as $ from "jquery";

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

export { 
  FixOnScrollDirective, 
  ForFunctionalityDirective,
  MessageService, 
  UtilityService, 
  CommonService, 
  RouteGuardService, 
  ViewElementService, 
  DialogService,
  MessageBoxComponent,
  SiteUserService,
  FirmService,
  PriceListService,
  CultureListResolver,
  ViewelementLocalizedResolver,
  ColorService,
  Colors,
  SiteUser,
  CheckboxElement,
  CheckboxElementService,
  SwiperModule,
  SliderComponent,
  HeaderComponent,
  FooterComponent,
  FilterToolbarComponent,
  HeadToolbarComponent,
  AppNotificationComponent,
  PageDataService, //Esporto anche questo modulo in quanto sarà usato in larga parte
  AppInitService
};

@NgModule({
  imports:[
    SwiperModule,
    DndModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,

  ],
  declarations:[
    SliderComponent,
    CustomerFilterComponent,
    ShowkTemplate,
    HeaderComponent,
    FooterComponent,
    FilterToolbarComponent,
    HeadToolbarComponent,
    FixOnScrollDirective,
    ForFunctionalityDirective,
    AppNotificationComponent
  ],
  providers:[

  ],
  exports: [
    SwiperModule,
    SliderComponent,
    HeaderComponent,
    FooterComponent,
    FilterToolbarComponent,
    HeadToolbarComponent,
    CustomerFilterComponent,
    AppNotificationComponent,
    ForFunctionalityDirective
  ],
})
export class SharedModule { }

