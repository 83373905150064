import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ViewElementService{

    constructor(private http: HttpClient){}

    public getViewElementLocalized(id: number): any{
        return this.http.get('/api/v1/viewelementlocalized/' + id);
    }

    public updateViewElementLocalized(json: any): any{
        return this.http.put('/api/v1/viewelementlocalized/' + json.ve_localized_id, json);
    }

    public deleteViewElementLocalized(id: number): any{
        return this.http.delete('/api/v1/viewelementlocalized/' + id);
    }

}