import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http'; 
import { HttpClient  } from '@angular/common/http'; 
// import { Response, Headers, RequestOptions } from '@angular/http'; 
import { Observable, Subject, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

/*
{
  //providedIn: 'root'
} */
@Injectable()
export class StandardBuyService {
  private baseApiUrl: string = '/api/v2/ecommerce/buy';

  private headers: Headers;
  private options: RequestOptions;

  constructor (private http: HttpClient) {
    this.headers = new Headers({ 'Content-type': 'application/json' });
    this.options = new RequestOptions({ headers: this.headers });
  }

  /**
   * standard buy load data function
   */
  
  loadData(id: number): Observable<any> {
    let payload = {
      product_id: id,
      // document_id: document_id ? TODO ?
    }
    return this.http.post(this.baseApiUrl + '/standard', payload)
    .pipe(map((response: Response) => {
      /*
      let result = {};
      if (response) {
        switch(response.status) {
          case 200: {
            result = response.json() || {};
            break;
          }
          default:
            break;  
        }
      }*/
      return response;
    }));

  }
}
