

export class HeaderData{

    theme: string;
    tools: any;

    constructor(params: any){
        this.theme = params.theme;
        this.tools = params.tools;
    }
}