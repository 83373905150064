import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { CommonService } from '@modules/shared-module/shared-module.module';

import { BuyComponentInterface } from '../../interfaces/buy.interface';

import { BuyService } from '../../services/buy.service';
import { StandardBuyService } from '../../services/specific/standard-buy.service';
import { DataStoreInterface } from '../../../ngrx/services/ngrx.service';

//import { CartService} from '../../services/cart.service';

@Component({
    selector: 'standard-buy',
    templateUrl: './standard-buy.component.html',
    styleUrls: ['./standard-buy.component.scss', '../buy.component.scss'],
    encapsulation: ViewEncapsulation.None    // NB: necessario per far recepire gli styles a tag all'interno di innerHtml
})

export class StandardBuyComponent implements OnInit, BuyComponentInterface {

    private inCart: boolean;

    @Input() productID: number;
    @Input() buyManagerService: DataStoreInterface;

    /* 
     * standard buy items
    */
    item: any = {};
    productInfo: any = {};

    /* 
     * view utility variables
    */
    loading: boolean = false;

    stepQuantity: number;    // prodotto con lotto minimo
    notAvailable: boolean;   // prodotto senza disponibilità

    /*
     * promotions variables
    */
    applyingPromo: boolean = false;
    stackedPromoModal: boolean = false;
    currentPromo: any;

    //buyManagerService: DataStoreInterface;

    constructor( 
        protected activeRoute: ActivatedRoute,
        protected router: Router,
        protected location: Location,
        protected buyService: BuyService,
        protected service: StandardBuyService,
        protected commonService: CommonService,
    ){
    }

    ngOnInit() { 
        this.loading = true;
        this.service.loadData(this.productID).subscribe(data => {
            this.item = data;
            this.stepQuantity = this.item.step_quantity || 1;
            // auto init
            this.item.quantity = this.stepQuantity;

            // TODO: definire reale ritorno dati per canvass e promo con Cristian
            // intanto prendo il primo della lista come attivo per avanzare con l'implementazione del nuovo buy
            if(this.item.canvass && this.item.canvass.length > 0) {
                this.item.activeCanvass = true;
                // this.item.activeCanvass.value = (- this.item.activeCanvass.discount) || this.commonService.formatNumber(this.item.activeCanvass.fixed_price,2,',','.');
            }

            if(this.item.promos && this.item.promos.length > 0) {
                for (let i = 0; i < this.item.promos.length; ++i) {
                    let promo = this.item.promos[i];
                    promo.template = promo.benefits[0].template;
                    promo.valueobj = promo.benefits[0].valueobj;
                    promo.type = promo.benefits[0].type;
                }
            }           

            this.buyService.askPrice(this.item).subscribe(priceDetail => {
                this.productInfo = this.item.priceDetail.productInfo;
                
                this.notAvailable = this.checkNotAvailable();
                
                if(this.notAvailable)
                    this.item.quantity = 0;

                this.loading = false;
            });
        });
    } 

    checkNotAvailable(): boolean {
        if(!this.productInfo || !this.productInfo.stockBehaviour || this.productInfo.stockBehaviour == 2)
            return false;

        let availability = this.productInfo.stockQuantity + this.productInfo.stockIncomingQuantity 
                      + this.productInfo.stockCustomerQuantity + this.productInfo.stockCustomerIncomingQuantity;

        return (availability == 0 || this.stepQuantity > availability);
    }

    increaseQuantity(): void {
        if(this.notAvailable)
            return;
        
        this.item.quantity += this.stepQuantity;
        this.quantityChanged();
    };

    decreaseQuantity(): void {
        if(this.notAvailable)
            return;

        if(this.item.quantity - this.stepQuantity >= this.stepQuantity) {
            this.item.quantity -= this.stepQuantity;
            this.quantityChanged();
        }
    };

    // funzione di gestione di cambio quantità -> ricalcolo prezzi
    quantityChanged(): void {
        if(!this.item.quantity) this.item.quantity = 0;

        let availability = this.productInfo.stockQuantity + this.productInfo.stockIncomingQuantity 
                      + this.productInfo.stockCustomerQuantity + this.productInfo.stockCustomerIncomingQuantity;

        /* modifiche automatiche alla quantità per input non valido */
        // controllo per stock -> se si supera la quantità disponibile viene impostato il limite massimo
        if(this.productInfo.stockBehaviour == 1 && this.item.quantity > availability) {
            this.item.quantity = availability;
            this.commonService.sendMessage(this.commonService.getFixedWord('CART_STOCK_QTY_ERROR_MESSAGE'), 'warning', true); 
            /*
            this.pageData.notify(tipo, {
                title: this.commonService.getFixedWord('CART_STOCK_QTY_ERROR'),
                message: this.commonService.getFixedWord('CART_STOCK_QTY_ERROR_MESSAGE'),
            });
            */
        }    

        // controlli su arrotondamenti step: quantity < step -> imposto lo step
        if(!this.commonService.getSiteUserService().checkFunctionality('buyinterface.quantity_not_locked_to_step') && this.item.quantity < this.stepQuantity) {
            this.item.quantity = this.stepQuantity;
            this.commonService.sendMessage(this.commonService.getFixedWord('CART_ROUND_QUANTITY_MESSAGE'), 'warning', true); 
            /*
            this.pageData.notify(tipo, {
                title: this.commonService.getFixedWord('CART_ROUND_QUANTITY'),
                message: this.commonService.getFixedWord('CART_ROUND_QUANTITY_MESSAGE'),
            });
            */
        }
        // eventuale correzione di inserimento manuale per step
        if(!this.commonService.getSiteUserService().checkFunctionality('buyinterface.quantity_not_locked_to_step') && this.item.quantity % this.stepQuantity) {
            this.item.quantity = Math.ceil((this.item.quantity) / this.stepQuantity ) * this.stepQuantity;
            this.commonService.sendMessage(this.commonService.getFixedWord('CART_ROUND_QUANTITY_MESSAGE'), 'warning', true); 
            /*
            this.pageData.notify(tipo, {
                title: this.commonService.getFixedWord('CART_ROUND_QUANTITY'),
                message: this.commonService.getFixedWord('CART_ROUND_QUANTITY_MESSAGE'),
            });
            */
        }

        // ricalcolo prezzi con nuova quantità
        this.buyService.askPrice(this.item).subscribe(priceDetail => {
            /* funzioni custom ?
            if(fctBuyInterface.customQuantityChecks) {
                fctBuyInterface.customQuantityChecks(fctBuyInterface.currentItem);
            }
            */
        });
    }

    getStockQuantityString(val:number): string {
        if(val == undefined || !val) return;

        let valString = val.toString();
        // se è un intero tolgo eventuali decimali inesistenti
        if(val % 1 === 0) {
          valString = (valString.indexOf('.') >= 0) ? valString.substr(0, valString.indexOf('.')) : valString;
        } else {
          valString = this.commonService.formatNumber(val, 2,',','.');
        }

        /* TODO: eventuale gestione valore massimo visualizzabile da setting
        var max_val = $rootScope.getSettingNumericValue("stock_quantity.max_value_display");
        if(max_val && max_val < Number(val)){
          val = "+" + max_val;
        }
        */

        return valString + ' ' + this.item.priceDetail.umCode;
    }

    
    getPromoType(promo:any): string {
        switch (promo.type) {
            case "%":
                return "extra";
                break;

            case "€":
                return "prezzo finito";
                break;
            
            default:
                return "omaggi a scelta";
                break;
        }
    }

    addOrUpdateItems(): void {
        let item = {
            product_id: this.item.product_id,
            quantity: this.item.quantity,
            um_id: this.item.priceDetail.umID,
            ship_date: this.item.ship_date,    // formattare la data probabilmente
            extra_data: this.item.extra_data || null,
            customprice: this.item.priceDetail.customUnitPrice, 
            discount: this.item.priceDetail.discount, 
            discount_string: this.item.priceDetail.discountString, 
            custom_tax_rate: this.item.priceDetail.customTaxRate,
            promo_id: this.item.promo ? this.item.promo.promo_id : null,
            custom_benefit_value: this.item.custom_benefit_value,
            // dati per visualizzazione diretta in cart popover
            code: this.item.code,
            name: this.item.name,
            image: this.item.image
        };



        let param = [item]; 
        this.buyManagerService.addOrUpdateItems(param)
        .subscribe(result => {
            if(result && !result.error) {
                this.commonService.sendMessage(this.commonService.getFixedWord('CART_ADDED_MESSAGE'), 'success', true); 
                this.inCart = true;
                this.buyService.openBuy = false;
            }
        });
    }

    openPromoModal(promo: any): void {
        this.currentPromo = promo;
        this.stackedPromoModal = true;
        this.currentPromo.notValid = false;

        // condizioni per applicabilità promozioni
        if(promo.min_quantity > this.item.quantity)
            this.currentPromo.notValid = true;

        // view helpers     
        for (var i = 0; i < this.currentPromo.benefits.length; i++) {
            if(this.currentPromo.benefits[i].type_code == 'MER') {                
                this.currentPromo.hasGifts = true;
                break;
            }
        }
    }

    confirmPromo(): void { 
        this.currentPromo.applyingPromo = true;
        this.removeCurrentPromo();
       /* TODO: probabile necessità di gestire customization in apertura, 
                almeno nel caso Lotti per chiedere il prezzo post attibazione di eventuali promo */
        if(this.currentPromo) {
            for (var i = 0; i < this.currentPromo.benefits.length; i++) {
                var benefit = this.currentPromo.benefits[i];
                var extra = benefit.benefit_type_extra;

                if(!this.item.custom_benefit_value)
                    this.item.custom_benefit_value = [];

                if((!benefit.quantity_from || benefit.quantity_from <= this.item.quantity)
                       && (!benefit.quantity_to || benefit.quantity_to >= this.item.quantity)) {

                    switch (benefit.type_code) {
                        case "PRC":
                            // supporto per salvataggio dati benefit applicati
                            extra.benefit_id = benefit.benefit_id;
                            extra.type = benefit.type_code;
                            this.item.custom_benefit_value.push(extra);
                            break;

                        case "FIX":
                            var code = this.item.code;
                            var rules = extra.filter(function (r) { 
                                return r.productcode == code; 
                            });

                            if(rules[0] && rules[0].fixedprice) {
                                this.item.custom_benefit_value.push({   
                                    benefit_id: benefit.benefit_id,
                                    type: benefit.type_code,
                                    products: rules
                                });
                            }

                            break;

                        case "MER":
                            // supporto per salvataggio dati benefit applicati
                            extra.benefit_id = benefit.benefit_id;
                            extra.type = benefit.type_code;
                            this.item.custom_benefit_value.push(extra) // aggiunto l'extra per segnalare e salvare la promo, la selezione omaggi avverrà in fase di checkout
                            break;
                    }
                    
                }

            }
        } 

        this.item.priceDetail.customBenefitValue = this.item.custom_benefit_value;
        this.item.priceDetail.useThisCustomBenefitValue = true;


        this.buyService.askPrice(this.item).subscribe(priceDetail => {
            this.stackedPromoModal = false;
            this.currentPromo.applyingPromo = false;
            this.currentPromo.active = true;
            this.item.promo = JSON.parse(JSON.stringify(this.currentPromo));
        });
    }

    cancelPromoModal(): void {
        this.currentPromo = null;
        this.stackedPromoModal = false;    
    }

    removeCurrentPromo(refresh: boolean = false): void {
        this.item.custom_benefit_value = [];  

        this.item.customDiscount1 = null;
        this.item.customDiscountString = "";

        this.item.customUnitPrice = null;
        this.item.customprice = null;      

        // this.item.priceDetail = null;

        if(refresh) {                
            this.buyService.askPrice(this.item).subscribe(priceDetail => {
                this.stackedPromoModal = false;
                this.currentPromo = null;
                this.item.promo = null;
            });
        }
    } 

    removeCurrentPromoModal(): void {
        this.currentPromo.active = false;
        this.removeCurrentPromo(true);
    }
    
    getBenefitDescription(benefit:any): string {
        switch (benefit.type_code) {
            case "PRC":
                return " sconto extra";
                break;

            case "FIX":
                return " prezzo finito";
                break;

            case "MER":
                return " articoli omaggio a scelta ogni " ;
                break;
        }
        return '';
    }

}