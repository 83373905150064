import { Injectable, Injector } from '@angular/core';
import { CheckboxElement } from './checkbox-element.interface';
 
@Injectable({
  providedIn: 'root'
})
export class CheckboxElementService{
  
    private collection: Array<CheckboxElement>;

    constructor(){ }
    
    public getCheckboxElements(items: Array<any>, key): Array<any>{
        this.collection = [];
        for(let item of items)
            this.collection.push({ id : item[key], checked: false });
        return this.collection;
    }

}