import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CommonService } from '../../../services/common/common.service';
import * as $ from "jquery";

@Component({
  selector: 'app-head-toolbar',
  templateUrl: './head-toolbar.component.html',
  styleUrls: ['./head-toolbar.component.scss'],
  providers: [ CommonService ]
})
export class HeadToolbarComponent implements OnInit {

  private formDataSubscription: Subscription;
  private inputControl: any;

  constructor(private cdRef:ChangeDetectorRef, private commonService: CommonService) { 
    this.inputControl = {};
  }

  @Input() data;

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(){

    this.formDataSubscription = this.commonService.getFormChanges().subscribe(
      data => {
        this.inputControl = data;
      }
    );
  }

  private execute(action): void {

    if(action.extra && action.extra.validation.form){
      action.extra.validation.form.valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged(),
      ).subscribe( value => console.log(value));
    } 

    switch(action.type){
      case 'toggleSidebar':
        $('.filter-toolbar').toggleClass('sidebar-off-screen');
        $('.content-area').toggleClass('sidebar-off-screen');
        break;
      default:
        action.owner[action.name](action.params);
        break;
    }
    
  }
  
}

